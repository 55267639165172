import { BoardObjectType } from '@/renderer/types/ObjectTypes';
import { Vector2 } from '@/renderer/utils/Vector2';

const RENDERER_CONFIG = {
	FONT: '12px Inter',
	FONT_SIZE: 12,
	FONT_FAMILY: 'Inter',
	COLORS: {
		WHITE: '#ffffff',
		WINDOW_GRAY: '#f8f8f8',
		LIGHT_GRAY: '#e3e8ef',
		LIGH_BLACK: '#4b5565',
		BLACK: '#1e1e1e',
		LIGHT_GREEN: '#EFFFE8',
		LIGHT_RED: '#ffefef',
	},
	METRICS: {
		FONT_SIZE: 27,
		LINE_LENGTH: 45,
		ARROW_SIZE: 20,
		VERTICAL_LINE_OFFSET: 8,
	},
	INITIAL_BASE_DIMENSIONS: {
		width: 700,
		height: 1500,
		depth: 40,
	},
	PAN: {
		MIN: new Vector2(-1000, -1000),
		MAX: new Vector2(2000, 2000),
	},
	ZOOM_LEVELS: [
		0.3, 0.38, 0.425984, 0.5, 0.53248, 0.625, 0.6656, 0.78125, 0.832, 0.9765625,
		1.04,
	],
	BOARD_ADJUSTED_OBJECT_TYPES: [
		BoardObjectType.GRILLE,
		BoardObjectType.CUSTOM_GRILLE,
		BoardObjectType.MOUNTING_PLATE,
	] as BoardObjectType[],
	METRICS_COMPONENTS: [
		BoardObjectType.GRILLE,
		BoardObjectType.CUSTOM_GRILLE,
		BoardObjectType.MOUNTING_PLATE,
	] as BoardObjectType[],
	DISTRIBUTION_BOARD_DRAG_GRID_SIZE: 50,
	STAGING_CANVAS_WIDTH: 225,
	STAGING_CANVAS_SCALE: 0.3,
	COMPONENTS: {
		GRILLE: {
			INNER_CUT_HEIGHT: 47,
		},
		ELECTRICITY_METER_3F: {
			WINDOW_WIDTH: 100,
			WINDOW_HEIGHT: 100,
			WINDOW_OFFSET_TOP: 40,
		},
		SWITCH: {
			SHAPE_X_OFFSET: 4,
			SHAPE_Y_OFFSET: 12,
			INNER_RECT_WIDTH: 30,
			CIRCLE_RADIUS: 5,
		},
	},
	GRILLE_MODULE_COUNT_PER_LENGTH: {
		300: 10,
		400: 15,
		500: 21,
		600: 26,
		700: 32,
		800: 37,
		900: 43,
		1000: 48,
		1100: 54,
		1200: 60,
	},
	DOOR_CONNECTION_LINE_WIDTH_BREAKPOINTS: {
		DOUBLE_MIDDLE: 700,
		DOUBLE_LEFT_SIDE: 1100,
	},
	SHAFT_DOORS: {
		SHELL: 5,
		GAP: 20,
		MIN_HEIGHT: 150,
		HEIGHT_STEP: 10,
		DOUBLE_WING_WIDTH_BREAKPOINT: 800,
		KEYHOLE_RADIUS: 5,
		KEYHOLE_X_OFFSET: 20,
	},
	SHAFT_WINDOWS: {
		RADIUS: 50,
		MIN_DIST_TO_EDGE_HORIZONTAL: 75,
		MIN_DIST_TO_EDGE_TOP: 84,
		MIN_DIST_TO_EDGE_BOTTOM: 135,
		MIN_GAP_HORIZONTAL: 102,
		MIN_GAP_VERTICAL: 230,
		MIN_DIST_TO_KEYHOLE: 20,
	},
} as const;

export default RENDERER_CONFIG;

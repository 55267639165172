import { Vector2 } from '@/renderer/utils/Vector2';
import { CanvasEngine } from '../CanvasEngine';
import { Dimensions } from '../types/utils';
import { Line } from '../utils/Line';

export abstract class CanvasObject {
	isMouseOver = false;
	pos = new Vector2(0, 0);
	width = 100;
	height = 100;

	constructor(protected engine: CanvasEngine) {}

	getCanvasPos() {
		const scaledPos = this.pos.clone();
		scaledPos.multiplyScalar(this.engine.scale);

		return scaledPos;
	}

	getScaledDimensions(scale = this.engine.scale) {
		return {
			width: this.width * scale,
			height: this.height * scale,
		};
	}

	getLinePoints() {
		const height = this.getScaledDimensions().height,
			halfWidth = this.getScaledDimensions().width / 2;

		const pos = this.getCanvasPos();

		const topVector = pos.clone().add(new Vector2(halfWidth, 0));
		const bottomVector = pos.clone().add(new Vector2(halfWidth, height));

		return new Line(topVector, bottomVector);
	}

	resize(dimensions: Partial<Dimensions>) {
		if (dimensions.width) this.width = dimensions.width;
		if (dimensions.height) this.height = dimensions.height;

		this.engine.mainNeedsRedraw = true;
		this.engine.stagingNeedsRedraw = true;
	}
}

import { Vector2 } from '@/renderer/utils/Vector2';
import { MainRenderer } from './MainRenderer';
import { BaseRenderer } from './BaseRenderer';
import { DistributionBoard } from '../objects/DistributionBoard';
import { RendererUtils } from '../utils/RendererUtils';
import { DraggableObject } from '../objects/DraggableObject';
import { EnabledDraggingRect } from '../objects/EnabledDraggingRect';
import RENDERER_CONFIG from '@/configs/rendererConfig';

const { COLORS, METRICS } = RENDERER_CONFIG;

type MetricsOptions = {
	shellThickness: number;
	lineLength: number;
	arrowSize: number;
	arrowOffset: number;
};

export class DistributionBoardRenderer extends BaseRenderer {
	constructor(renderer: MainRenderer) {
		super(renderer);
	}

	draw() {
		const { distributionBoard, withPlinth } = this.renderer.engine;

		this.drawBoard(distributionBoard);
		this.drawMetrics(distributionBoard);

		if (withPlinth) this.drawPlinth(distributionBoard);
	}

	private drawBoard(distributionBoard: DistributionBoard) {
		const outerRect = new Path2D(),
			innerRect = new Path2D();

		const pos = distributionBoard.getCanvasPos();
		const { width, height } = distributionBoard.getScaledDimensions();

		this.drawGrid(pos, width, height, distributionBoard.enabledDraggingRects);

		const shellThickness =
			distributionBoard.shellThickness * this.renderer.engine.scale;

		outerRect.rect(
			pos.x - shellThickness,
			pos.y - shellThickness,
			width + shellThickness * 2,
			height + shellThickness * 2
		);
		innerRect.rect(pos.x, pos.y, width, height);
		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(outerRect);
		this.context.strokeStyle = COLORS.LIGH_BLACK;
		this.context.stroke(innerRect);
	}

	private drawGrid(
		pos: Vector2,
		width: number,
		height: number,
		enabledRects: EnabledDraggingRect[]
	) {
		const grid = new Path2D();
		const gridWidth = width;
		const gridHeight = height;

		if (enabledRects.length) {
			this.context.fillStyle = COLORS.LIGHT_RED;
			this.context.fillRect(pos.x, pos.y, width, height);
		}

		this.context.fillStyle = COLORS.LIGHT_GREEN;

		for (const rect of enabledRects) {
			const rectPath = new Path2D();

			const rectPos = rect.getCanvasPos();
			const { width, height } = rect.getScaledDimensions();

			rectPath.rect(rectPos.x, rectPos.y, width, height);

			this.context.fill(rectPath);
		}

		const gridSize = 50 * this.renderer.engine.scale;

		for (let x = pos.x; x < pos.x + gridWidth; x += gridSize) {
			grid.moveTo(x, pos.y);
			grid.lineTo(x, pos.y + gridHeight);
		}

		for (let y = pos.y; y < pos.y + gridHeight; y += gridSize) {
			grid.moveTo(pos.x, y);
			grid.lineTo(pos.x + gridWidth, y);
		}

		this.context.strokeStyle = COLORS.LIGHT_GRAY;
		this.context.stroke(grid);
	}

	private drawMetrics(distributionBoard: DistributionBoard) {
		const { width, height } = distributionBoard.getScaledDimensions();
		const pos = distributionBoard.getCanvasPos();

		const shellThickness =
			distributionBoard.shellThickness * this.renderer.engine.scale;

		const componenets = this.renderer.engine.getMetricsComponents();

		const lineLength = METRICS.LINE_LENGTH * this.renderer.engine.scale,
			arrowSize = METRICS.ARROW_SIZE * this.renderer.engine.scale,
			arrowOffset = METRICS.VERTICAL_LINE_OFFSET * this.renderer.engine.scale;

		const options = {
			shellThickness,
			lineLength,
			arrowSize,
			arrowOffset,
		};

		const { board: boardLineLength, total: totalLineLength } =
			RendererUtils.getHeightMetricOffset(this.renderer.engine, lineLength);

		this.drawHeightMetrics(width, height, pos, distributionBoard.height, {
			...options,
			lineLength: boardLineLength,
		});

		this.drawWidthMetrics(width, pos, distributionBoard.width, options);
		this.drawDepthMetrics(
			new Vector2(pos.x - shellThickness, pos.y + height + shellThickness),
			distributionBoard.depth,
			options
		);

		if (componenets.length)
			this.drawComponentsMetrics(pos, width, height, componenets, options);

		const { plinthHeight: plinthHeightRaw, withPlinth } = this.renderer.engine;

		const plinthHeight = plinthHeightRaw * this.renderer.engine.scale;

		if (withPlinth) {
			this.drawPlinthMetrics(
				plinthHeight,
				pos
					.clone()
					.add(new Vector2(width + shellThickness, height + shellThickness)),
				plinthHeightRaw,
				{
					...options,
					lineLength: boardLineLength,
				}
			);
			this.drawTotalMetrics(
				width,
				height + plinthHeight,
				pos,
				distributionBoard.height + plinthHeightRaw,
				{
					...options,
					lineLength: totalLineLength,
				}
			);
		}
	}

	private drawHeightMetrics(
		width: number,
		height: number,
		pos: Vector2,
		originalHeight: number,
		options: MetricsOptions
	) {
		const { shellThickness, lineLength, arrowSize, arrowOffset } = options;

		const basePoint = new Vector2(
			pos.x + width + shellThickness,
			pos.y - shellThickness
		);

		const topLine = new Path2D();
		topLine.moveTo(basePoint.x, basePoint.y);
		topLine.lineTo(basePoint.x + lineLength, basePoint.y);

		const bottomLine = new Path2D();
		basePoint.set(
			pos.x + width + shellThickness,
			pos.y + height + shellThickness
		);
		bottomLine.moveTo(basePoint.x, basePoint.y);
		bottomLine.lineTo(basePoint.x + lineLength, basePoint.y);

		const arrowLine = new Path2D();
		basePoint.set(
			pos.x + width + lineLength - arrowOffset + shellThickness,
			pos.y - shellThickness
		);
		arrowLine.moveTo(basePoint.x, basePoint.y);
		arrowLine.lineTo(basePoint.x, basePoint.y + height + shellThickness * 2);

		const arrowTop = new Path2D();
		arrowTop.moveTo(basePoint.x, basePoint.y);
		arrowTop.lineTo(basePoint.x + arrowSize * 0.5, basePoint.y + arrowSize);
		arrowTop.lineTo(basePoint.x - arrowSize * 0.5, basePoint.y + arrowSize);
		arrowTop.closePath();

		const arrowBottom = new Path2D();
		arrowBottom.moveTo(basePoint.x, basePoint.y + height + shellThickness * 2);
		arrowBottom.lineTo(
			basePoint.x - arrowSize * 0.5,
			basePoint.y - arrowSize + height + shellThickness * 2
		);
		arrowBottom.lineTo(
			basePoint.x + arrowSize * 0.5,
			basePoint.y - arrowSize + height + shellThickness * 2
		);
		arrowBottom.closePath();

		const text = originalHeight.toString();

		this.context.save();
		this.context.translate(basePoint.x, basePoint.y + height / 2);

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);
		this.context.rotate(-Math.PI / 2);
		this.context.textAlign = 'center';
		this.context.textBaseline = 'bottom';
		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(text, 0, 0);
		this.context.restore();

		this.context.strokeStyle = COLORS.BLACK;

		if (!this.renderer.engine.withPlinth) this.context.stroke(topLine);

		this.context.stroke(bottomLine);
		this.context.stroke(arrowLine);
		this.context.fillStyle = COLORS.BLACK;
		this.context.fill(arrowTop);
		this.context.fill(arrowBottom);
	}

	private drawWidthMetrics(
		width: number,
		pos: Vector2,
		originalWidth: number,
		options: MetricsOptions
	) {
		const { shellThickness, lineLength, arrowSize, arrowOffset } = options;

		const lines = new Path2D();

		const basePoint = new Vector2(
			pos.x - shellThickness,
			pos.y - shellThickness
		);

		lines.moveTo(basePoint.x, basePoint.y);
		lines.lineTo(basePoint.x, basePoint.y - lineLength);

		basePoint.setX(pos.x + width + shellThickness);
		lines.moveTo(basePoint.x, basePoint.y);
		lines.lineTo(basePoint.x, basePoint.y - lineLength);

		basePoint.set(
			pos.x + width + shellThickness,
			pos.y - lineLength + arrowOffset - shellThickness
		);
		lines.moveTo(basePoint.x, basePoint.y);
		lines.lineTo(
			pos.x - shellThickness,
			pos.y - lineLength + arrowOffset - shellThickness
		);

		const arrowRight = new Path2D();
		arrowRight.moveTo(basePoint.x, basePoint.y);
		arrowRight.lineTo(basePoint.x - arrowSize, basePoint.y - arrowSize * 0.5);
		arrowRight.lineTo(basePoint.x - arrowSize, basePoint.y + arrowSize * 0.5);
		arrowRight.closePath();

		basePoint.set(
			pos.x - shellThickness,
			pos.y - lineLength + arrowOffset - shellThickness
		);
		const arrowLeft = new Path2D();
		arrowLeft.moveTo(basePoint.x, basePoint.y);
		arrowLeft.lineTo(basePoint.x + arrowSize, basePoint.y - arrowSize * 0.5);
		arrowLeft.lineTo(basePoint.x + arrowSize, basePoint.y + arrowSize * 0.5);
		arrowLeft.closePath();

		const text = originalWidth.toString();

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);
		this.context.textBaseline = 'alphabetic';
		this.context.textAlign = 'center';
		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(
			text,
			pos.x + width / 2,
			pos.y - lineLength - shellThickness
		);
		this.context.restore();

		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(lines);
		this.context.fillStyle = COLORS.BLACK;
		this.context.fill(arrowRight);
		this.context.fill(arrowLeft);
	}

	private drawDepthMetrics(
		basePoint: Vector2,
		depth: number,
		options: MetricsOptions
	) {
		const { lineLength } = options;

		const line = new Path2D();

		line.moveTo(basePoint.x, basePoint.y);
		line.lineTo(basePoint.x - lineLength * 2, basePoint.y - lineLength * 2);

		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(line);

		const text = depth.toString();

		this.context.save();
		this.context.translate(basePoint.x - lineLength, basePoint.y - lineLength);

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);
		this.context.rotate(Math.PI / 4);
		this.context.textAlign = 'center';
		this.context.textBaseline = 'bottom';
		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(text, 0, 0);
		this.context.restore();
	}

	private drawComponentsMetrics(
		pos: Vector2,
		width: number,
		height: number,
		components: DraggableObject[],
		options: MetricsOptions
	) {
		const { shellThickness, lineLength } = options;

		const vertLine = new Path2D();

		const basePoint = new Vector2(
			pos.x + width + shellThickness + lineLength,
			pos.y - shellThickness
		);
		vertLine.moveTo(basePoint.x, basePoint.y);
		vertLine.lineTo(basePoint.x, basePoint.y + height + shellThickness * 2);

		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(vertLine);

		let horizontalLinesLevels: number[] = [];

		for (const component of components) {
			const { y } = component.getCanvasPos();
			const componentHeight = component.getScaledDimensions().height;

			horizontalLinesLevels.push(y);
			horizontalLinesLevels.push(y + componentHeight);
		}

		horizontalLinesLevels = [...new Set(horizontalLinesLevels)];
		let horizontalLine = new Path2D();

		const halfLineLength = lineLength / 2;

		for (const level of horizontalLinesLevels) {
			horizontalLine.moveTo(basePoint.x - halfLineLength, level);
			horizontalLine.lineTo(basePoint.x + halfLineLength, level);
		}

		this.context.stroke(horizontalLine);

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);
		this.context.textAlign = 'center';
		this.context.textBaseline = 'bottom';
		this.context.fillStyle = COLORS.BLACK;

		for (const component of components) {
			this.context.save();
			const text = component.height.toString();

			const { height } = component.getScaledDimensions();

			const { y } = component.getCanvasPos();
			basePoint.set(
				pos.x + width + shellThickness + lineLength,
				y + height / 2
			);
			this.context.translate(basePoint.x, basePoint.y);

			this.context.rotate(-Math.PI / 2);

			this.context.fillText(text, 0, 0);
			this.context.restore();
		}
	}

	private drawPlinth(distributionBoard: DistributionBoard) {
		const pos = distributionBoard.getCanvasPos();
		const { width, height } = distributionBoard.getScaledDimensions();

		const { scale, plinthHeight } = this.renderer.engine;

		const shellThickness = distributionBoard.shellThickness * scale;

		const rect = new Path2D();
		rect.rect(
			pos.x - shellThickness,
			pos.y + height + shellThickness,
			width + shellThickness * 2,
			plinthHeight * scale
		);

		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(rect);
	}

	private drawPlinthMetrics(
		height: number,
		pos: Vector2,
		originalHeight: number,
		options: MetricsOptions
	) {
		const { lineLength, arrowOffset, arrowSize } = options;

		const arrowLine = new Path2D();
		arrowLine.moveTo(pos.x + lineLength - arrowOffset, pos.y + height);
		arrowLine.lineTo(pos.x + lineLength - arrowOffset, pos.y);

		const basePoint = new Vector2(pos.x + lineLength - arrowOffset, pos.y);

		const arrowTop = new Path2D();
		arrowTop.moveTo(basePoint.x, basePoint.y);
		arrowTop.lineTo(basePoint.x + arrowSize * 0.5, basePoint.y + arrowSize);
		arrowTop.lineTo(basePoint.x - arrowSize * 0.5, basePoint.y + arrowSize);
		arrowTop.closePath();

		basePoint.setY(pos.y + height);

		const arrowBottom = new Path2D();
		arrowBottom.moveTo(basePoint.x, basePoint.y);
		arrowBottom.lineTo(basePoint.x - arrowSize * 0.5, basePoint.y - arrowSize);
		arrowBottom.lineTo(basePoint.x + arrowSize * 0.5, basePoint.y - arrowSize);
		arrowBottom.closePath();

		this.context.stroke(arrowLine);
		this.context.fillStyle = COLORS.BLACK;
		this.context.fill(arrowTop);
		this.context.fill(arrowBottom);

		const text = originalHeight.toString();

		basePoint.setY(pos.y);

		this.context.save();
		this.context.translate(basePoint.x, basePoint.y + height / 2);

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);
		this.context.rotate(-Math.PI / 2);
		this.context.textAlign = 'center';
		this.context.textBaseline = 'bottom';
		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(text, 0, 0);
		this.context.restore();
	}

	private drawTotalMetrics(
		width: number,
		height: number,
		pos: Vector2,
		originalHeight: number,
		options: MetricsOptions
	) {
		const lines = new Path2D();

		const { shellThickness, lineLength, arrowSize, arrowOffset } = options;

		const basePoint = new Vector2(
			pos.x + width + shellThickness,
			pos.y - shellThickness
		);
		lines.moveTo(basePoint.x, basePoint.y);
		lines.lineTo(basePoint.x + lineLength, basePoint.y);

		basePoint.set(
			pos.x + width + shellThickness,
			pos.y + height + shellThickness
		);
		lines.moveTo(basePoint.x, basePoint.y);
		lines.lineTo(basePoint.x + lineLength, basePoint.y);

		basePoint.set(
			pos.x + width + lineLength - arrowOffset + shellThickness,
			pos.y - shellThickness
		);
		lines.moveTo(basePoint.x, basePoint.y);
		lines.lineTo(basePoint.x, basePoint.y + height + shellThickness * 2);

		const arrowTop = new Path2D();
		arrowTop.moveTo(basePoint.x, basePoint.y);
		arrowTop.lineTo(basePoint.x + arrowSize * 0.5, basePoint.y + arrowSize);
		arrowTop.lineTo(basePoint.x - arrowSize * 0.5, basePoint.y + arrowSize);
		arrowTop.closePath();

		const arrowBottom = new Path2D();
		arrowBottom.moveTo(basePoint.x, basePoint.y + height + shellThickness * 2);
		arrowBottom.lineTo(
			basePoint.x - arrowSize * 0.5,
			basePoint.y - arrowSize + height + shellThickness * 2
		);
		arrowBottom.lineTo(
			basePoint.x + arrowSize * 0.5,
			basePoint.y - arrowSize + height + shellThickness * 2
		);
		arrowBottom.closePath();

		const text = originalHeight.toString();

		this.context.save();
		this.context.translate(basePoint.x, basePoint.y + height / 2);

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);
		this.context.rotate(-Math.PI / 2);
		this.context.textAlign = 'center';
		this.context.textBaseline = 'bottom';
		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(text, 0, 0);
		this.context.restore();

		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(lines);
		this.context.fillStyle = COLORS.BLACK;
		this.context.fill(arrowTop);
		this.context.fill(arrowBottom);
	}
}

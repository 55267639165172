import { DistributionBoard } from './objects/DistributionBoard';
import { ApplyEventHandlers, Events, On } from './utils/Events';
import { Vector2 } from '@/renderer/utils/Vector2';
import { CanvasEvent } from './types/CanvasEvent';
import { CanvasObject } from './objects/CanvasObject';
import { StagingArea } from './objects/StagingArea';
import { DraggableObject } from './objects/DraggableObject';
import { ObjectDragger } from './utils/ObjectDragger';
import { NativeMouseEvent } from './types/Mouse';
import { CreatorStep } from '@/types/creator';
import { Door } from './objects/Door';
import { CollisionDetector } from './objects/CollisionDetector';
import { AssetsBase } from './utils/AssetsBase';
import { BoardObjectType } from './types/ObjectTypes';
import { loadComponents } from './utils/loadComponents';
import { ExportRendererPayload } from './types/ExportPayload';
import { ProductTypeViewType } from '@/api/ProductTypeApi';
import { Shaft } from './objects/Shaft';
import RENDERER_CONFIG from '@/configs/rendererConfig';

@ApplyEventHandlers
export class CanvasEngine {
	private _viewMode = ProductTypeViewType.DISTRIBUTION_BOARD;
	mainNeedsRedraw = true;
	stagingNeedsRedraw = true;
	events = Events.getInstance();
	windowMouse = new Vector2(0, 0);
	canvasMouse = new Vector2(0, 0);
	translation = new Vector2(0, 0);
	distributionBoard: DistributionBoard;
	collisionDetector: CollisionDetector;
	door: Door;
	shaft: Shaft;
	objectDragger = new ObjectDragger(this);
	stagingArea = new StagingArea(this);
	draggableObjects: DraggableObject[] = [];
	assetsLib = new AssetsBase(this);
	private zoomLevel = 1;
	private _currentStep = CreatorStep.BASE;
	private _plinthHeight = 100;
	private _withPlinth = false;

	constructor() {
		this.distributionBoard = new DistributionBoard(this);
		this.collisionDetector = new CollisionDetector(this);
		this.door = new Door(this);

		this.shaft = new Shaft(this);

		loadComponents(this);
	}

	get viewMode() {
		return this._viewMode;
	}

	set viewMode(value: ProductTypeViewType) {
		this._viewMode = value;
		this.mainNeedsRedraw = true;
	}

	get withPlinth() {
		return this._withPlinth;
	}

	set withPlinth(value: boolean) {
		this._withPlinth = value;
		this.door.repositionDoor();
		this.mainNeedsRedraw = true;
	}

	get plinthHeight() {
		return this._plinthHeight;
	}

	set plinthHeight(value: number) {
		this._plinthHeight = value;
		this.mainNeedsRedraw = true;
	}

	get scale() {
		return RENDERER_CONFIG.ZOOM_LEVELS[this.zoomLevel];
	}

	get currentStep() {
		return this._currentStep;
	}

	set currentStep(step: CreatorStep) {
		if (step === CreatorStep.BASE) this.clearObjects();
		this._currentStep = step;
	}

	@On(CanvasEvent.MOUSE_ENTER)
	private onMouseEnter(obj: CanvasObject) {
		obj.isMouseOver = true;
	}

	@On(CanvasEvent.MOUSE_LEAVE_OBJECT)
	private onMouseLeave(object: CanvasObject) {
		document.body.style.cursor = 'default';
		object.isMouseOver = false;
	}

	@On(CanvasEvent.MOUSE_SCROLL)
	private handleScroll(mouseEvent: WheelEvent, pos: Vector2) {
		const oldScale = this.scale;

		if (mouseEvent.deltaY < 0) {
			if (this.canZoomIn()) this.zoomLevel++;
		} else {
			if (this.canZoomOut()) this.zoomLevel--;
		}

		const newScale = this.scale;

		this.events.emit(
			CanvasEvent.PAN_MOVE,
			new Vector2(
				pos.x * (newScale - oldScale),
				pos.y * (newScale - oldScale)
			).multiplyScalar(-1)
		);
	}

	@On(CanvasEvent.MOUSE_DOWN)
	protected onMouseDown(
		object: DraggableObject,
		mouseEvent: NativeMouseEvent,
		mouse: Vector2
	) {
		object.isDragging = true;
		const clondedMouse = mouse.clone();

		this.calculateAvailableRects(object);

		if (object.isStagging) {
			object.centerClickDiff = clondedMouse.sub(object.stagingPos);
			return;
		}

		clondedMouse.multiplyScalar(1 / this.scale);
		object.centerClickDiff = clondedMouse.sub(object.pos);
	}

	@On(CanvasEvent.OBJECT_DRAGGING)
	protected onObjectDragging(
		object: DraggableObject,
		mouse: Vector2,
		mouseEvent: NativeMouseEvent
	) {
		if (object.isStagging) this.stagingNeedsRedraw = true;
		else this.mainNeedsRedraw = true;

		this.objectDragger.onObjectDragging(object, mouse);
	}

	private clearObjects() {
		this.draggableObjects = [];
		this.collisionDetector.collidables = [];
		this.mainNeedsRedraw = true;
	}

	private canZoomIn() {
		return this.zoomLevel < RENDERER_CONFIG.ZOOM_LEVELS.length - 1;
	}

	private canZoomOut() {
		return this.zoomLevel > 0;
	}

	private calculateAvailableRects(object: DraggableObject) {
		this.distributionBoard.calculateEnabledDraggingRects(object);
	}

	getMetricsComponents() {
		const componenets = this.draggableObjects.filter(
			(component) =>
				RENDERER_CONFIG.METRICS_COMPONENTS.includes(component.objectType) &&
				component.isInsideBoard
		);

		return componenets;
	}

	centerView(canvas: HTMLCanvasElement) {
		const contentCenter = new Vector2();
		let contentWidth = 0,
			contentHeight = 0;

		const canvasCenter = new Vector2(canvas.width / 2, canvas.height / 2),
			lineLength = RENDERER_CONFIG.METRICS.LINE_LENGTH * this.scale;

		switch (this.viewMode) {
			case ProductTypeViewType.DISTRIBUTION_BOARD: {
				const { width, height } = this.distributionBoard.getScaledDimensions();

				const shell = this.distributionBoard.shellThickness * 2 * this.scale;

				contentWidth =
					this.currentStep === CreatorStep.COMPONENTS
						? width + shell
						: width * 2 + shell + this.door.xOffsetToBoard * this.scale;
				contentHeight = height + shell;

				contentWidth += lineLength;
				contentHeight += lineLength;

				break;
			}
			case ProductTypeViewType.SHAFTS: {
				const { width, height } = this.shaft.getScaledDimensions();

				const shell = this.shaft.shellThickness * 2 * this.scale;

				contentWidth = width + shell + lineLength;
				contentHeight = height + shell + lineLength;

				break;
			}
		}

		contentCenter.set(contentWidth / 2, contentHeight / 2);
		const translation = canvasCenter.sub(contentCenter);

		this.translation.copy(translation);

		this.mainNeedsRedraw = true;
	}

	onMouseUp(object: DraggableObject) {
		object.centerClickDiff = undefined;
		this.distributionBoard.enabledDraggingRects = [];

		this.objectDragger.onDragEnd(object);

		if (object.isStagging) this.stagingNeedsRedraw = true;
		else this.mainNeedsRedraw = true;
	}

	transferStagingObjectToMain(object: DraggableObject, mouse: Vector2) {
		const transfered = this.stagingArea.transferObjectToMain(object);

		const newPos = mouse.clone();
		if (object.centerClickDiff) newPos.sub(object.centerClickDiff);

		newPos.multiplyScalar(1 / this.scale);
		transfered.pos.copy(newPos);

		this.collisionDetector.addCollidable(transfered);

		this.mainNeedsRedraw = true;
		this.stagingNeedsRedraw = true;
	}

	checkIsWindowError() {
		if (!this.door.withWindows) return false;

		return (
			this.distributionBoard.getWindowAvailableMeters().length !==
			this.draggableObjects.filter(
				(obj) => obj.objectType === BoardObjectType.ELECTRICITY_METER_3F
			).length
		);
	}

	viewStaggingArea() {
		return (
			this.viewMode === ProductTypeViewType.DISTRIBUTION_BOARD &&
			this.currentStep === CreatorStep.COMPONENTS
		);
	}

	export(): ExportRendererPayload {
		const objects = this.draggableObjects.map((obj) => obj.export()),
			distributionBoard = this.distributionBoard.export(),
			door = this.door.export(),
			shaft = this.shaft.export();

		return {
			viewMode: this.viewMode,
			plinthHeight: this.plinthHeight,
			withPlinth: this.withPlinth,
			distributionBoard,
			door,
			objects,
			shaft,
		};
	}
}

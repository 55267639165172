import { FC, useEffect, useMemo } from 'react';
import { useContent } from '@/providers/ContentProvider';
import { CreatorComponentCategory } from '@/types/creator';
import { Accordion, Box } from '@mantine/core';
import { SingleAttribute } from '../singleAttribute/SingleAttribute';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { useQuery } from '@tanstack/react-query';
import { changeSelectedComponent } from '@/store/slices/creatorSlice';
import { getFilterRelatedAttributes } from '@/utils/utilities';
import AttributeValueApi from '@/api/AttributeValueApi';
import ComponentCategoryApi from '@/api/ComponentCategoryApi';

interface Props {
	componentCategory: CreatorComponentCategory;
}

export const ComponentAccordion: FC<Props> = ({ componentCategory }) => {
	const dispatch = useTypedDispatch();

	const { options } = useTypedSelector((state) => state.creator);
	const selectedComponent = options.selectedComponents[componentCategory.id];

	const { getEntityText } = useContent();

	const selectedValues = useMemo(
		() =>
			Object.values(options.selectedAttributes[componentCategory.id]).filter(
				(value) => !!value
			),
		[options.selectedAttributes[componentCategory.id]]
	);

	const findComponentQuery = useQuery({
		queryKey: [
			ComponentCategoryApi.queryKey,
			componentCategory.id,
			AttributeValueApi.queryKey,
			selectedValues,
		],
		queryFn: ComponentCategoryApi.findComponentItem,
		enabled: !!selectedValues.length,
	});

	useEffect(() => {
		const component = findComponentQuery.data?.component;

		if (!component) return;

		if (component.id === selectedComponent?.id) return;

		dispatch(
			changeSelectedComponent({
				componentCategory,
				component,
			})
		);
	}, [findComponentQuery.data]);

	return (
		<Accordion.Item key={componentCategory.id} value={componentCategory.id}>
			<Accordion.Control>
				{getEntityText(componentCategory, 'name')}
			</Accordion.Control>
			<Accordion.Panel>
				<Box p="md" px={0}>
					{getFilterRelatedAttributes(
						componentCategory,
						options.selectedAttributes
					).map((attribute, idx, arr) => (
						<SingleAttribute
							key={attribute.id}
							attribute={attribute}
							componentCategoryId={componentCategory.id}
							isLast={idx === arr.length - 1}
						/>
					))}
				</Box>
			</Accordion.Panel>
		</Accordion.Item>
	);
};

import { CanvasEngine } from '../CanvasEngine';
import { DraggableObject } from './DraggableObject';
import { RendererUtils } from '../utils/RendererUtils';

export class CollisionDetector {
	collidables: DraggableObject[] = [];

	constructor(private engine: CanvasEngine) {}

	addCollidable(collidable: DraggableObject) {
		this.collidables.push(collidable);
	}

	removeCollidable(collidable: DraggableObject) {
		this.collidables = this.collidables.filter((c) => c !== collidable);
	}

	collisionCheck(object: DraggableObject) {
		for (const comparedObj of this.collidables) {
			if (object === comparedObj) continue;

			const isColliding = RendererUtils.isRectOverlapOtherRect(
				object,
				comparedObj
			);

			if (isColliding) {
				const changeDirection = object.pos.clone().sub(object.lastPos),
					nextValidPos = object.pos.clone();

				if (Math.abs(changeDirection.x) > Math.abs(changeDirection.y)) {
					if (changeDirection.x > 0) {
						nextValidPos.setX(comparedObj.pos.x - object.width);
					} else {
						nextValidPos.setX(comparedObj.pos.x + comparedObj.width);
					}
				} else {
					if (changeDirection.y > 0) {
						nextValidPos.setY(comparedObj.pos.y - object.height);
					} else {
						nextValidPos.setY(comparedObj.pos.y + comparedObj.height);
					}
				}

				return nextValidPos;
			}
		}

		return;
	}
}

import { FC, useMemo } from 'react';
import { useTypedSelector } from '@/store/hooks';
import { Flex } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { getFileSrc } from '@/utils/utilities';
import APP_CONFIG from '@/configs/appConfig';

export const Dimensions: FC = () => {
	const { product } = useTypedSelector((state) => state.creator);

	const isMobile = useMediaQuery(`(max-width: ${APP_CONFIG.BREAKPOINTS.MD})`);

	const height = useMemo(
		() => `calc(${isMobile ? '100' : '90'}dvh - 80px)`,
		[isMobile]
	);
	return (
		<Flex justify="center" align="center" h={height}>
			<img
				style={{ maxHeight: '100%', maxWidth: '100%' }}
				src={getFileSrc(product.productType?.image?.path)}
			/>
		</Flex>
	);
};

import { CanvasEngine } from '../CanvasEngine';
import { CanvasObject } from './CanvasObject';
import { DraggableObject, DraggableObjectConfig } from './DraggableObject';
import { TextObject } from './TextObject';
import { RendererUtils } from '../utils/RendererUtils';
import { BoardObjectType } from '../types/ObjectTypes';
import { Dimensions } from '../types/utils';
import RENDERER_CONFIG from '@/configs/rendererConfig';

export class StagingArea extends CanvasObject {
	private padding = 10;
	objects: DraggableObject[] = [];
	textObjects: TextObject[] = [];

	constructor(engine: CanvasEngine) {
		super(engine);
		this.width = RENDERER_CONFIG.STAGING_CANVAS_WIDTH;
	}

	private repositionObjects() {
		for (let idx = 0; idx < this.objects.length; idx++) {
			const object = this.objects[idx],
				textObject = this.textObjects[idx],
				lastObject = this.objects[idx - 1],
				lastTextObject = this.textObjects[idx - 1];

			textObject.pos = this.pos.clone();

			if (lastTextObject && lastObject)
				textObject.pos.setY(
					lastTextObject.pos.y +
						lastTextObject.getTotalHeight() +
						this.padding * 3 +
						lastObject.getStagingDimensions().height
				);
			else textObject.pos.setY(this.pos.y + this.padding);

			object.stagingPos.set(
				this.width / 2 - object.getStagingDimensions().width / 2,
				textObject.pos.y + textObject.getTotalHeight() + this.padding
			);

			object.basePos = object.stagingPos.clone();
		}

		this.engine.stagingNeedsRedraw = true;
	}

	addObject(config: DraggableObjectConfig) {
		const object = new DraggableObject(this.engine, config);
		this.objects.push(object);

		if (config.meta?.renderSrc)
			this.engine.assetsLib.prepareAssets(object, config.meta.renderSrc);

		const textObject = new TextObject(
			this.engine,
			RendererUtils.getObjectNameByType(object.objectType)
		);
		textObject.width = this.width;

		this.textObjects.push(textObject);

		this.repositionObjects();
	}

	transferObjectToMain(object: DraggableObject) {
		const idx = this.objects.indexOf(object);

		if (idx === -1) return object;

		const clone = object.clone();
		clone.isStagging = false;
		clone.isDragging = true;
		clone.isMouseOver = true;

		this.engine.draggableObjects.push(clone);

		object.stagingPos.copy(object.basePos);
		object.isDragging = false;
		object.isMouseOver = false;

		return clone;
	}

	resizeObject(objectType: BoardObjectType, dimensions: Partial<Dimensions>) {
		const object = this.objects.find((obj) => obj.objectType === objectType);
		if (!object) return;

		object.resize(dimensions);
		this.repositionObjects();
	}

	updateStaggingObject(
		objectType: BoardObjectType,
		payload: Partial<DraggableObject>
	) {
		const object = this.objects.find((obj) => obj.objectType === objectType);
		if (!object) return;

		Object.assign(object, payload);

		if (payload.height)
			this.resizeObject(objectType, {
				height: payload.height,
			});

		this.engine.stagingNeedsRedraw = true;
	}
}

import { CanvasEngine } from '../CanvasEngine';
import { CanvasObject } from './CanvasObject';

export class TextObject extends CanvasObject {
	lines: string[];

	constructor(engine: CanvasEngine, lines: string[]) {
		super(engine);
		this.lines = lines;
		this.height = 18;
	}

	getTotalHeight() {
		return this.lines.length * this.height;
	}
}

import { CanvasEngine } from '../CanvasEngine';
import { DraggableObjectsRenderer } from './DraggableObjectsRenderer';
import { TextObject } from '../objects/TextObject';
import { CreatorStep } from '@/types/creator';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import { ProductTypeViewType } from '@/api/ProductTypeApi';

const { COLORS, STAGING_CANVAS_WIDTH } = RENDERER_CONFIG;

export class StagingAreaRenderer {
	engine: CanvasEngine;
	canvas: HTMLCanvasElement;
	context!: CanvasRenderingContext2D;
	private draggableObjectsRenderer!: DraggableObjectsRenderer;

	constructor(canvas: HTMLCanvasElement, engine: CanvasEngine) {
		this.canvas = canvas;
		this.engine = engine;
		this.init();
		this.draggableObjectsRenderer = new DraggableObjectsRenderer(this);

		this.draw();
	}

	protected init() {
		this.canvas.width = STAGING_CANVAS_WIDTH;
		this.canvas.height = this.canvas.parentElement!.clientHeight;

		this.context = this.canvas.getContext('2d')!;

		this.context.textBaseline = 'bottom';

		this.context.save();
	}

	private draw() {
		if (this.engine.stagingNeedsRedraw) {
			this.canvas.width = this.canvas.width;
			this.canvas.height = this.canvas.height;

			for (const textObject of this.engine.stagingArea.textObjects) {
				this.drawTextObject(textObject);
			}
			this.draggableObjectsRenderer.draw(true);
			this.engine.stagingNeedsRedraw = false;
		}

		requestAnimationFrame(() => this.draw());
	}

	private drawTextObject(textObject: TextObject) {
		const { width, height, pos } = textObject;

		this.context.save();
		this.context.textAlign = 'center';
		this.context.fillStyle = COLORS.BLACK;

		this.context.font = `${height}px ${RENDERER_CONFIG.FONT_FAMILY}`;

		for (let index = 0; index < textObject.lines.length; index++) {
			const line = textObject.lines[index];

			this.context.fillText(
				line,
				pos.x + width / 2,
				pos.y + height * (index + 1)
			);
		}

		this.context.restore();
	}

	resizeRenderer() {
		let width = 0;

		if (this.engine.viewStaggingArea()) width = STAGING_CANVAS_WIDTH;

		this.canvas.width = width;
		this.engine.stagingNeedsRedraw = true;
	}
}

import { CanvasEngine } from '../CanvasEngine';
import { ApplyEventHandlers, On } from '../utils/Events';
import { DistributionBoardRenderer } from './DistributionBoardRenderer';
import { CanvasEvent } from '../types/CanvasEvent';
import { Vector2 } from '@/renderer/utils/Vector2';
import { DraggableObjectsRenderer } from './DraggableObjectsRenderer';
import { CreatorStep } from '@/types/creator';
import { DoorRenderer } from './DoorRenderer';
import { ProductTypeViewType } from '@/api/ProductTypeApi';
import { ShaftRenderer } from './ShaftRenderer';
import RENDERER_CONFIG from '@/configs/rendererConfig';

@ApplyEventHandlers
export class MainRenderer {
	engine: CanvasEngine;
	canvas: HTMLCanvasElement;
	context!: CanvasRenderingContext2D;
	private distributionBoardRenderer!: DistributionBoardRenderer;
	private doorRenderer!: DoorRenderer;
	private draggableObjectsRenderer!: DraggableObjectsRenderer;
	private shaftRenderer!: ShaftRenderer;

	constructor(canvas: HTMLCanvasElement, engine: CanvasEngine) {
		this.canvas = canvas;
		this.engine = engine;
		this.init();
		this.draw();
	}

	@On(CanvasEvent.RESET_TRANSLATION)
	private resetTranslation() {
		this.context.setTransform(1, 0, 0, 1, 0, 0);
		this.engine.translation = new Vector2(0, 0);
	}

	private clearCanvas() {
		const offset = new Vector2(
			Math.abs(this.engine.translation.x),
			Math.abs(this.engine.translation.y)
		).multiplyScalar(RENDERER_CONFIG.ZOOM_LEVELS.at(-1)!);

		this.context.clearRect(
			-4 * offset.x,
			-4 * offset.y,
			this.canvas.width + 10 * offset.x,
			this.canvas.height + 10 * offset.y
		);
	}

	protected init() {
		this.canvas.width = 700;
		this.canvas.height = this.canvas.parentElement!.clientHeight;

		this.context = this.canvas.getContext('2d')!;

		this.context.textBaseline = 'bottom';
		this.context.textAlign = 'start';
		this.context.save();

		this.distributionBoardRenderer = new DistributionBoardRenderer(this);
		this.doorRenderer = new DoorRenderer(this);
		this.draggableObjectsRenderer = new DraggableObjectsRenderer(this);

		this.shaftRenderer = new ShaftRenderer(this);
	}

	private draw() {
		if (this.engine.mainNeedsRedraw) {
			this.clearCanvas();
			this.context.setTransform(
				1,
				0,
				0,
				1,
				this.engine.translation.x,
				this.engine.translation.y
			);

			switch (this.engine.viewMode) {
				case ProductTypeViewType.DISTRIBUTION_BOARD:
					this.handleDistributionBoardDraw();
					break;
				case ProductTypeViewType.SHAFTS:
					this.handleShaftDraw();
			}

			this.engine.mainNeedsRedraw = false;
		}

		requestAnimationFrame(() => this.draw());
	}

	private handleDistributionBoardDraw() {
		switch (this.engine.currentStep) {
			case CreatorStep.COMPONENTS:
				this.distributionBoardRenderer.draw();
				this.draggableObjectsRenderer.draw();
				break;
			case CreatorStep.ACCESSORIES:
			case CreatorStep.SUMMARY:
				this.distributionBoardRenderer.draw();
				this.draggableObjectsRenderer.draw();
				this.doorRenderer.draw();
				break;
		}
	}

	private handleShaftDraw() {
		switch (this.engine.currentStep) {
			case CreatorStep.COMPONENTS:
			case CreatorStep.ACCESSORIES:
			case CreatorStep.SUMMARY:
				this.shaftRenderer.draw();
				break;
		}
	}

	@On(CanvasEvent.PAN_MOVE)
	private handlePan(panVector: Vector2) {
		const MIN = RENDERER_CONFIG.PAN.MIN.clone(),
			MAX = RENDERER_CONFIG.PAN.MAX.clone();
		MIN.multiplyScalar(this.engine.scale);
		MAX.multiplyScalar(this.engine.scale);

		this.engine.translation.add(panVector);
		// this.engine.translation.clamp(MIN, MAX);

		this.engine.mainNeedsRedraw = true;
	}

	resizeRenderer(width: number) {
		let newWidth = width;
		const BORDER_WIDTH = 2;

		if (this.engine.viewStaggingArea())
			newWidth = newWidth - RENDERER_CONFIG.STAGING_CANVAS_WIDTH - BORDER_WIDTH;

		this.canvas.width = newWidth;

		this.resetTranslation();

		this.engine.mainNeedsRedraw = true;
	}
}

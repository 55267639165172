import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Title, Text, Transition } from '@mantine/core';
import {
	getFilterRelatedAttributes,
	mapComponentCategoryTypeName,
} from '@/utils/utilities';
import { useTypedSelector } from '@/store/hooks';
import { SingleAttribute } from '../singleAttribute/SingleAttribute';
import { ComponentCategoryType } from '@/api/ComponentCategoryApi';
import { canvasEngine } from '@/components/App';

export const AsideAccessories: FC = () => {
	const { product, options } = useTypedSelector((state) => state.creator);

	const doorsComponentCategory = useMemo(
		() =>
			product.productType!.componentCategories.find(
				(componentCategory) =>
					componentCategory.type === ComponentCategoryType.ACCESSORIES
			)!,
		[product.productType?.componentCategories]
	);

	const [isWindowError, setIsWindowError] = useState(
		canvasEngine.checkIsWindowError()
	);

	useEffect(() => {
		setIsWindowError(canvasEngine.checkIsWindowError());
	}, [options.withWindows]);

	return (
		<Box>
			<Title order={2} mb="md" pt="sm">
				{mapComponentCategoryTypeName(
					ComponentCategoryType.ACCESSORIES,
					product.productType!.viewType
				)}
			</Title>
			{getFilterRelatedAttributes(
				doorsComponentCategory,
				options.selectedAttributes
			).map((attribute, idx, arr) => (
				<SingleAttribute
					key={attribute.id}
					attribute={attribute}
					componentCategoryId={doorsComponentCategory.id}
					isLast={idx === arr.length - 1}
				/>
			))}
			<Transition
				mounted={isWindowError}
				transition="fade"
				duration={400}
				timingFunction="ease"
			>
				{(styles) => (
					<Text style={styles} c="red" mt="xs" size="sm">
						Niekóre okna rewizyjne nie mogą zostać umieszczone w wybranych
						miejscach!
					</Text>
				)}
			</Transition>
		</Box>
	);
};

import { ProductTypeViewType } from '@/api/ProductTypeApi';
import { CreatorStep } from '@/types/creator';
import { em } from '@mantine/core';

const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	Z_INDEX: {
		OVERLAY: 50,
		SCROLL_TOP: 50,
		NOTIFICATION: 100,
		DRAWER: 104,
		MODAL: 301,
		POPOVER: 302,
		TOOLTIP: 302,
		SELECT: 302,
		CREATOR_LOADING: 303,
	},
	BREAKPOINTS: {
		XS: em(576),
		SM: em(768),
		MD: em(992),
		LG: em(1200),
		XL: em(1408),
	},
	MAX_DESCRIPTION_LENGTH: 250,
	MOBILE_PREVIEW_SHOW_OFFSET: -50,
	MOBILE_PREVIEW_HIDE_OFFSET: 250,
	HISTORY_MAX_LENGTH: 10,
	DEFAULT_LANGUAGE: 'PL',
	SHAFT_DOORS_AMOUNT_OPTIONS: [1, 2, 3, 4, 5] as number[],
	CREATOR_STEPS: {
		[ProductTypeViewType.DISTRIBUTION_BOARD]: [
			CreatorStep.BASE,
			CreatorStep.COMPONENTS,
			CreatorStep.ACCESSORIES,
			CreatorStep.SUMMARY,
		] as CreatorStep[],
		[ProductTypeViewType.SHAFTS]: [
			CreatorStep.BASE,
			CreatorStep.COMPONENTS,
			CreatorStep.ACCESSORIES,
			CreatorStep.SUMMARY,
		] as CreatorStep[],
	},
} as const;

export default APP_CONFIG;

import {
	createListenerMiddleware,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@/store/index';
import { StoreKey } from '@/configs/storeKeys';
import {
	CreatorComponentCategory,
	CreatorOptions,
	CreatorProduct,
	CreatorRenderer,
	CreatorSelectedAttributes,
	CreatorStep,
	SelectedAttributeValue,
} from '@/types/creator';
import { Component } from '@/api/ComponentApi';
import { UUID } from '@/types/types';
import { ComponentCategoryType } from '@/api/ComponentCategoryApi';
import {
	getDimensionsFromComponent,
	updateDoors,
	updateShaftDoors,
	updateStaggingObject,
} from '@/utils/utilities';
import { ProductTypeViewType } from '@/api/ProductTypeApi';
import { canvasEngine } from '@/components/App';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import ProductApi, { ProductStatus } from '@/api/ProductApi';
import APP_CONFIG from '@/configs/appConfig';

export interface CreatorSliceState {
	product: CreatorProduct;
	options: CreatorOptions;
	renderer: CreatorRenderer;
}

const initialState: CreatorSliceState = {
	product: {
		id: '',
		netPrice: 0,
		grossPrice: 0,
		productType: null,
		status: ProductStatus.NOT_ADDED,
		comment: '',
		updatedAt: '',
		createdAt: '',
		productComponents: [],
	},
	options: {
		currentStep: CreatorStep.BASE,
		isInitialized: false,
		withWindows: false,
		isCreatorOpened: true,
		selectedComponents: {},
		selectedAttributes: {},
		selectedAttributesHistory: [],
	},
	renderer: {
		baseDimensions: {
			width: RENDERER_CONFIG.INITIAL_BASE_DIMENSIONS.width,
			height: RENDERER_CONFIG.INITIAL_BASE_DIMENSIONS.height,
			depth: RENDERER_CONFIG.INITIAL_BASE_DIMENSIONS.depth,
		},
		shaftDoorsNumber: 0,
		shaftDoors: [],
	},
};

export const creatorSlice = createSlice({
	name: StoreKey.CREATOR,
	initialState,
	reducers: {
		updateCreator: (
			state,
			{ payload }: PayloadAction<Partial<CreatorSliceState>>
		) => {
			Object.assign(state, payload);
		},
		updateProduct: (
			state,
			{ payload }: PayloadAction<Partial<CreatorProduct>>
		) => {
			Object.assign(state.product, payload);
		},
		updateOptions: (
			state,
			{ payload }: PayloadAction<Partial<CreatorOptions>>
		) => {
			Object.assign(state.options, payload);
		},
		updateRenderer: (
			state,
			{ payload }: PayloadAction<Partial<CreatorRenderer>>
		) => {
			Object.assign(state.renderer, payload);
		},
		updateDimensions(
			state,
			{ payload }: PayloadAction<CreatorRenderer['baseDimensions']>
		) {
			const oldHeight = state.renderer.baseDimensions.height,
				oldWidth = state.renderer.baseDimensions.width;
			Object.assign(state.renderer.baseDimensions, payload);

			if (state.product.productType?.viewType !== ProductTypeViewType.SHAFTS)
				return;

			if (payload.height !== oldHeight) {
				state.renderer.shaftDoorsNumber = 0;
				state.renderer.shaftDoors = [];
				canvasEngine.shaft.shaftDoors = [];
			}
			if (payload.width !== oldWidth)
				canvasEngine.shaft.adjustDoorsWidth(payload.width);
		},
		toggleCreatorStep: (state, { payload }: PayloadAction<'-' | '+'>) => {
			const indexOfStep = APP_CONFIG.CREATOR_STEPS[
				canvasEngine.viewMode
			].indexOf(state.options.currentStep);

			if (payload === '-') {
				if (!state.options.currentStep) {
					Object.assign(state, structuredClone(initialState));
					state.options.isCreatorOpened = true;
				} else
					state.options.currentStep =
						APP_CONFIG.CREATOR_STEPS[canvasEngine.viewMode][indexOfStep - 1];
				return;
			}
			state.options.currentStep =
				APP_CONFIG.CREATOR_STEPS[canvasEngine.viewMode][indexOfStep + 1];
		},
		resetCreator: (state) => {
			Object.assign(state, structuredClone(initialState));
			state.options.isCreatorOpened = true;
		},
		changeSelectedComponent: (
			state,
			{
				payload,
			}: PayloadAction<{
				componentCategory: CreatorComponentCategory;
				component: Component;
			}>
		) => {
			state.options.selectedComponents[payload.componentCategory.id] =
				payload.component;
		},
		changeSelectedAttributes: (
			state,
			{
				payload,
			}: PayloadAction<{
				componentCategoryId: UUID;
				attributeId: UUID;
				attributeValueId: SelectedAttributeValue;
			}>
		) => {
			if (
				state.options.selectedAttributesHistory.length ===
				APP_CONFIG.HISTORY_MAX_LENGTH
			)
				state.options.selectedAttributesHistory.shift();

			state.options.selectedAttributesHistory.push(
				state.options.selectedAttributes
			);

			state.options.selectedAttributes[payload.componentCategoryId][
				payload.attributeId
			] = payload.attributeValueId;
		},
		addToHistory: (
			{ options },
			{ payload }: PayloadAction<CreatorSelectedAttributes>
		) => {
			if (
				options.selectedAttributesHistory.length ===
				APP_CONFIG.HISTORY_MAX_LENGTH
			)
				options.selectedAttributesHistory.shift();

			options.selectedAttributesHistory.push(payload);
		},
		undoAttributesSelection: ({ options }) => {
			const prev = options.selectedAttributesHistory.pop();
			if (!prev) return;

			options.selectedAttributes = prev;
		},
		handleShaftDoorChange: (
			state,
			{
				payload,
			}: PayloadAction<{
				id: UUID;
				height?: number;
				windowsQuantity?: number;
			}>
		) => {
			const rendererShaftDoor = canvasEngine.shaft.shaftDoors.find(
					(door) => door.id === payload.id
				)!,
				currentMaxSlots = rendererShaftDoor.getTotalWindowSlots();

			for (const shaftDoor of state.renderer.shaftDoors) {
				if (shaftDoor.id !== payload.id) continue;
				Object.assign(shaftDoor, payload);

				if (payload.height) {
					canvasEngine.shaft.updateDoor(payload.id, payload.height);

					if (currentMaxSlots < shaftDoor.windowsQuantity) {
						shaftDoor.windowsQuantity = currentMaxSlots;
						rendererShaftDoor.setWindows(currentMaxSlots);
					}
				}
				if (
					payload.windowsQuantity !== undefined &&
					currentMaxSlots >= payload.windowsQuantity
				)
					canvasEngine.shaft.changeDoorWindowQuantity(
						payload.id,
						payload.windowsQuantity
					);
			}
		},
	},
});

export const {
	updateCreator,
	updateProduct,
	updateOptions,
	updateRenderer,
	updateDimensions,
	toggleCreatorStep,
	resetCreator,
	changeSelectedComponent,
	changeSelectedAttributes,
	addToHistory,
	undoAttributesSelection,
	handleShaftDoorChange,
} = creatorSlice.actions;

export const selectCreatorState = (state: RootState) => state.creator;
export default creatorSlice.reducer;

export const creatorListenerMiddleware = createListenerMiddleware();

creatorListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()({
	actionCreator: changeSelectedComponent,
	effect: async ({ payload }, listenerApi) => {
		switch (payload.componentCategory.type) {
			case ComponentCategoryType.BASE:
				const dimensions = getDimensionsFromComponent(
					payload.componentCategory,
					payload.component
				);

				if (dimensions) listenerApi.dispatch(updateDimensions(dimensions));

				break;
			case ComponentCategoryType.COMPONENTS:
				updateStaggingObject(payload.componentCategory, payload.component);
				return;
			case ComponentCategoryType.ACCESSORIES:
				updateShaftDoors(payload.componentCategory, payload.component);
				const { withWindows } = updateDoors(
					payload.componentCategory,
					payload.component
				);
				listenerApi.dispatch(updateOptions({ withWindows }));
				break;
		}
	},
});

creatorListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()({
	actionCreator: updateProduct,
	effect: async ({ payload }, listenerApi) => {
		listenerApi.cancelActiveListeners();
		await listenerApi.delay(1000);

		if (payload.comment)
			ProductApi.update({
				id: listenerApi.getState().creator.product.id as UUID,
				comment: payload.comment,
			});
	},
});

creatorListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()({
	actionCreator: updateRenderer,
	effect: async ({ payload }, listenerApi) => {
		if (payload.shaftDoorsNumber) {
			canvasEngine.shaft.createDoors(payload.shaftDoorsNumber);
			listenerApi.dispatch(
				updateRenderer({
					shaftDoors: canvasEngine.shaft.shaftDoors.map((shaftDoor) => ({
						id: shaftDoor.id,
						height: shaftDoor.height,
						windowsQuantity: shaftDoor.windows.length,
					})),
				})
			);
		}
	},
});

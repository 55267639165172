import { Renderer } from '../types/Renderer';

export abstract class BaseRenderer {
	protected renderer: Renderer;
	protected context: CanvasRenderingContext2D;

	constructor(renderer: Renderer) {
		this.renderer = renderer;
		this.context = renderer.context;
	}
}

import { ChangeEventHandler, FC } from 'react';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { updateProduct } from '@/store/slices/creatorSlice';
import { Textarea } from '@mantine/core';

export const CommentTextarea: FC = () => {
	const dispatch = useTypedDispatch();

	const { product } = useTypedSelector((state) => state.creator);

	const handleCommentChange: ChangeEventHandler<HTMLTextAreaElement> = ({
		target,
	}) => {
		dispatch(
			updateProduct({
				comment: target.value,
			})
		);
	};

	return (
		<Textarea
			placeholder="Wpisz uwagi"
			value={product.comment}
			onChange={handleCommentChange}
		/>
	);
};

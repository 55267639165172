import { ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { store } from '@/store/index';
import { MantineProvider } from './MantineProvider';
import { QueryProvider } from './QueryProvider';
import { ContentProvider } from './ContentProvider';

export const AppProviders = ({ children }: { children: ReactNode }) => {
	return (
		<Provider store={store}>
			<MantineProvider>
				<QueryProvider>
					<ContentProvider>
						<Suspense fallback={<></>}>{children}</Suspense>
					</ContentProvider>
				</QueryProvider>
			</MantineProvider>
		</Provider>
	);
};

import { Attachment, Translations, UUID } from '@/types/types';
import { ComponentCategory } from './ComponentCategoryApi';
import { QueryKey } from '@/configs/queryKeys';
import { DimensionAxis } from '@/types/creator';
import EntityApi from './EntityApi';

export enum ProductTypeViewType {
	DISTRIBUTION_BOARD = 'distribution-board',
	SHAFTS = 'shafts',
}

export interface ProductTypeDimensionSettings {
	min: number;
	max: number;
	step: number;
	pricePercentPerStep: number;
}

export interface ProductType {
	id: UUID;
	externalId: number;
	name: string;
	componentCategories: ComponentCategory[];
	viewType: ProductTypeViewType;
	price: number;
	translations: Translations<{
		name: string;
	}>;
	image?: Attachment;
	dimensionsSettings: Record<DimensionAxis, ProductTypeDimensionSettings>;
}

class ProductTypeApi extends EntityApi<ProductType> {
	constructor() {
		super(QueryKey.productTypes);
	}
}

export default new ProductTypeApi();

import { HttpStatusCode } from 'axios';
import { BaseEntity } from '@/api/EntityApi';
import { ErrorCode } from '@/configs/errorCode';
import { DeepKeys } from '@tanstack/react-table';
import { cancellablePromise } from '@/utils/utilities';
import LANGUAGES from '@/configs/languages';

export interface StringSignature<TData = any> {
	[key: string]: TData;
}

export type Language = keyof typeof LANGUAGES;
export type ForeignLanguage = Exclude<Language, 'PL'>;
export type Translations<TData> = Record<ForeignLanguage, TData>;

export type SortOrder = 'ASC' | 'DESC';

export interface Sort<TData extends BaseEntity = any> {
	key: DeepKeys<TData> | null;
	order: SortOrder;
}

export interface ApiQueryOptions<TData extends BaseEntity> {
	page: number;
	limit: number;
	search: string;
	sortBy: Sort<TData>;
}

export type CustomFilters = Record<string, unknown>;

export interface ApiPaginatedResponse<TData extends {} = {}> {
	data: TData[];
	meta: {
		currentPage: number;
		itemsPerPage: number;
		totalItems: number;
		totalPages: number;
	};
}

export interface ApiFormattedResponseData<TData extends {} = {}> {
	data: TData[];
	totalPages: number;
}

export interface Attachment {
	id: UUID;
	path: string;
	name: string;
	size: number;
	mimetype: string;
	width: number | null;
	height: number | null;
}

export type UUID = ReturnType<typeof crypto.randomUUID>;

export type ApiErrorMessage = ErrorCode | string | (ErrorCode | string)[];

export interface ApiError {
	error: string;
	message: ApiErrorMessage;
	status: HttpStatusCode;
}

export interface AppError {
	status: HttpStatusCode;
	stack: (ErrorCode | string)[];
}

export type CancelablePromise<TData extends {}> = ReturnType<
	typeof cancellablePromise<TData>
>;

export enum ComponentRelationType {
	ATTRIBUTE_VALUE = 'attributeValue',
	COMPONENT = 'component',
}

export interface ComponentRelation {
	type: ComponentRelationType;
	objectId: UUID;
	present: boolean;
}

export enum ParentWindowEvent {
	MODAL_CONTROL = 'modal-control',
	ADD_TO_CART = 'add-to-cart',
}

export type MessageData =
	| {
			title: ParentWindowEvent.MODAL_CONTROL;
			content: 'open' | 'close';
	  }
	| {
			title: ParentWindowEvent.MODAL_CONTROL;
			content: 'restart';
	  };

import { Attachment, Translations, UUID } from '@/types/types';
import { Component } from './ComponentApi';
import { QueryKey } from '@/configs/queryKeys';
import { AttributeValue } from './AttributeValueApi';
import { ProductType } from './ProductTypeApi';
import { ComponentCategoryMeta, SelectedAttributeValue } from '@/types/creator';
import { QueryFunctionContext } from '@tanstack/react-query';
import { Attribute } from './AttributeApi';
import httpClient from './httpClient';
import EntityApi from './EntityApi';

export enum ComponentCategoryType {
	BASE = 'base',
	COMPONENTS = 'components',
	ACCESSORIES = 'doors',
}

export interface ComponentCategory {
	id: UUID;
	name: string;
	attributeValues: AttributeValue[];
	productTypes: ProductType[];
	description: string;
	components: Component[];
	listOrder: number;
	image?: Attachment;
	type: ComponentCategoryType;
	componentsCount: number;
	isConfigurable: boolean;
	chosenAttributeValues: Record<UUID, UUID | ''>;
	translations: Translations<{
		name: string;
		description: string;
	}>;
	meta?: ComponentCategoryMeta;
}

class ComponentCategoryApi extends EntityApi<ComponentCategory> {
	constructor() {
		super(QueryKey.componentCategories);
	}

	async findComponentItem({
		queryKey,
	}: QueryFunctionContext<
		[QueryKey, UUID, QueryKey, SelectedAttributeValue[]]
	>) {
		const response = await httpClient.get<{
			component: Component | null;
			attributes: Attribute[];
		}>(`${queryKey[0]}/${queryKey[1]}/${queryKey[2]}`, {
			params: {
				selectedValues: queryKey[3].join(','),
			},
		});

		return {
			...response.data,
			attributes: response.data.attributes.reduce<
				Record<UUID, Record<UUID, boolean>>
			>((acc, curr) => {
				acc[curr.id] = curr.attributeValues.reduce<Record<UUID, boolean>>(
					(acc, curr) => {
						acc[curr.id] = !!curr.isAvailable;
						return acc;
					},
					{}
				);
				return acc;
			}, {}),
		};
	}
}

export default new ComponentCategoryApi();

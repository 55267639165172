import { FC } from 'react';
import { Box, Title } from '@mantine/core';
import { mapComponentCategoryTypeName } from '@/utils/utilities';
import { ComponentCategoryType } from '@/api/ComponentCategoryApi';
import { useTypedSelector } from '@/store/hooks';
import { ProductTypeViewType } from '@/api/ProductTypeApi';
import { AsideComponentsDistributionBoard } from './AsideComponentsDistributionBoard';
import { AsideComponentsShaft } from './AsideComponentsShaft';

export const AsideComponents: FC = () => {
	const { product } = useTypedSelector((state) => state.creator);

	return (
		<Box>
			<Title order={2} mb="md" pt="sm">
				{mapComponentCategoryTypeName(
					ComponentCategoryType.COMPONENTS,
					product.productType!.viewType
				)}
			</Title>
			{product.productType!.viewType ===
			ProductTypeViewType.DISTRIBUTION_BOARD ? (
				<AsideComponentsDistributionBoard />
			) : (
				<AsideComponentsShaft />
			)}
		</Box>
	);
};

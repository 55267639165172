import { Vector2 } from '@/renderer/utils/Vector2';
import { MainRenderer } from './MainRenderer';
import { BaseRenderer } from './BaseRenderer';
import { RendererUtils } from '../utils/RendererUtils';
import { Shaft } from '../objects/Shaft';
import { ShaftDoor } from '../objects/ShaftDoor';
import { ShaftWindow } from '../objects/ShaftWindow';
import RENDERER_CONFIG from '@/configs/rendererConfig';

const { COLORS, METRICS } = RENDERER_CONFIG;

type MetricsOptions = {
	shellThickness: number;
	lineXOffset: number;
	arrowSize: number;
	arrowOffset: number;
};

export class ShaftRenderer extends BaseRenderer {
	constructor(renderer: MainRenderer) {
		super(renderer);
	}

	draw() {
		const { shaft } = this.renderer.engine;

		const doorShaftShellOffset = new Vector2(
				shaft.doorShell,
				shaft.doorShell
			).multiplyScalar(this.renderer.engine.scale),
			dashedLine = [10, 5].map((num) => num * this.renderer.engine.scale);

		const keyholeOptions = {
			radius:
				RENDERER_CONFIG.SHAFT_DOORS.KEYHOLE_RADIUS * this.renderer.engine.scale,
			xOffset:
				RENDERER_CONFIG.SHAFT_DOORS.KEYHOLE_X_OFFSET *
					this.renderer.engine.scale +
				doorShaftShellOffset.x,
		};

		this.drawShaft(shaft);
		this.drawShaftDoors(
			shaft,
			doorShaftShellOffset,
			dashedLine,
			keyholeOptions
		);
	}

	private drawShaft(shaft: Shaft) {
		const outerRect = new Path2D(),
			innerRect = new Path2D();

		const pos = shaft.getCanvasPos();
		const { width, height } = shaft.getScaledDimensions();

		const shellThickness = shaft.shellThickness * this.renderer.engine.scale;

		outerRect.rect(
			pos.x - shellThickness,
			pos.y - shellThickness,
			width + shellThickness * 2,
			height + shellThickness * 2
		);
		innerRect.rect(pos.x, pos.y, width, height);
		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(outerRect);
		this.context.strokeStyle = COLORS.LIGH_BLACK;
		this.context.stroke(innerRect);

		this.drawMetrics(shaft);
	}

	private drawMetrics(shaft: Shaft) {
		const { width, height } = shaft.getScaledDimensions();
		const pos = shaft.getCanvasPos();

		const shellThickness = shaft.shellThickness * this.renderer.engine.scale;

		const lineLength = METRICS.LINE_LENGTH * this.renderer.engine.scale,
			arrowSize = METRICS.ARROW_SIZE * this.renderer.engine.scale,
			arrowOffset = METRICS.VERTICAL_LINE_OFFSET * this.renderer.engine.scale,
			lineXOffset = METRICS.LINE_LENGTH * this.renderer.engine.scale;

		const options = {
			shellThickness,
			lineLength,
			arrowSize,
			arrowOffset,
			lineXOffset,
		};

		this.drawHeightMetrics(width, height, pos, shaft.height, options);

		this.drawWidthMetrics(width, pos, shaft.width, options);
		this.drawDepthMetrics(
			new Vector2(pos.x - shellThickness, pos.y + height + shellThickness),
			shaft.depth,
			options
		);

		this.drawDoorsMetrics(pos, width, height, shaft.shaftDoors, options);
	}

	private drawHeightMetrics(
		width: number,
		height: number,
		pos: Vector2,
		originalHeight: number,
		options: MetricsOptions
	) {
		const { shellThickness, lineXOffset, arrowSize, arrowOffset } = options;

		const xOffset = lineXOffset * 2.5;

		const basePoint = new Vector2(
			pos.x + width + shellThickness,
			pos.y - shellThickness
		);

		const topLine = new Path2D();
		topLine.moveTo(basePoint.x, basePoint.y);
		topLine.lineTo(basePoint.x + xOffset, basePoint.y);

		const bottomLine = new Path2D();
		basePoint.set(
			pos.x + width + shellThickness,
			pos.y + height + shellThickness
		);
		bottomLine.moveTo(basePoint.x, basePoint.y);
		bottomLine.lineTo(basePoint.x + xOffset, basePoint.y);

		const arrowLine = new Path2D();
		basePoint.set(
			pos.x + width + xOffset - arrowOffset + shellThickness,
			pos.y - shellThickness
		);
		arrowLine.moveTo(basePoint.x, basePoint.y);
		arrowLine.lineTo(basePoint.x, basePoint.y + height + shellThickness * 2);

		const arrowTop = new Path2D();
		arrowTop.moveTo(basePoint.x, basePoint.y);
		arrowTop.lineTo(basePoint.x + arrowSize * 0.5, basePoint.y + arrowSize);
		arrowTop.lineTo(basePoint.x - arrowSize * 0.5, basePoint.y + arrowSize);
		arrowTop.closePath();

		const arrowBottom = new Path2D();
		arrowBottom.moveTo(basePoint.x, basePoint.y + height + shellThickness * 2);
		arrowBottom.lineTo(
			basePoint.x - arrowSize * 0.5,
			basePoint.y - arrowSize + height + shellThickness * 2
		);
		arrowBottom.lineTo(
			basePoint.x + arrowSize * 0.5,
			basePoint.y - arrowSize + height + shellThickness * 2
		);
		arrowBottom.closePath();

		const text = originalHeight.toString();

		this.context.save();
		this.context.translate(basePoint.x, basePoint.y + height / 2);

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);
		this.context.rotate(-Math.PI / 2);
		this.context.textAlign = 'center';
		this.context.textBaseline = 'bottom';
		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(text, 0, 0);
		this.context.restore();

		this.context.strokeStyle = COLORS.BLACK;

		if (!this.renderer.engine.withPlinth) this.context.stroke(topLine);

		this.context.stroke(bottomLine);
		this.context.stroke(arrowLine);
		this.context.fillStyle = COLORS.BLACK;
		this.context.fill(arrowTop);
		this.context.fill(arrowBottom);
	}

	private drawWidthMetrics(
		width: number,
		pos: Vector2,
		originalWidth: number,
		options: MetricsOptions
	) {
		const {
			shellThickness,
			lineXOffset: lineLength,
			arrowSize,
			arrowOffset,
		} = options;

		const lines = new Path2D();

		const basePoint = new Vector2(
			pos.x - shellThickness,
			pos.y - shellThickness
		);

		lines.moveTo(basePoint.x, basePoint.y);
		lines.lineTo(basePoint.x, basePoint.y - lineLength);

		basePoint.setX(pos.x + width + shellThickness);
		lines.moveTo(basePoint.x, basePoint.y);
		lines.lineTo(basePoint.x, basePoint.y - lineLength);

		basePoint.set(
			pos.x + width + shellThickness,
			pos.y - lineLength + arrowOffset - shellThickness
		);
		lines.moveTo(basePoint.x, basePoint.y);
		lines.lineTo(
			pos.x - shellThickness,
			pos.y - lineLength + arrowOffset - shellThickness
		);

		const arrowRight = new Path2D();
		arrowRight.moveTo(basePoint.x, basePoint.y);
		arrowRight.lineTo(basePoint.x - arrowSize, basePoint.y - arrowSize * 0.5);
		arrowRight.lineTo(basePoint.x - arrowSize, basePoint.y + arrowSize * 0.5);
		arrowRight.closePath();

		basePoint.set(
			pos.x - shellThickness,
			pos.y - lineLength + arrowOffset - shellThickness
		);
		const arrowLeft = new Path2D();
		arrowLeft.moveTo(basePoint.x, basePoint.y);
		arrowLeft.lineTo(basePoint.x + arrowSize, basePoint.y - arrowSize * 0.5);
		arrowLeft.lineTo(basePoint.x + arrowSize, basePoint.y + arrowSize * 0.5);
		arrowLeft.closePath();

		const text = originalWidth.toString();

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);

		this.context.textBaseline = 'alphabetic';
		this.context.textAlign = 'center';
		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(
			text,
			pos.x + width / 2,
			pos.y - lineLength - shellThickness
		);
		this.context.restore();

		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(lines);
		this.context.fillStyle = COLORS.BLACK;
		this.context.fill(arrowRight);
		this.context.fill(arrowLeft);
	}

	private drawDepthMetrics(
		basePoint: Vector2,
		depth: number,
		options: MetricsOptions
	) {
		const { lineXOffset: lineLength } = options;

		const line = new Path2D();

		line.moveTo(basePoint.x, basePoint.y);
		line.lineTo(basePoint.x - lineLength * 2, basePoint.y - lineLength * 2);

		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(line);

		const text = depth.toString();

		this.context.save();
		this.context.translate(basePoint.x - lineLength, basePoint.y - lineLength);

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);
		this.context.rotate(Math.PI / 4);
		this.context.textAlign = 'center';
		this.context.textBaseline = 'bottom';
		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(text, 0, 0);
		this.context.restore();
	}

	private drawDoorsMetrics(
		pos: Vector2,
		width: number,
		height: number,
		components: ShaftDoor[],
		options: MetricsOptions
	) {
		const { shellThickness, lineXOffset: lineLength } = options;

		const vertLine = new Path2D();

		const basePoint = new Vector2(
			pos.x + width + shellThickness + lineLength,
			pos.y - shellThickness
		);
		vertLine.moveTo(basePoint.x, basePoint.y);
		vertLine.lineTo(basePoint.x, basePoint.y + height + shellThickness * 2);

		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(vertLine);

		let horizontalLinesLevels: number[] = [];

		for (const component of components) {
			const { y } = component.getCanvasPos();
			const componentHeight = component.getScaledDimensions().height;

			horizontalLinesLevels.push(y);
			horizontalLinesLevels.push(y + componentHeight);
		}

		horizontalLinesLevels = [...new Set(horizontalLinesLevels)];
		let horizontalLine = new Path2D();

		const halfLineLength = lineLength / 2;

		for (const level of horizontalLinesLevels) {
			horizontalLine.moveTo(basePoint.x - halfLineLength, level);
			horizontalLine.lineTo(basePoint.x + halfLineLength, level);
		}

		this.context.stroke(horizontalLine);

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE
		);
		this.context.textAlign = 'center';
		this.context.textBaseline = 'bottom';
		this.context.fillStyle = COLORS.BLACK;

		for (const component of components) {
			this.context.save();
			const text = component.height.toString();

			const { height } = component.getScaledDimensions();

			const { y } = component.getCanvasPos();
			basePoint.set(
				pos.x + width + shellThickness + lineLength,
				y + height / 2
			);
			this.context.translate(basePoint.x, basePoint.y);

			this.context.rotate(-Math.PI / 2);

			this.context.fillText(text, 0, 0);
			this.context.restore();
		}
	}

	private drawShaftDoors(
		shaft: Shaft,
		doorShaftOffset: Vector2,
		dashedLine: number[],
		keyholeOptions: { radius: number; xOffset: number }
	) {
		const { shaftDoors, isDobuleWinged } = shaft;

		for (const shaftDoor of shaftDoors) {
			const { width, height } = shaftDoor.getScaledDimensions();
			const pos = shaftDoor.getCanvasPos();

			const door = new Path2D(),
				shellLine = new Path2D(),
				middleLine = new Path2D(),
				keyhole = new Path2D();

			this.context.save();

			door.rect(pos.x, pos.y, width, height);
			this.context.strokeStyle = COLORS.BLACK;
			this.context.stroke(door);

			if (isDobuleWinged) {
				middleLine.moveTo(pos.x + width / 2, pos.y);
				middleLine.lineTo(pos.x + width / 2, pos.y + height);

				this.context.save();
				this.context.lineWidth = 2;
				this.context.stroke(middleLine);
				this.context.restore();

				keyhole.arc(
					pos.x + width / 2 + keyholeOptions.xOffset,
					pos.y + height / 2,
					keyholeOptions.radius,
					0,
					Math.PI * 2
				);
			} else {
				const x =
					shaft.openDirection === 'right'
						? pos.x + +keyholeOptions.xOffset
						: pos.x + width - keyholeOptions.xOffset;

				keyhole.arc(
					x,
					pos.y + height / 2,
					keyholeOptions.radius,
					0,
					Math.PI * 2
				);
			}

			this.context.stroke(keyhole);

			shellLine.moveTo(pos.x + doorShaftOffset.x, pos.y + doorShaftOffset.y);
			shellLine.lineTo(
				pos.x + doorShaftOffset.x,
				pos.y + height - doorShaftOffset.y
			);
			shellLine.lineTo(
				pos.x + width - doorShaftOffset.x,
				pos.y + height - doorShaftOffset.y
			);
			shellLine.lineTo(
				pos.x + width - doorShaftOffset.x,
				pos.y + doorShaftOffset.y
			);
			shellLine.lineTo(pos.x + doorShaftOffset.x, pos.y + doorShaftOffset.y);

			this.context.setLineDash(dashedLine);
			this.context.strokeStyle = COLORS.BLACK;
			this.context.stroke(shellLine);

			this.context.restore();

			for (const window of shaftDoor.windows) {
				this.drawShaftDoorWindows(window);
			}
		}
	}

	private drawShaftDoorWindows(window: ShaftWindow) {
		const radius = window.getScaledRadius(),
			pos = window.getCanvasPos();

		const circle = new Path2D();
		circle.arc(pos.x, pos.y, radius, 0, Math.PI * 2);
		this.context.fillStyle = COLORS.LIGHT_GRAY;
		this.context.fill(circle);
		this.context.strokeStyle = COLORS.LIGH_BLACK;
		this.context.stroke(circle);
	}
}

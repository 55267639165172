import { MainRenderer } from '@/renderer/renderers/MainRenderer';
import { CanvasMouseHandler } from '@/renderer/CanvasMouseHandler';
import { useTypedSelector } from '@/store/hooks';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { canvasEngine } from '@/components/App';
import { Box, Flex, LoadingOverlay } from '@mantine/core';
import { Events } from '@/renderer/utils/Events';
import { CanvasEvent } from '@/renderer/types/CanvasEvent';
import { StagingAreaRenderer } from '@/renderer/renderers/StagingAreaRenderer';
import { StaggingMouseHandler } from '@/renderer/StaggingMouseHandler';
import { useDisclosure, useElementSize } from '@mantine/hooks';
import { CreatorStep } from '@/types/creator';
import { useMutation } from '@tanstack/react-query';
import { UUID } from '@/types/types';
import { ProductTypeViewType } from '@/api/ProductTypeApi';
import ProductApi from '@/api/ProductApi';
import classNames from 'classnames';

export const ProductRenderer: FC = () => {
	const canvasRef = useRef<HTMLCanvasElement>(null!);
	const stagingRef = useRef<HTMLCanvasElement>(null!);

	const [mainRenderer, setMainRenderer] = useState<MainRenderer>(null!);
	const [stagingRenderer, setStagingRenderer] = useState<StagingAreaRenderer>(
		null!
	);

	const { product, renderer, options } = useTypedSelector(
		(state) => state.creator
	);

	useEffect(() => {
		canvasEngine.mainNeedsRedraw = true;
		canvasEngine.stagingNeedsRedraw = true;

		const canvas = canvasRef.current,
			stagingCanvas = stagingRef.current;

		const canvasRenderer = new MainRenderer(canvas, canvasEngine),
			stagingAreaRenderer = new StagingAreaRenderer(
				stagingCanvas,
				canvasEngine
			);

		setMainRenderer(canvasRenderer);
		setStagingRenderer(stagingAreaRenderer);

		const canvasMouseHandler = new CanvasMouseHandler(canvasEngine, canvas);
		const staggingMouseHandler = new StaggingMouseHandler(
			canvasEngine,
			stagingCanvas
		);

		return () => {
			canvasMouseHandler.dispose();
			staggingMouseHandler.dispose();
		};
	}, []);

	useEffect(() => {
		canvasEngine.distributionBoard.resizeBoard(renderer.baseDimensions);
		canvasEngine.door.resize(renderer.baseDimensions);
		canvasEngine.shaft.resizeShaft(renderer.baseDimensions);
		canvasEngine.centerView(canvasRef.current);
	}, [renderer.baseDimensions]);

	const [loading, laodingControls] = useDisclosure(true);

	const { ref: sizeRef, width: containerWidth } = useElementSize();
	useLayoutEffect(() => {
		if (!mainRenderer) return;

		mainRenderer.resizeRenderer(containerWidth);
		canvasEngine.centerView(canvasRef.current);
		laodingControls.close();
	}, [containerWidth]);

	const saveOptionsMutation = useMutation({
		mutationFn: ProductApi.saveOptions,
	});

	useEffect(() => {
		if (!mainRenderer || !stagingRenderer) return;

		canvasEngine.currentStep = options.currentStep;

		stagingRenderer.resizeRenderer();
		mainRenderer.resizeRenderer(containerWidth);
		canvasEngine.centerView(canvasRef.current);

		if (options.currentStep === CreatorStep.ACCESSORIES)
			canvasEngine.door.resizeDoor();

		if (options.currentStep !== CreatorStep.BASE)
			saveOptionsMutation.mutate({
				id: product.id as UUID,
				options: canvasEngine.export(),
			});
	}, [options.currentStep]);

	const handleMouseLeave = () => {
		Events.getInstance().emit(CanvasEvent.MOUSE_LEAVE_PREVIEW);
	};

	const handleMouseLeaveMain = () => {
		Events.getInstance().emit(CanvasEvent.MOUSE_LEAVE_CANVAS);
	};

	return (
		<Flex
			onMouseLeave={handleMouseLeave}
			h="100%"
			ref={sizeRef}
			justify="center"
		>
			<LoadingOverlay visible={loading} />
			<Box
				component="canvas"
				id="canvas-renderer"
				ref={canvasRef}
				onContextMenu={(e) => e.preventDefault()}
				onMouseLeave={handleMouseLeaveMain}
			/>
			<Box
				component="canvas"
				id="canvas-staging"
				ref={stagingRef}
				onContextMenu={(e) => e.preventDefault()}
				className={classNames({
					'canvas-hidden':
						product.productType?.viewType !==
							ProductTypeViewType.DISTRIBUTION_BOARD ||
						options.currentStep !== CreatorStep.COMPONENTS,
				})}
			/>
		</Flex>
	);
};

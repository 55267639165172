import { FC, useMemo } from 'react';
import { Accordion } from '@mantine/core';
import { ComponentAccordion } from '../componentAccordion/ComponentAccordion';
import { ComponentCategoryType } from '@/api/ComponentCategoryApi';
import { useTypedSelector } from '@/store/hooks';
import { Loading } from '@/components/ui/loading/Loading';

export const AsideComponentsDistributionBoard: FC = () => {
	const { product } = useTypedSelector((state) => state.creator);

	const componentComponentCategories = useMemo(
		() =>
			product.productType!.componentCategories.filter(
				(componentCategory) =>
					componentCategory.type === ComponentCategoryType.COMPONENTS &&
					componentCategory.attributes.length
			)!,
		[product.productType!.componentCategories]
	);

	if (!componentComponentCategories.length) return <Loading />;

	return (
		<Accordion defaultValue={componentComponentCategories[0].id}>
			{componentComponentCategories.map((componentCategory) => (
				<ComponentAccordion
					key={componentCategory.id}
					componentCategory={componentCategory}
				/>
			))}
		</Accordion>
	);
};

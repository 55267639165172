import { configureStore } from '@reduxjs/toolkit';
import creatorReducer, {
	creatorListenerMiddleware,
} from './slices/creatorSlice';
import appReducer from './slices/appSlice';

export const store = configureStore({
	reducer: {
		app: appReducer,
		creator: creatorReducer,
	},
	devTools: import.meta.env.DEV,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {},
		}).prepend(creatorListenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

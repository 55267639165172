import { CanvasEngine } from '../CanvasEngine';
import { CanvasObject } from './CanvasObject';
import { Vector2 } from '@/renderer/utils/Vector2';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import { ExportRendererPayload } from '../types/ExportPayload';

export class ShaftWindow extends CanvasObject {
	radius = RENDERER_CONFIG.SHAFT_WINDOWS.RADIUS;

	constructor(engine: CanvasEngine, pos: Vector2) {
		super(engine);
		this.pos = pos;
	}

	getScaledRadius() {
		return this.radius * this.engine.scale;
	}

	export(): ExportRendererPayload['shaft']['shaftDoors'][number]['shaftWindows'][number] {
		return {
			pos: this.pos.toPoint(),
			radius: this.radius,
		};
	}
}

import React, { useLayoutEffect } from 'react';
import { Creator } from './creator/Creator';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { Modal } from '@mantine/core';
import { updateOptions } from '@/store/slices/creatorSlice';
import { ProductTypePicker } from './productTypePicker/ProductTypePicker';
import { CanvasEngine } from '@/renderer/CanvasEngine';
import { MessageData } from '@/types/types';

export const canvasEngine = new CanvasEngine();

const App: React.FC = () => {
	const dispatch = useTypedDispatch();

	const { product, options } = useTypedSelector((state) => state.creator);

	const modalControls = {
		open: () => {
			dispatch(updateOptions({ isCreatorOpened: true }));
		},
		close: () => {
			dispatch(updateOptions({ isCreatorOpened: false }));
		},
	};

	useLayoutEffect(() => {
		window.onmessage = ({ data }: MessageEvent<MessageData>) => {
			switch (data.title) {
				case 'modal-control':
					if (data.content === 'open') modalControls.open();
					// if (data.content === 'restart') {
					// 	dispatch(resetCreator());
					// 	dispatch(setLoading(false));
					// 	modalControls.close();
					// }
					break;
			}
		};
	}, []);

	return (
		<Modal
			opened={options.isCreatorOpened}
			title={
				product.productType
					? `Konfiguruj ${product.productType.name}`
					: 'Wybierz rodzaj produktu'
			}
			onClose={modalControls.close}
			transitionProps={{
				duration: 250,
				transition: 'slide-down',
				onExited: () => {
					window!.top!.postMessage(
						{
							title: 'modal-control',
							content: 'close',
						},
						'*'
					);
				},
			}}
		>
			{options.isInitialized && !!product.id ? (
				<Creator />
			) : (
				<ProductTypePicker />
			)}
		</Modal>
	);
};

export default App;

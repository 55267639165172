import { MainRenderer } from './MainRenderer';
import { BaseRenderer } from './BaseRenderer';
import { Door } from '../objects/Door';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import doorImages from '../assets/doors';

const { COLORS, COMPONENTS } = RENDERER_CONFIG;

export class DoorRenderer extends BaseRenderer {
	constructor(renderer: MainRenderer) {
		super(renderer);
	}

	draw() {
		this.drawDoor();

		if (this.renderer.engine.withPlinth)
			this.drawPlinth(this.renderer.engine.door);
	}

	private getImage(door: Door) {
		if (!doorImages[door.height]) return;

		const image = doorImages[door.height][door.width];
		return image;
	}

	private drawDoor() {
		const { door } = this.renderer.engine;

		const pos = door.getCanvasPos(),
			{ width, height } = door.getScaledDimensions();

		const image = this.getImage(door);

		if (image) {
			this.context.drawImage(image, pos.x, pos.y, width, height);
		} else {
			const rect = new Path2D();
			rect.rect(pos.x, pos.y, width, height);

			this.context.strokeStyle = COLORS.BLACK;
			this.context.stroke(rect);
		}

		if (door.withWindows)
			this.drawWindows(
				pos.x - this.renderer.engine.distributionBoard.getCanvasPos().x
			);
	}

	private drawWindows(xOffset: number) {
		const meters =
			this.renderer.engine.distributionBoard.getWindowAvailableMeters();

		const params = COMPONENTS.ELECTRICITY_METER_3F,
			scale = this.renderer.engine.scale,
			windowWidth = params.WINDOW_WIDTH * scale,
			windowHeight = params.WINDOW_HEIGHT * scale,
			yOffset = params.WINDOW_OFFSET_TOP * scale;

		for (const meter of meters) {
			const pos = meter.getCanvasPos(),
				posX =
					pos.x +
					xOffset +
					meter.getScaledDimensions().width / 2 -
					windowWidth / 2;

			const window = new Path2D();

			window.rect(posX, pos.y + yOffset, windowWidth, windowHeight);

			this.context.save();

			this.context.fillStyle = COLORS.WINDOW_GRAY;
			this.context.fill(window);
			this.context.strokeStyle = COLORS.BLACK;
			this.context.stroke(window);

			this.context.restore();
		}
	}

	private drawPlinth(door: Door) {
		const pos = door.getCanvasPos();
		const { width, height } = door.getScaledDimensions();

		const rect = new Path2D();
		rect.rect(
			pos.x,
			pos.y + height,
			width,
			this.renderer.engine.plinthHeight * this.renderer.engine.scale
		);

		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(rect);
	}
}

import { FC, useMemo } from 'react';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { updateDimensions } from '@/store/slices/creatorSlice';
import { DimensionAxis } from '@/types/creator';
import { prepareDimensionsData } from '@/utils/utilities';
import { Box, Select, SelectProps } from '@mantine/core';

export const DimensionsPicker: FC = () => {
	const dispatch = useTypedDispatch();

	const { product, renderer } = useTypedSelector((state) => state.creator);

	const data = useMemo(
		() => prepareDimensionsData(product.productType!.dimensionsSettings),
		[product.productType]
	);

	const handleChange =
		(axis: DimensionAxis): SelectProps['onChange'] =>
		(value) => {
			if (!value) return;

			dispatch(
				updateDimensions({
					...renderer.baseDimensions,
					[axis]: Number(value),
				})
			);
		};

	return (
		<Box mb="md">
			<Select
				mb="md"
				label="Wysokość"
				placeholder="Wybierz wartość"
				searchable
				data={data.height}
				value={renderer.baseDimensions.height.toString()}
				onChange={handleChange('height')}
			/>
			<Select
				mb="md"
				label="Szerokość"
				placeholder="Wybierz wartość"
				searchable
				data={data.width}
				value={renderer.baseDimensions.width.toString()}
				onChange={handleChange('width')}
			/>
			<Select
				label="Głębokość"
				placeholder="Wybierz wartość"
				searchable
				data={data.depth}
				value={renderer.baseDimensions.depth.toString()}
				onChange={handleChange('depth')}
			/>
		</Box>
	);
};

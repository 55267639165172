import {
	ApiPaginatedResponse,
	Attachment,
	Translations,
	UUID,
} from '@/types/types';
import { ComponentCategory } from './ComponentCategoryApi';
import { AttributeValue } from './AttributeValueApi';
import { QueryKey } from '@/configs/queryKeys';
import EntityApi from './EntityApi';
import httpClient from './httpClient';

export interface Component {
	id: UUID;
	name: string;
	componentIndex: string;
	render?: Attachment;
	pricePercentage: number;
	componentCategory: ComponentCategory;
	attributeValues: AttributeValue[];
	translations: Translations<{
		name: string;
	}>;
}

class ComponentApi extends EntityApi<Component> {
	constructor() {
		super(QueryKey.components);
		this.getNonConfigureableComponents =
			this.getNonConfigureableComponents.bind(this);
	}

	async getNonConfigureableComponents() {
		const response = await httpClient.get<ApiPaginatedResponse<Component>>(
			this.queryKey,
			{
				params: {
					['filter.componentCategory.isConfigurable']: false,
				},
			}
		);

		return response.data.data;
	}
}

export default new ComponentApi();

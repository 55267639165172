import { Vector2 } from '@/renderer/utils/Vector2';
import { CanvasEngine } from './CanvasEngine';
import { CanvasEvent } from './types/CanvasEvent';
import { RendererUtils } from './utils/RendererUtils';
import { MOUSE_BUTTON } from './types/utils';
import { ApplyEventHandlers, On } from './utils/Events';
import { NativeMouseEvent } from './types/Mouse';

@ApplyEventHandlers
export class StaggingMouseHandler {
	private mousePosition = new Vector2();
	private clientMousePosition = new Vector2();

	constructor(private engine: CanvasEngine, private canvas: HTMLCanvasElement) {
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.canvas.addEventListener('mousemove', this.onMouseMove);
		this.canvas.addEventListener('mousedown', this.onMouseDown);
		this.canvas.addEventListener('mouseup', this.onMouseUp);
	}

	@On(CanvasEvent.MOUSE_LEAVE_PREVIEW)
	mouseLeaveLeaveRenderer() {
		document.body.style.cursor = 'initial';

		const draggedObject = this.getDraggedObject();

		if (draggedObject) {
			draggedObject.isDragging = false;
			draggedObject.isMouseOver = false;

			draggedObject.returnToBase();
		}

		this.engine.stagingNeedsRedraw = true;
	}

	private getDraggedObject() {
		return this.engine.stagingArea.objects.find((object) => object.isDragging);
	}

	private onMouseMove(event: NativeMouseEvent) {
		const { clientX, clientY } = event;
		const eventPos = new Vector2(clientX, clientY);

		if (this.clientMousePosition.equals(eventPos)) return;

		this.clientMousePosition = eventPos;

		const rect = this.canvas.getBoundingClientRect();

		const x = clientX - rect.left;
		const y = clientY - rect.top;

		this.mousePosition.set(x, y);

		const draggedObject = this.getDraggedObject();

		if (draggedObject) {
			this.engine.events.emit(
				CanvasEvent.OBJECT_DRAGGING,
				draggedObject,
				this.mousePosition,
				event
			);
			document.body.style.cursor = 'grabbing';
			return;
		}

		for (const object of this.engine.stagingArea.objects) {
			if (RendererUtils.isMouseOverDraggable(this.mousePosition, object)) {
				document.body.style.cursor = 'grab';
				if (!object.isMouseOver) {
					this.engine.events.emit(CanvasEvent.MOUSE_ENTER, object, event);
				}
			} else {
				if (object.isMouseOver) {
					this.engine.events.emit(CanvasEvent.MOUSE_LEAVE_OBJECT, object);
				}
			}
		}
	}

	private onMouseUp(event: NativeMouseEvent) {
		document.body.style.cursor = 'initial';

		for (const object of this.engine.stagingArea.objects) {
			if (!object.isMouseOver) continue;

			this.engine.onMouseUp(object);
			document.body.style.cursor = 'grab';
			return;
		}
	}

	private onMouseDown(event: NativeMouseEvent) {
		if (event.button !== MOUSE_BUTTON.LEFT) return;

		for (const object of this.engine.stagingArea.objects) {
			if (!object.isMouseOver) continue;

			this.engine.events.emit(
				CanvasEvent.MOUSE_DOWN,
				object,
				event,
				this.mousePosition
			);
			return;
		}
	}

	dispose() {
		this.canvas.removeEventListener('mousemove', this.onMouseMove);
		this.canvas.removeEventListener('mousedown', this.onMouseDown);
		this.canvas.removeEventListener('mouseup', this.onMouseUp);
	}
}

import { DraggableObject } from '../objects/DraggableObject';
import { BoardObjectType } from '../types/ObjectTypes';
import { BaseRenderer } from './BaseRenderer';
import { Renderer } from '../types/Renderer';
import { Vector2 } from '@/renderer/utils/Vector2';
import { RendererUtils } from '../utils/RendererUtils';
import { getModulesCountName } from '@/utils/utilities';
import RENDERER_CONFIG from '@/configs/rendererConfig';

const { COLORS, COMPONENTS, METRICS } = RENDERER_CONFIG;

type DrawObjectData = {
	width: number;
	height: number;
	pos: Vector2;
	scale: number;
};

export class DraggableObjectsRenderer extends BaseRenderer {
	constructor(renderer: Renderer) {
		super(renderer);
	}

	draw(isStaging = false) {
		const objects = (
			isStaging
				? this.renderer.engine.stagingArea.objects
				: this.renderer.engine.draggableObjects
		).toSorted((a, b) => a.renderPriority - b.renderPriority);

		for (const object of objects) {
			this.drawObject(object);
		}
	}

	private drawObject(object: DraggableObject) {
		const { width, height } = object.isStagging
			? object.getStagingDimensions()
			: object.getScaledDimensions();
		const pos = object.isStagging ? object.stagingPos : object.getCanvasPos();
		const scale = object.isStagging
			? RENDERER_CONFIG.STAGING_CANVAS_SCALE
			: this.renderer.engine.scale;

		const data = { width, height, pos, scale };

		switch (object.objectType) {
			case BoardObjectType.GRILLE:
				this.drawGrille(object, data);
				break;
			case BoardObjectType.CUSTOM_GRILLE:
				this.drawCustomGrille(object, data);
				break;
			case BoardObjectType.MOUNTING_PLATE:
				this.drawMountingPlate(object, data);
				break;
			case BoardObjectType.SWITCH:
				this.drawSwitch(object, data);
				break;
			case BoardObjectType.ELECTRICITY_METER_1F:
			case BoardObjectType.ELECTRICITY_METER_3F:
				this.drawElectricityMeter(object, data);
				break;

			default:
				break;
		}
	}

	private drawGrille(object: DraggableObject, data: DrawObjectData) {
		const rect = new Path2D();
		const { width, height, pos, scale } = data;

		rect.rect(pos.x, pos.y, width, height);
		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(rect);
		this.context.fillStyle = COLORS.WHITE;
		this.context.fill(rect);

		if (object.meta.withInnerCut) {
			const innerCut = new Path2D();

			const innerCutWidth = width - 95 * scale,
				innerCutHeight = COMPONENTS.GRILLE.INNER_CUT_HEIGHT * scale;

			innerCut.rect(
				pos.x + width / 2 - innerCutWidth / 2,
				pos.y + height / 2 - innerCutHeight / 2,
				innerCutWidth,
				innerCutHeight
			);
			this.context.strokeStyle = COLORS.BLACK;
			this.context.stroke(innerCut);
			if (object.isInsideBoard) {
				this.context.save();
				this.context.textBaseline = 'middle';
				this.context.textAlign = 'center';

				this.context.font = RendererUtils.scaleFont(
					this.renderer.engine.scale,
					METRICS.FONT_SIZE
				);

				const modulesCount = RendererUtils.getGrilleModulesCountPerLength(
					object.width
				);

				this.context.fillStyle = COLORS.BLACK;
				this.context.fillText(
					`${modulesCount} ${getModulesCountName(modulesCount)}`,
					pos.x + width / 2,
					pos.y + height / 2
				);
				this.context.restore();
			}
		}

		const sideCircles = new Path2D(),
			sideCircleRadius = 3 * scale;
		sideCircles.arc(
			pos.x + sideCircleRadius * 2,
			pos.y + height / 2,
			sideCircleRadius,
			0,
			2 * Math.PI
		);

		sideCircles.moveTo(pos.x + width - sideCircleRadius, pos.y + height / 2);
		sideCircles.arc(
			pos.x + width - sideCircleRadius * 2,
			pos.y + height / 2,
			sideCircleRadius,
			0,
			2 * Math.PI
		);
		this.context.fillStyle = COLORS.WHITE;
		this.context.fill(sideCircles);
		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(sideCircles);

		let cornerCircle = new Path2D();
		const offsetToSides = 10 * scale,
			conerCircleRadius = 4 * scale,
			bananSize = 25 * scale,
			halfBananaSize = bananSize / 2,
			bananaPos = new Vector2();

		cornerCircle.arc(
			pos.x + conerCircleRadius * 2 + offsetToSides,
			pos.y + conerCircleRadius * 1.5 + offsetToSides,
			conerCircleRadius,
			0,
			2 * Math.PI
		);
		this.context.stroke(cornerCircle);

		RendererUtils.drawBananaShape(
			this.context,
			bananaPos.set(
				pos.x + conerCircleRadius * 2 + offsetToSides,
				pos.y + conerCircleRadius * 5.5 + offsetToSides
			),
			bananSize
		);

		cornerCircle = new Path2D();
		cornerCircle.arc(
			pos.x + width - conerCircleRadius * 2 - offsetToSides,
			pos.y + conerCircleRadius * 1.5 + offsetToSides,
			conerCircleRadius,
			0,
			2 * Math.PI
		);
		this.context.stroke(cornerCircle);

		this.context.save();

		bananaPos.set(
			pos.x + width - conerCircleRadius * 2 - offsetToSides,
			pos.y + conerCircleRadius * 1.5 + offsetToSides
		);

		this.context.translate(
			bananaPos.x + halfBananaSize,
			bananaPos.y + halfBananaSize
		);

		this.context.rotate(Math.PI / 2);

		RendererUtils.drawBananaShape(
			this.context,
			new Vector2(-halfBananaSize, halfBananaSize * 2.25),
			bananSize
		);

		this.context.restore();

		cornerCircle = new Path2D();
		cornerCircle.arc(
			pos.x + conerCircleRadius * 2 + offsetToSides,
			pos.y + height - conerCircleRadius * 1.5 - offsetToSides,
			conerCircleRadius,
			0,
			2 * Math.PI
		);
		this.context.stroke(cornerCircle);

		this.context.save();

		bananaPos.set(
			pos.x + conerCircleRadius * 2 + offsetToSides,
			pos.y + height - conerCircleRadius * 1.5 - offsetToSides
		);

		this.context.translate(
			bananaPos.x + halfBananaSize,
			bananaPos.y + halfBananaSize
		);

		this.context.rotate(Math.PI * 1.5);

		RendererUtils.drawBananaShape(
			this.context,
			new Vector2(halfBananaSize, halfBananaSize / 3),
			bananSize
		);

		this.context.restore();

		cornerCircle = new Path2D();
		cornerCircle.arc(
			pos.x + width - conerCircleRadius * 2 - offsetToSides,
			pos.y - conerCircleRadius * 1.5 + height - offsetToSides,
			conerCircleRadius,
			0,
			2 * Math.PI
		);
		this.context.stroke(cornerCircle);

		this.context.save();

		bananaPos.set(
			pos.x + width - conerCircleRadius * 2 - offsetToSides,
			pos.y - conerCircleRadius * 1.5 + height - offsetToSides
		);

		this.context.translate(
			bananaPos.x + halfBananaSize,
			bananaPos.y + halfBananaSize
		);

		this.context.rotate(Math.PI);

		RendererUtils.drawBananaShape(
			this.context,
			new Vector2(halfBananaSize, halfBananaSize * 2.25),
			bananSize
		);

		this.context.restore();
	}

	private drawCustomGrille(object: DraggableObject, data: DrawObjectData) {
		const rect = new Path2D();
		const { width, height, pos, scale } = data;

		rect.rect(pos.x, pos.y, width, height);
		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(rect);
		this.context.fillStyle = COLORS.WHITE;
		this.context.fill(rect);

		let cornerCircle = new Path2D();
		const offsetToSides = 10 * scale,
			conerCircleRadius = 4 * scale,
			bananSize = 25 * scale,
			halfBananaSize = bananSize / 2,
			bananaPos = new Vector2();

		cornerCircle.arc(
			pos.x + conerCircleRadius * 2 + offsetToSides,
			pos.y + conerCircleRadius * 1.5 + offsetToSides,
			conerCircleRadius,
			0,
			2 * Math.PI
		);
		this.context.stroke(cornerCircle);

		RendererUtils.drawBananaShape(
			this.context,
			bananaPos.set(
				pos.x + conerCircleRadius * 2 + offsetToSides,
				pos.y + conerCircleRadius * 5.5 + offsetToSides
			),
			bananSize
		);

		cornerCircle = new Path2D();
		cornerCircle.arc(
			pos.x + width - conerCircleRadius * 2 - offsetToSides,
			pos.y + conerCircleRadius * 1.5 + offsetToSides,
			conerCircleRadius,
			0,
			2 * Math.PI
		);
		this.context.stroke(cornerCircle);

		this.context.save();

		bananaPos.set(
			pos.x + width - conerCircleRadius * 2 - offsetToSides,
			pos.y + conerCircleRadius * 1.5 + offsetToSides
		);

		this.context.translate(
			bananaPos.x + halfBananaSize,
			bananaPos.y + halfBananaSize
		);

		this.context.rotate(Math.PI / 2);

		RendererUtils.drawBananaShape(
			this.context,
			new Vector2(-halfBananaSize, halfBananaSize * 2.25),
			bananSize
		);

		this.context.restore();

		cornerCircle = new Path2D();
		cornerCircle.arc(
			pos.x + conerCircleRadius * 2 + offsetToSides,
			pos.y + height - conerCircleRadius * 1.5 - offsetToSides,
			conerCircleRadius,
			0,
			2 * Math.PI
		);
		this.context.stroke(cornerCircle);

		this.context.save();

		bananaPos.set(
			pos.x + conerCircleRadius * 2 + offsetToSides,
			pos.y + height - conerCircleRadius * 1.5 - offsetToSides
		);

		this.context.translate(
			bananaPos.x + halfBananaSize,
			bananaPos.y + halfBananaSize
		);

		this.context.rotate(Math.PI * 1.5);

		RendererUtils.drawBananaShape(
			this.context,
			new Vector2(halfBananaSize, halfBananaSize / 3),
			bananSize
		);

		this.context.restore();

		cornerCircle = new Path2D();
		cornerCircle.arc(
			pos.x + width - conerCircleRadius * 2 - offsetToSides,
			pos.y - conerCircleRadius * 1.5 + height - offsetToSides,
			conerCircleRadius,
			0,
			2 * Math.PI
		);
		this.context.stroke(cornerCircle);

		this.context.save();

		bananaPos.set(
			pos.x + width - conerCircleRadius * 2 - offsetToSides,
			pos.y - conerCircleRadius * 1.5 + height - offsetToSides
		);

		this.context.translate(
			bananaPos.x + halfBananaSize,
			bananaPos.y + halfBananaSize
		);

		this.context.rotate(Math.PI);

		RendererUtils.drawBananaShape(
			this.context,
			new Vector2(halfBananaSize, halfBananaSize * 2.25),
			bananSize
		);

		this.context.restore();

		this.context.save();
		this.context.textBaseline = 'middle';
		this.context.textAlign = 'center';

		this.context.font = RendererUtils.scaleFont(
			this.renderer.engine.scale,
			METRICS.FONT_SIZE * 2
		);

		const text = object.meta.cutType || '';

		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(text, pos.x + width / 2, pos.y + height / 2);
		this.context.restore();
	}

	private drawMountingPlate(object: DraggableObject, data: DrawObjectData) {
		const rect = new Path2D();

		const { width, height, pos } = data;

		rect.rect(pos.x, pos.y, width, height);
		this.context.fillStyle = COLORS.WHITE;
		this.context.fill(rect);
		this.context.strokeStyle = COLORS.BLACK;
		this.context.stroke(rect);

		this.context.save();
		this.context.textBaseline = 'middle';
		this.context.textAlign = 'center';

		let fontSize = 100;
		this.context.font = fontSize + 'px Inter';

		const text = 'P. montażowa';
		let textMetrics = this.context.measureText(text);

		while (textMetrics.width > width) {
			fontSize--;
			this.context.font = `${fontSize}px Inter`;
			textMetrics = this.context.measureText(text);
		}

		this.context.font = `${Math.min(fontSize - 2, height * 0.5)}px Inter`;

		this.context.fillStyle = COLORS.BLACK;
		this.context.fillText(text, pos.x + width / 2, pos.y + height / 2);
		this.context.restore();
	}

	private drawSwitch(object: DraggableObject, data: DrawObjectData) {
		const { width, height, pos } = data;

		const innerBackground = new Path2D();
		innerBackground.rect(pos.x, pos.y, width, height);
		this.context.fillStyle = COLORS.WHITE;
		this.context.fill(innerBackground);

		const outerShape = new Path2D();

		const offsetX = COMPONENTS.SWITCH.SHAPE_X_OFFSET * data.scale,
			offsetY = COMPONENTS.SWITCH.SHAPE_Y_OFFSET * data.scale;

		outerShape.moveTo(pos.x + offsetX, pos.y);
		outerShape.lineTo(pos.x + width - offsetX, pos.y);
		outerShape.lineTo(pos.x + width - offsetX, pos.y + offsetY);
		outerShape.lineTo(pos.x + width, pos.y + offsetY);
		outerShape.lineTo(pos.x + width, pos.y + height - offsetY);
		outerShape.lineTo(pos.x + width - offsetX, pos.y + height - offsetY);
		outerShape.lineTo(pos.x + width - offsetX, pos.y + height);
		outerShape.lineTo(pos.x + offsetX, pos.y + height);
		outerShape.lineTo(pos.x + offsetX, pos.y + height - offsetY);
		outerShape.lineTo(pos.x, pos.y + height - offsetY);
		outerShape.lineTo(pos.x, pos.y + offsetY);
		outerShape.lineTo(pos.x + offsetX, pos.y + offsetY);
		outerShape.lineTo(pos.x + offsetX, pos.y);

		this.context.stroke(outerShape);

		const innerRectWidth = COMPONENTS.SWITCH.INNER_RECT_WIDTH * data.scale;

		const innerRect = new Path2D();
		innerRect.rect(
			pos.x + width / 2 - innerRectWidth / 2,
			pos.y + height / 2 - innerRectWidth / 2,
			innerRectWidth,
			innerRectWidth
		);

		this.context.stroke(innerRect);

		const circleRadius = COMPONENTS.SWITCH.CIRCLE_RADIUS * data.scale;

		const bottomCircle = new Path2D();

		bottomCircle.arc(
			pos.x + width / 2,
			pos.y + height - circleRadius - offsetY / 4,
			circleRadius,
			0,
			2 * Math.PI
		);

		this.context.stroke(bottomCircle);

		const topCircle = new Path2D();

		topCircle.arc(
			pos.x + width / 2,
			pos.y + circleRadius + offsetY / 4,
			circleRadius,
			0,
			2 * Math.PI
		);
		topCircle.arc(
			pos.x + width / 2,
			pos.y + circleRadius + offsetY / 4,
			circleRadius * 0.6,
			0,
			2 * Math.PI
		);

		this.context.stroke(topCircle);
	}

	private async drawElectricityMeter(
		object: DraggableObject,
		data: DrawObjectData
	) {
		const cvs = this.renderer.engine.assetsLib.assets[object.objectType].get(
			data.scale
		);

		if (!cvs) return;

		const { width, height, pos } = data;

		this.context.save();
		const background = new Path2D();
		background.rect(pos.x, pos.y, width, height);
		this.context.fillStyle = COLORS.WHITE;
		this.context.fill(background);
		this.context.drawImage(cvs, pos.x, pos.y);

		this.context.restore();
	}
}

import { FC, useMemo } from 'react';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { Select, SelectProps } from '@mantine/core';
import { updateRenderer } from '@/store/slices/creatorSlice';
import { calculateMaxShaftDoorsAmount } from '@/utils/utilities';
import APP_CONFIG from '@/configs/appConfig';

export const ShaftDoorsNumberSelect: FC = () => {
	const dispatch = useTypedDispatch();

	const { renderer } = useTypedSelector((state) => state.creator);

	const data = useMemo(() => {
		const maxAvailableDoors = calculateMaxShaftDoorsAmount(
			renderer.baseDimensions.height
		);

		return APP_CONFIG.SHAFT_DOORS_AMOUNT_OPTIONS.map((doorNumber) => ({
			value: doorNumber.toString(),
			label: `${doorNumber} drzwi`,
			disabled: doorNumber > maxAvailableDoors,
		}));
	}, [renderer]);

	const handleChange: SelectProps['onChange'] = (val) => {
		if (!val) return;

		dispatch(
			updateRenderer({
				shaftDoorsNumber: Number(val),
			})
		);
	};

	return (
		<Select
			mb="xs"
			label="Liczba drzwi"
			placeholder="Wybierz wartość"
			data={data}
			value={
				renderer.shaftDoorsNumber ? renderer.shaftDoorsNumber.toString() : null
			}
			onChange={handleChange}
		/>
	);
};

export enum BoardObjectType {
	GRILLE = 'grille',
	CUSTOM_GRILLE = 'customGrille',
	MOUNTING_PLATE = 'mountingPlate',
	SWITCH = 'switch',
	ELECTRICITY_METER_1F = 'electricityMeter1f',
	ELECTRICITY_METER_3F = 'electricityMeter3f',
}

export enum ShaftObjectType {
	DOOR = 'shaftDoor',
	WINDOW = 'shaftWindow',
}

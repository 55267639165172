import {
	Attachment,
	UUID,
	Translations,
	ComponentRelation,
} from '@/types/types';
import { Attribute } from './AttributeApi';
import { QueryKey } from '@/configs/queryKeys';
import { AttributeValueMeta } from '@/types/creator';
import EntityApi from './EntityApi';

export interface AttributeValue {
	id: UUID;
	attribute: Attribute;
	value: string;
	displayName: string;
	description: string;
	image?: Attachment;
	listOrder: number;
	isAvailable: boolean;
	relations: ComponentRelation[];
	translations: Translations<{
		value: string;
		description: string;
		displayName: string;
	}>;
	meta?: AttributeValueMeta;
}

class AttributeValueApi extends EntityApi<AttributeValue> {
	constructor() {
		super(QueryKey.attributeValues);
	}
}

export default new AttributeValueApi();

import { FC, useEffect } from 'react';
import {
	Box,
	Divider,
	Flex,
	LoadingOverlay,
	Table,
	Text,
	Title,
} from '@mantine/core';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { updateProduct } from '@/store/slices/creatorSlice';
import { ComponentCategoryType } from '@/api/ComponentCategoryApi';
import { canvasEngine } from '@/components/App';
import { useMutation } from '@tanstack/react-query';
import { pick } from 'lodash';
import { UUID } from '@/types/types';
import { useContent } from '@/providers/ContentProvider';
import { formatPrice, getAttributeValueName } from '@/utils/utilities';
import { CommentTextarea } from '../commentTextarea/CommentTextarea';
import { BaseEntity } from '@/api/EntityApi';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import ProductApi from '@/api/ProductApi';
import styles from '../Aside.module.scss';

export const AsideSummary: FC = () => {
	const dispatch = useTypedDispatch();

	const { getEntityText } = useContent();

	const { product, options, renderer } = useTypedSelector(
		(state) => state.creator
	);

	const baseProductComponent = product.productComponents.find(
			({ componentData }) =>
				componentData.componentCategory.type === ComponentCategoryType.BASE
		),
		accessoriesProductComponent = product.productComponents.find(
			({ componentData }) =>
				componentData.componentCategory.type ===
				ComponentCategoryType.ACCESSORIES
		),
		comonentsProductComponent = product.productComponents.filter(
			({ componentData }) =>
				componentData.componentCategory.type ===
				ComponentCategoryType.COMPONENTS
		);

	const syncProductComponentsMutation = useMutation({
		mutationFn: ProductApi.syncProductComponents,
		onSuccess: (product) => {
			dispatch(
				updateProduct(
					pick(product, [
						'updatedAt',
						'netPrice',
						'grossPrice',
						'productComponents',
					])
				)
			);
		},
	});

	useEffect(() => {
		const baseComponents = Object.values(options.selectedComponents)
			.filter(
				(component) =>
					!!component &&
					component.componentCategory.type !== ComponentCategoryType.COMPONENTS
			)
			.map((component) => ({ id: component!.id }));

		const components = baseComponents
			.concat(
				canvasEngine.draggableObjects.map((obj) => ({
					id: obj.componentId,
				}))
			)
			.concat(
				canvasEngine.shaft.shaftDoors.reduce<BaseEntity[]>((acc, curr) => {
					acc.push({
						id: canvasEngine.shaft.shaftDoorComponentId,
					});
					return acc.concat(
						curr.windows.map(() => ({
							id: canvasEngine.shaft.shaftDoorWindowComponentId,
						}))
					);
				}, [])
			);

		syncProductComponentsMutation.mutate({
			productId: product.id as UUID,
			components,
			dimensions: renderer.baseDimensions,
		});
	}, [options.selectedComponents]);

	return (
		<Box pos="relative">
			<LoadingOverlay visible={syncProductComponentsMutation.isPending} />
			<Title order={2} mb="md" pt="sm">
				Podsumowanie
			</Title>
			{baseProductComponent?.componentData.values
				.toSorted((a, b) => a.attribute.listOrder - b.attribute.listOrder)
				.map((attributeValue) => (
					<Flex key={attributeValue.id} w="100%" gap={12} mb={12}>
						<Text fw={600}>
							{getEntityText(attributeValue.attribute, 'name')}:
						</Text>
						<Text className={styles.summaryValue} fw={500} c="dark.6">
							{getAttributeValueName(attributeValue)}
						</Text>
					</Flex>
				))}
			{accessoriesProductComponent?.componentData.values
				.filter((attributeValue) => {
					if (
						attributeValue.attribute.meta?.attributeType ===
							'shaftDoorOpenDirection' &&
						renderer.baseDimensions.width >=
							RENDERER_CONFIG.SHAFT_DOORS.DOUBLE_WING_WIDTH_BREAKPOINT
					)
						return false;

					return true;
				})
				.toSorted((a, b) => a.attribute.listOrder - b.attribute.listOrder)
				.map((attributeValue) => (
					<Flex key={attributeValue.id} w="100%" gap={12} mb={12}>
						<Text fw={600}>
							{getEntityText(attributeValue.attribute, 'name')}:
						</Text>
						<Text className={styles.summaryValue} fw={500} c="dark.6">
							{getAttributeValueName(attributeValue)}
						</Text>
					</Flex>
				))}

			{!!comonentsProductComponent.length && (
				<>
					<Divider my="md" label={<Box mx="xs">Komponenty</Box>} />
					<Table w="100%">
						<Table.Thead>
							<Table.Tr>
								<Table.Th>Nazwa</Table.Th>
								<Table.Th ta="end">Liczba</Table.Th>
								<Table.Th ta="end">Cena</Table.Th>
							</Table.Tr>
						</Table.Thead>
						<Table.Tbody>
							{comonentsProductComponent
								.toSorted((a, b) => b.quantity - a.quantity)
								.map((productComponent) => (
									<Table.Tr key={productComponent.id}>
										<Table.Td>
											{getEntityText(
												productComponent.componentData.componentCategory,
												'name'
											)}{' '}
											{productComponent.componentData.componentCategory
												.isConfigurable && (
												<Text c="dark.6">
													{productComponent.componentData.name}
												</Text>
											)}
										</Table.Td>
										<Table.Td ta="end">{productComponent.quantity}</Table.Td>
										<Table.Td ta="end">
											{formatPrice(productComponent.netPrice / 100)}
										</Table.Td>
									</Table.Tr>
								))}
						</Table.Tbody>
					</Table>
				</>
			)}
			<Divider my="md" label={<Box mx="xs">Uwagi</Box>} />
			<CommentTextarea />
		</Box>
	);
};

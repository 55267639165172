import { Dimensions } from '../types/utils';
import { CanvasEngine } from '../CanvasEngine';
import { CanvasObject } from './CanvasObject';
import { DraggableObject } from './DraggableObject';
import { RendererUtils } from '../utils/RendererUtils';
import { BoardObjectType } from '../types/ObjectTypes';
import { EnabledDraggingRect } from './EnabledDraggingRect';
import { ExportRendererPayload } from '../types/ExportPayload';
import RENDERER_CONFIG from '@/configs/rendererConfig';

export class DistributionBoard extends CanvasObject {
	shellThickness = 15;
	depth = 350;
	enabledDraggingRects: EnabledDraggingRect[] = [];

	constructor(engine: CanvasEngine) {
		super(engine);
		this.width = RENDERER_CONFIG.INITIAL_BASE_DIMENSIONS.width;
		this.height = RENDERER_CONFIG.INITIAL_BASE_DIMENSIONS.height;
	}

	private getWindowForbiddenXAxis() {
		if (
			this.width >
			RENDERER_CONFIG.DOOR_CONNECTION_LINE_WIDTH_BREAKPOINTS.DOUBLE_MIDDLE
		)
			return this.pos.x + this.width / 2;

		if (
			this.width >
			RENDERER_CONFIG.DOOR_CONNECTION_LINE_WIDTH_BREAKPOINTS.DOUBLE_LEFT_SIDE
		)
			return this.pos.x + 440;
	}

	resizeBoard(dimensions: Dimensions) {
		if (dimensions.width) this.width = dimensions.width;
		if (dimensions.height) this.height = dimensions.height;
		if (dimensions.depth) this.depth = dimensions.depth;
	}

	isObjectOverlapingBoard(object: DraggableObject) {
		return RendererUtils.isRectOverlapOtherRect(object, this);
	}

	isObjectInsideBoard(object: DraggableObject) {
		const isInside = this.enabledDraggingRects.some((rect) =>
			RendererUtils.isRectInsideOtherRect(object, rect, 5)
		);

		return isInside;
	}

	setAdjustableObjectPosAndWidth(object: DraggableObject) {
		object.pos.setX(this.pos.x);
		object.width = this.width;
	}

	calculateEnabledDraggingRects(object: DraggableObject) {
		const rect = new EnabledDraggingRect(this.engine);
		rect.pos = this.pos.clone();
		rect.width = this.width;
		rect.height = this.height;

		switch (object.objectType) {
			case BoardObjectType.GRILLE:
			case BoardObjectType.CUSTOM_GRILLE:
			case BoardObjectType.MOUNTING_PLATE:
			case BoardObjectType.SWITCH:
				this.enabledDraggingRects.push(rect);
				break;

			case BoardObjectType.ELECTRICITY_METER_3F:
			case BoardObjectType.ELECTRICITY_METER_1F:
				rect.pos.setX(
					rect.pos.x + RENDERER_CONFIG.DISTRIBUTION_BOARD_DRAG_GRID_SIZE
				);
				rect.width -= RENDERER_CONFIG.DISTRIBUTION_BOARD_DRAG_GRID_SIZE * 2;
				this.enabledDraggingRects.push(rect);
				break;
			default:
				break;
		}
	}

	getWindowAvailableMeters() {
		const forbiddenAxis = this.getWindowForbiddenXAxis();

		return this.engine.draggableObjects.filter((obj) => {
			if (obj.objectType !== BoardObjectType.ELECTRICITY_METER_3F) return false;

			if (!forbiddenAxis) return true;

			const windowWidth =
					RENDERER_CONFIG.COMPONENTS.ELECTRICITY_METER_3F.WINDOW_WIDTH,
				posX = obj.pos.x + obj.width / 2 - windowWidth / 2;

			return posX + windowWidth < forbiddenAxis || posX > forbiddenAxis;
		});
	}

	export(): ExportRendererPayload['distributionBoard'] {
		return {
			pos: this.pos.toPoint(),
			shellThickness: this.shellThickness,
			depth: this.depth,
			width: this.width,
			height: this.height,
		};
	}
}

export enum CanvasEvent {
	UPDATE_RENDERER = 'updateRenderer',
	MOUSE_LEAVE_CANVAS = 'mouseLeaveCanvas',
	MOUSE_LEAVE_PREVIEW = 'mouseLeavePreview',
	MOUSE_DOWN = 'mouseDown',
	MOUSE_UP = 'mouseUp',
	MOUSE_ENTER = 'mouseEnter',
	MOUSE_LEAVE_OBJECT = 'mouseLeaveObject',
	MOUSE_SCROLL = 'mouseScroll',
	PAN_MOVE = 'panMove',
	OBJECT_DRAGGING = 'objectDragging',
	RESET_TRANSLATION = 'resetTranslation',
}

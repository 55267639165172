import { UUID, Translations, ComponentRelation } from '@/types/types';
import { AttributeValue } from './AttributeValueApi';
import { QueryKey } from '@/configs/queryKeys';
import { AttributeMeta } from '@/types/creator';
import EntityApi from './EntityApi';

export enum AttributeViewType {
	SELECT,
	SWITCH,
}

export interface Attribute {
	id: UUID;
	name: string;
	description?: string;
	listOrder: number;
	viewType: AttributeViewType;
	attributeValues: AttributeValue[];
	suffix?: string;
	relations: ComponentRelation[];
	translations: Translations<{
		name: string;
		description: string;
	}>;
	meta?: AttributeMeta;
}

class AttributeApi extends EntityApi<Attribute> {
	constructor() {
		super(QueryKey.attributes);
	}
}

export default new AttributeApi();

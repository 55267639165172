import { ProductType } from './ProductTypeApi';
import { QueryKey } from '@/configs/queryKeys';
import { Attachment, UUID } from '@/types/types';
import { ComponentCategory } from './ComponentCategoryApi';
import { AttributeValue } from './AttributeValueApi';
import { ExportRendererPayload } from '@/renderer/types/ExportPayload';
import { CreatorRenderer } from '@/types/creator';
import EntityApi, { BaseEntity } from './EntityApi';
import httpClient from './httpClient';

export enum ProductStatus {
	NOT_ADDED,
	ADDDED_TO_CART,
}

export interface ComponentData {
	id: UUID;
	name: string;
	componentIndex: string;
	componentCategory: ComponentCategory;
	values: AttributeValue[];
}

export interface ProductComponent {
	id: UUID;
	product: Product;
	quantity: number;
	netPrice: number;
	grossPrice: number;
	componentData: ComponentData;
}

export interface Product {
	id: UUID;
	netPrice: number;
	grossPrice: number;
	productType: ProductType | null;
	productComponents: ProductComponent[];
	status: ProductStatus;
	options: ExportRendererPayload;
	comment: string;
	updatedAt: string;
	image?: Attachment;
	createdAt: string;
}

class ProductApi extends EntityApi<Product> {
	constructor() {
		super(QueryKey.products);
		this.syncProductComponents = this.syncProductComponents.bind(this);
		this.resetProductComponents = this.resetProductComponents.bind(this);
		this.saveOptions = this.saveOptions.bind(this);
		this.submit = this.submit.bind(this);
	}

	async syncProductComponents({
		productId,
		components,
		dimensions,
	}: {
		productId: UUID;
		components: BaseEntity[];
		dimensions: CreatorRenderer['baseDimensions'];
	}) {
		const response = await httpClient.patch<Product>(
			`${this.queryKey}/${productId}/add-components`,
			{
				components,
				...dimensions,
			}
		);

		return response.data;
	}

	async resetProductComponents(productId: UUID) {
		await httpClient.delete(`${this.queryKey}/${productId}/items`);
	}

	async saveOptions({
		id,
		options,
	}: {
		id: UUID;
		options: ExportRendererPayload;
	}) {
		const response = await httpClient.patch<Product>(`${this.queryKey}/${id}`, {
			options,
		});

		return response.data;
	}

	async submit(productId: UUID) {
		const response = await httpClient.patch<Product>(
			`${this.queryKey}/${productId}`,
			{
				status: ProductStatus.ADDDED_TO_CART,
			}
		);

		return response.data;
	}
}

export default new ProductApi();

import { Vector2 } from '@/renderer/utils/Vector2';

export class Line {
	constructor(public p1: Vector2, public p2: Vector2) {}

	findMidpoint(line: Line) {
		const x = (this.p1.x + line.p2.x) / 2;
		const y = (this.p1.y + line.p2.y) / 2;
		return new Vector2(x, y);
	}

	get length() {
		return Math.sqrt(
			Math.pow(this.p2.x - this.p1.x, 2) + Math.pow(this.p2.y - this.p1.y, 2)
		);
	}
}

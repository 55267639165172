import React, { useEffect } from 'react';
import {
	Box,
	Title,
	UnstyledButton,
	Image,
	LoadingOverlay,
} from '@mantine/core';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { useMediaQuery } from '@mantine/hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContent } from '@/providers/ContentProvider';
import {
	getFileSrc,
	getInitialSelectedAttributes,
	getInitialSelectedComponents,
	groupAttributeValues,
} from '@/utils/utilities';
import { updateOptions, updateProduct } from '@/store/slices/creatorSlice';
import { CreatorProductType } from '@/types/creator';
import { Loading } from '../ui/loading/Loading';
import { canvasEngine } from '@/components/App';
import { UUID } from '@/types/types';
import { omit, pick } from 'lodash';
import ProductTypeApi, { ProductType } from '@/api/ProductTypeApi';
import ProductApi from '@/api/ProductApi';
import APP_CONFIG from '@/configs/appConfig';
import styles from './ProductTypePicker.module.scss';

export const ProductTypePicker: React.FC = () => {
	const dispatch = useTypedDispatch();

	const queryClient = useQueryClient();

	const { getEntityText } = useContent();

	const { product, options } = useTypedSelector((state) => state.creator);

	const isMobile = useMediaQuery(`(max-width: ${APP_CONFIG.BREAKPOINTS.MD})`);

	const productTypesQuery = useQuery({
		queryKey: [ProductTypeApi.queryKey],
		queryFn: ProductTypeApi.getAll,
	});

	const productTypeQuery = useQuery({
		queryKey: [ProductTypeApi.queryKey, options.productTypeId!],
		queryFn: ProductTypeApi.getOne,
		enabled: !product.productType && !!options.productTypeId,
	});

	useEffect(() => {
		if (!productTypeQuery.data) return;

		canvasEngine.viewMode = productTypeQuery.data.viewType;

		const productType: CreatorProductType = {
			...omit(productTypeQuery.data, ['componentCategories']),
			componentCategories: [],
		};

		for (const componentCategory of productTypeQuery.data.componentCategories) {
			productType.componentCategories.push({
				...omit(componentCategory, ['attributeValues']),
				attributes: groupAttributeValues(componentCategory),
			});
		}

		dispatch(updateProduct({ productType }));

		const selectedComponents = getInitialSelectedComponents(
			productType.componentCategories
		);
		const selectedAttributes = getInitialSelectedAttributes(
			productType.componentCategories
		);

		dispatch(updateOptions({ selectedComponents, selectedAttributes }));

		dispatch(updateOptions({ isInitialized: true }));
	}, [productTypeQuery.data]);

	const createMutation = useMutation({
		mutationFn: ProductApi.create,
		onSuccess: (product) => {
			dispatch(
				updateProduct(
					pick(product, [
						'id',
						'createdAt',
						'updatedAt',
						'netPrice',
						'grossPrice',
						'productComponents',
					])
				)
			);
		},
	});

	useEffect(() => {
		if (!options.productTypeId || options.isInitialized) return;

		createMutation.mutate({
			productType: { id: options.productTypeId } as ProductType,
		});
	}, [options.productTypeId]);

	const handleSelectProductType = (productTypeId: UUID) => () => {
		dispatch(updateOptions({ productTypeId }));
	};

	const prefetchProductType = (productTypeId: UUID) => () => {
		queryClient.prefetchQuery({
			queryKey: [ProductTypeApi.queryKey, productTypeId],
			queryFn: ProductTypeApi.getOne,
		});
	};

	if (!productTypesQuery.data) return <Loading />;

	return (
		<Box className={styles.container}>
			<LoadingOverlay visible={productTypeQuery.isLoading} />
			{productTypesQuery.data.map((productType) => (
				<UnstyledButton
					key={productType.id}
					className={styles.button}
					p={{ base: 'md', md: 'lg' }}
					onClick={handleSelectProductType(productType.id)}
					onMouseOver={prefetchProductType(productType.id)}
				>
					<Image
						src={getFileSrc(productType.image?.path)}
						mb={{ base: 'md', md: 'lg' }}
						mah={500}
					/>
					<Title ta="center" order={isMobile ? 3 : 2}>
						{getEntityText(productType, 'name')}
					</Title>
				</UnstyledButton>
			))}
		</Box>
	);
};

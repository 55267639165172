import { FC } from 'react';
import { Box, Text } from '@mantine/core';
import { useTypedSelector } from '@/store/hooks';
import { calculateRemainingSpaceInShaft } from '@/utils/utilities';
import { ShaftDoorDetails } from '../shaftDoorConfig/ShaftDoorDetails';

export const AsideComponentsShaft: FC = () => {
	const { renderer } = useTypedSelector((state) => state.creator);

	const remainingSpace = calculateRemainingSpaceInShaft(
		renderer.baseDimensions.height,
		renderer.shaftDoors
	);

	return (
		<Box>
			<Text fw={500} c="dark.6" mb="lg">
				Wybierz wysokość dla każdego z drzwi. Drzwi muszą wypełnić całą wysokość
				wnęki. Jeżeli suma wysokości drzwi przekroczy wysokości drzwi będzie
				mniejsza niż wnęka to następny krok będzie{' '}
				<Text span fw="bold" c="black">
					zablokowany
				</Text>
				. Na podstawie wymiarów drzwi obliczana jest maksymalna dostępna liczba
				rewizji.
			</Text>
			{renderer.shaftDoors.map((shaftDoor, idx) => (
				<ShaftDoorDetails
					key={idx}
					idx={idx}
					shaftDoor={shaftDoor}
					remainingSpace={remainingSpace}
				/>
			))}
		</Box>
	);
};

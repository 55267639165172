import src1500x300 from './Drzwi-1500x300.svg';
import src1500x400 from './Drzwi-1500x400.svg';
import src1500x500 from './Drzwi-1500x500.svg';
import src1500x600 from './Drzwi-1500x600.svg';
import src1500x700 from './Drzwi-1500x700.svg';
import src1500x800 from './Drzwi-1500x800.svg';
import src1500x900 from './Drzwi-1500x900.svg';
import src1500x1000 from './Drzwi-1500x1000.svg';
import src1500x1100 from './Drzwi-1500x1100.svg';
import src1500x1200 from './Drzwi-1500x1200.svg';
import src1800x300 from './Drzwi-1800x300.svg';
import src1800x400 from './Drzwi-1800x400.svg';
import src1800x500 from './Drzwi-1800x500.svg';
import src1800x600 from './Drzwi-1800x600.svg';
import src1800x700 from './Drzwi-1800x700.svg';
import src1800x800 from './Drzwi-1800x800.svg';
import src1800x900 from './Drzwi-1800x900.svg';
import src1800x1000 from './Drzwi-1800x1000.svg';
import src1800x1100 from './Drzwi-1800x1100.svg';
import src1800x1200 from './Drzwi-1800x1200.svg';
import src2100x300 from './Drzwi-2100x300.svg';
import src2100x400 from './Drzwi-2100x400.svg';
import src2100x500 from './Drzwi-2100x500.svg';
import src2100x600 from './Drzwi-2100x600.svg';
import src2100x700 from './Drzwi-2100x700.svg';
import src2100x800 from './Drzwi-2100x800.svg';
import src2100x900 from './Drzwi-2100x900.svg';
import src2100x1000 from './Drzwi-2100x1000.svg';
import src2100x1100 from './Drzwi-2100x1100.svg';
import src2100x1200 from './Drzwi-2100x1200.svg';

const doorImages: Record<number, Record<number, HTMLImageElement>> = {
	1500: {
		300: getImage(src1500x300),
		400: getImage(src1500x400),
		500: getImage(src1500x500),
		600: getImage(src1500x600),
		700: getImage(src1500x700),
		800: getImage(src1500x800),
		900: getImage(src1500x900),
		1000: getImage(src1500x1000),
		1100: getImage(src1500x1100),
		1200: getImage(src1500x1200),
	},
	1800: {
		300: getImage(src1800x300),
		400: getImage(src1800x400),
		500: getImage(src1800x500),
		600: getImage(src1800x600),
		700: getImage(src1800x700),
		800: getImage(src1800x800),
		900: getImage(src1800x900),
		1000: getImage(src1800x1000),
		1100: getImage(src1800x1100),
		1200: getImage(src1800x1200),
	},
	2100: {
		300: getImage(src2100x300),
		400: getImage(src2100x400),
		500: getImage(src2100x500),
		600: getImage(src2100x600),
		700: getImage(src2100x700),
		800: getImage(src2100x800),
		900: getImage(src2100x900),
		1000: getImage(src2100x1000),
		1100: getImage(src2100x1100),
		1200: getImage(src2100x1200),
	},
};

function getImage(src: string) {
	const img = new Image();
	img.src = src;
	return img;
}

export default doorImages;

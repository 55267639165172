import { Vector2 } from '@/renderer/utils/Vector2';
import { CanvasEngine } from '../CanvasEngine';
import { CanvasObject } from './CanvasObject';
import { ShaftDoor } from './ShaftDoor';
import { Dimensions } from '../types/utils';
import { UUID } from '@/types/types';
import { ShaftDoorOpenDirection } from '@/types/creator';
import { ExportRendererPayload } from '../types/ExportPayload';
import RENDERER_CONFIG from '@/configs/rendererConfig';

export class Shaft extends CanvasObject {
	shellThickness = 5;
	doorShell = RENDERER_CONFIG.SHAFT_DOORS.SHELL;
	_openDirection: ShaftDoorOpenDirection = 'right';
	depth = 40;
	shaftDoorComponentId!: UUID;
	shaftDoorWindowComponentId!: UUID;
	shaftDoors: ShaftDoor[] = [];
	doorsGap = RENDERER_CONFIG.SHAFT_DOORS.GAP;
	isDobuleWinged = false;

	constructor(engine: CanvasEngine) {
		super(engine);
		this.width = RENDERER_CONFIG.INITIAL_BASE_DIMENSIONS.width;
		this.height = RENDERER_CONFIG.INITIAL_BASE_DIMENSIONS.height;
	}

	get openDirection() {
		return this._openDirection;
	}

	set openDirection(direction: ShaftDoorOpenDirection) {
		this._openDirection = direction;
		this.engine.mainNeedsRedraw = true;
	}

	changeDoorWindowQuantity(doorId: UUID, quantity: number) {
		for (const door of this.shaftDoors) {
			if (door.id === doorId) door.setWindows(quantity);
		}
	}

	resizeShaft(dimensions: Dimensions) {
		if (dimensions.width) this.width = dimensions.width;
		if (dimensions.height) this.height = dimensions.height;
		if (dimensions.depth) this.depth = dimensions.depth;

		this.isDobuleWinged =
			this.width >= RENDERER_CONFIG.SHAFT_DOORS.DOUBLE_WING_WIDTH_BREAKPOINT;

		for (const door of this.shaftDoors) {
			door.setWindows(0);
		}
	}

	createDoors(amount: number) {
		this.shaftDoors = [];

		for (let i = 0; i < amount; i++) {
			const pos = this.pos
				.clone()
				.add(
					new Vector2(
						0,
						i * (this.doorsGap + RENDERER_CONFIG.SHAFT_DOORS.MIN_HEIGHT)
					)
				);

			const door = new ShaftDoor(this.engine, this.width, pos);
			this.shaftDoors.push(door);
		}

		if (this.shaftDoors.length === 1) {
			this.shaftDoors[0].height = this.height;
			this.shaftDoors[0].calculateWindowsGrid();
		}
	}

	updateDoor(id: UUID, height: number) {
		const idx = this.shaftDoors.findIndex((door) => door.id === id);

		const doorToUpdate = this.shaftDoors[idx];

		const heightOffset = new Vector2(0, height - doorToUpdate.height);

		this.shaftDoors[idx].height = height;

		for (let index = idx + 1; index < this.shaftDoors.length; index++) {
			const door = this.shaftDoors[index];
			door.pos.add(heightOffset);
		}

		const isOverflowing =
			this.shaftDoors.reduce((acc, curr, idx) => {
				acc += curr.height;
				if (idx) acc += this.doorsGap;
				return acc;
			}, 0) > this.height;

		if (isOverflowing) return;

		for (const shaftDoor of this.shaftDoors) {
			shaftDoor.calculateWindowsGrid();
		}
		this.engine.mainNeedsRedraw = true;
	}

	adjustDoorsWidth(width: number) {
		for (const shaftDoor of this.shaftDoors) {
			shaftDoor.width = width;
			shaftDoor.calculateWindowsGrid();
		}

		this.engine.mainNeedsRedraw = true;
	}

	export(): ExportRendererPayload['shaft'] {
		return {
			pos: this.pos.toPoint(),
			width: this.width,
			height: this.height,
			depth: this.depth,
			doorsGap: this.doorsGap,
			openDirection: this.openDirection,
			shellThickness: this.shellThickness,
			doorShell: this.doorShell,
			shaftDoors: this.shaftDoors.map((door) => door.export()),
		};
	}
}

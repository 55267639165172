import { CanvasEngine } from '../CanvasEngine';
import { CanvasObject } from './CanvasObject';
import { Vector2 } from '@/renderer/utils/Vector2';
import { ShaftWindow } from './ShaftWindow';
import { ShaftDoorWindowsAvailableRect } from '../types/utils';
import { Shaft } from './Shaft';
import { ExportRendererPayload } from '../types/ExportPayload';
import RENDERER_CONFIG from '@/configs/rendererConfig';

const {
	RADIUS,
	MIN_DIST_TO_EDGE_BOTTOM,
	MIN_DIST_TO_EDGE_HORIZONTAL,
	MIN_GAP_HORIZONTAL,
	MIN_GAP_VERTICAL,
	MIN_DIST_TO_EDGE_TOP,
} = RENDERER_CONFIG.SHAFT_WINDOWS;

export class ShaftDoor extends CanvasObject {
	id = crypto.randomUUID();
	private shaft: Shaft;
	private _windows: ShaftWindow[] = [];
	windowsAvailableRects: {
		left: ShaftDoorWindowsAvailableRect;
		right: ShaftDoorWindowsAvailableRect;
	} = {
		left: {
			pos: this.pos.clone(),
			width: this.width,
			height: this.height,
			windowsGridSize: [0, 0],
		},
		right: {
			pos: this.pos.clone(),
			width: this.width,
			height: this.height,
			windowsGridSize: [0, 0],
		},
	};

	constructor(engine: CanvasEngine, widht: number, pos: Vector2) {
		super(engine);
		this.shaft = engine.shaft;
		this.width = widht;
		this.height = 150;
		this.pos = pos;
		this.calculateWindowsGrid();
	}

	setWindows(quantity: number) {
		this._windows = [];
		for (let index = 0; index < quantity; index++) {
			this._windows.push(new ShaftWindow(this.engine, this.pos.clone()));
		}

		this.repositionWindows();
		this.engine.mainNeedsRedraw = true;
	}

	get windows() {
		return this._windows;
	}

	private calculateWindowAvailableRects() {
		const { left, right } = this.windowsAvailableRects;

		left.pos
			.copy(this.pos)
			.add(new Vector2(MIN_DIST_TO_EDGE_HORIZONTAL, MIN_DIST_TO_EDGE_TOP));
		left.height = this.height - MIN_DIST_TO_EDGE_TOP - MIN_DIST_TO_EDGE_BOTTOM;
		left.height = Math.max(0, left.height);
		right.height = this.height - MIN_DIST_TO_EDGE_TOP - MIN_DIST_TO_EDGE_BOTTOM;
		right.height = Math.max(0, right.height);
		right.width = 0;

		if (this.shaft.isDobuleWinged) {
			const halfWidth = this.width / 2;

			left.width = halfWidth - MIN_DIST_TO_EDGE_HORIZONTAL * 2;
			left.width = Math.max(0, left.width);

			right.pos.set(left.pos.x + halfWidth, left.pos.y);
			right.width = halfWidth - MIN_DIST_TO_EDGE_HORIZONTAL * 2;
			right.width = Math.max(0, right.width);
		} else {
			left.width = this.width - MIN_DIST_TO_EDGE_HORIZONTAL * 2;
			left.width = Math.max(0, left.width);
		}
	}

	private positionWindowsInRect(
		rect: ShaftDoorWindowsAvailableRect,
		windows: ShaftWindow[]
	) {
		const windowDiameter = RADIUS * 2;
		const numRows = Math.ceil(windows.length / rect.windowsGridSize[1]);
		const numCols = Math.min(windows.length, rect.windowsGridSize[1]);
		const totalHeight =
			numRows * windowDiameter + (numRows - 1) * MIN_GAP_VERTICAL;
		const totalWidth =
			numCols * windowDiameter + (numCols - 1) * MIN_GAP_HORIZONTAL;
		const verticalOffset = (rect.height - totalHeight) / 2;
		const horizontalOffset = (rect.width - totalWidth) / 2;

		let currentRow = 0,
			currentCol = 0;

		for (const window of windows) {
			const x =
					rect.pos.x +
					RADIUS +
					currentCol * (windowDiameter + MIN_GAP_HORIZONTAL) +
					horizontalOffset,
				y =
					rect.pos.y +
					rect.height -
					RADIUS -
					currentRow * (windowDiameter + MIN_GAP_VERTICAL) -
					verticalOffset;

			window.pos.set(x, y);

			if (currentCol < rect.windowsGridSize[1] - 1) currentCol++;
			else {
				currentCol = 0;
				currentRow++;
			}
		}
	}

	private repositionWindows() {
		const leftSlotsQuantity =
				this.windowsAvailableRects.left.windowsGridSize[0] *
				this.windowsAvailableRects.left.windowsGridSize[1],
			rightSlotsQuantity =
				this.windowsAvailableRects.right.windowsGridSize[0] *
				this.windowsAvailableRects.right.windowsGridSize[1];

		let leftSideWindows = [],
			rightSideWindows = [];

		for (let index = 0; index < this.windows.length; index++) {
			const window = this.windows[index];

			if (index % 2 && rightSideWindows.length < rightSlotsQuantity)
				rightSideWindows.push(window);
			else if (leftSideWindows.length < leftSlotsQuantity)
				leftSideWindows.push(window);
		}

		this.positionWindowsInRect(
			this.windowsAvailableRects.left,
			leftSideWindows
		);
		this.positionWindowsInRect(
			this.windowsAvailableRects.right,
			rightSideWindows
		);
	}

	calculateWindowsGrid() {
		this.calculateWindowAvailableRects();

		const windowDiameter = RADIUS * 2;

		for (const space of Object.values(this.windowsAvailableRects)) {
			let rows = 0,
				cols = 0,
				height = space.height,
				width = space.width;

			while (height >= windowDiameter) {
				rows++;
				height -= windowDiameter;
				height -= MIN_GAP_VERTICAL;
			}

			while (width >= windowDiameter) {
				cols++;
				width -= windowDiameter;
				width -= MIN_GAP_HORIZONTAL;
			}

			space.windowsGridSize = [rows, cols];
		}

		this.repositionWindows();
	}

	getTotalWindowSlots() {
		return (
			this.windowsAvailableRects.left.windowsGridSize[0] *
				this.windowsAvailableRects.left.windowsGridSize[1] +
			this.windowsAvailableRects.right.windowsGridSize[0] *
				this.windowsAvailableRects.right.windowsGridSize[1]
		);
	}

	export(): ExportRendererPayload['shaft']['shaftDoors'][number] {
		return {
			pos: this.pos.toPoint(),
			width: this.width,
			height: this.height,
			shaftWindows: this.windows.map((window) => window.export()),
		};
	}
}

import React, { useMemo } from 'react';
import { Box, LoadingOverlay, Title } from '@mantine/core';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { Button } from '@/components/ui/button/Button';
import { toggleCreatorStep } from '@/store/slices/creatorSlice';
import { CreatorStep } from '@/types/creator';
import { AsideBase } from './steps/AsideBase';
import { AsideComponents } from './steps/AsideComponents';
import { AsideAccessories } from './steps/AsideAccessories';
import { AsideSummary } from './steps/AsideSummary';
import { calculateRemainingSpaceInShaft, formatPrice } from '@/utils/utilities';
import { useFindComponent } from '@/hooks/useFindComponent';
import { ComponentCategoryType } from '@/api/ComponentCategoryApi';
import { canvasEngine } from '@/components/App';
import { ProductTypeViewType } from '@/api/ProductTypeApi';
import { ParentWindowEvent } from '@/types/types';
import { useDisclosure } from '@mantine/hooks';
import CartIcon from '@/assets/icons/cart.svg?react';
import styles from './Aside.module.scss';

export const Aside: React.FC = () => {
	const dispatch = useTypedDispatch();

	const { product, options, renderer } = useTypedSelector((app) => app.creator);

	const handleStepChange = (action: '-' | '+') => () => {
		dispatch(toggleCreatorStep(action));
	};

	const currentStep = useMemo(() => {
		switch (options.currentStep) {
			case CreatorStep.BASE:
				return <AsideBase />;
			case CreatorStep.COMPONENTS:
				return <AsideComponents />;
			case CreatorStep.ACCESSORIES:
				return <AsideAccessories />;
			case CreatorStep.SUMMARY:
				return <AsideSummary />;

			default:
				return null;
		}
	}, [options.currentStep]);

	const isNextDisabled = useMemo(() => {
		switch (options.currentStep) {
			case CreatorStep.BASE:
				if (canvasEngine.viewMode === ProductTypeViewType.SHAFTS)
					return !renderer.shaftDoorsNumber;
				return false;
			case CreatorStep.COMPONENTS:
				if (canvasEngine.viewMode === ProductTypeViewType.SHAFTS)
					return !!calculateRemainingSpaceInShaft(
						renderer.baseDimensions.height,
						renderer.shaftDoors
					);
				return false;

			default:
				return false;
		}
	}, [options.currentStep, renderer.shaftDoors]);

	const isSummary = options.currentStep === CreatorStep.SUMMARY;

	const accessoriesComponentCategory = useMemo(
		() =>
			product.productType!.componentCategories.find(
				(componentCategory) =>
					componentCategory.type === ComponentCategoryType.ACCESSORIES
			)!,
		[product.productType?.componentCategories]
	);

	const baseComponentCategory = useMemo(
		() =>
			product.productType!.componentCategories.find(
				(componentCategory) =>
					componentCategory.type === ComponentCategoryType.BASE
			)!,
		[product.productType?.componentCategories]
	);

	useFindComponent({ componentCategory: baseComponentCategory });
	useFindComponent({ componentCategory: accessoriesComponentCategory });

	const [loading, loadingControls] = useDisclosure();

	const handleAddToCart = () => {
		if (!product.id) return;

		loadingControls.open();

		window.parent.postMessage(
			{
				title: ParentWindowEvent.ADD_TO_CART,
				content: product.id,
			},
			'*'
		);
	};

	return (
		<Box
			component="aside"
			className={styles.aside}
			id="creator-aside"
			pt="md"
			pl={{ base: 'md', md: 'lg' }}
			miw={isSummary ? 400 : 350}
			maw={isSummary ? 400 : 350}
		>
			<LoadingOverlay visible={loading} />
			<Box className={styles.content} pr={{ base: 'md', md: 'lg' }}>
				{currentStep}
			</Box>

			<Box className={styles.actions} py="md" pr={{ base: 'md', md: 'lg' }}>
				{isSummary && (
					<Title order={2} pb="md">
						{`Cena netto: ${formatPrice(product.netPrice / 100, {
							currencyDisplay: 'symbol',
						})}`}
					</Title>
				)}
				<Box className={styles.buttons}>
					<Button
						themes={['secondary', 'arrowReverse']}
						buttonProps={{
							type: 'button',
							onClick: handleStepChange('-'),
						}}
					>
						Wstecz
					</Button>
					{isSummary ? (
						<Button
							disabled={!product.productComponents.length}
							themes={['primary', 'icon']}
							buttonProps={{
								type: 'button',
								onClick: handleAddToCart,
							}}
						>
							Do koszyka
							<CartIcon />
						</Button>
					) : (
						<Button
							themes={['primary', 'arrow']}
							buttonProps={{
								type: 'button',
								onClick: handleStepChange('+'),
							}}
							disabled={isNextDisabled}
						>
							Dalej
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	);
};

import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { changeSelectedComponent } from '@/store/slices/creatorSlice';
import { CreatorComponentCategory } from '@/types/creator';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import ComponentCategoryApi from '@/api/ComponentCategoryApi';
import AttributeValueApi from '@/api/AttributeValueApi';

interface Props {
	componentCategory: CreatorComponentCategory;
}

export const useFindComponent = ({ componentCategory }: Props) => {
	const dispatch = useTypedDispatch();
	const { options, product } = useTypedSelector((state) => state.creator);
	const selectedComponent = options.selectedComponents[componentCategory.id];

	const selectedValues = useMemo(
		() =>
			Object.values(options.selectedAttributes[componentCategory.id]).filter(
				(value) => !!value
			),
		[options.selectedAttributes[componentCategory.id]]
	);

	const findComponentQuery = useQuery({
		queryKey: [
			ComponentCategoryApi.queryKey,
			componentCategory.id,
			AttributeValueApi.queryKey,
			selectedValues,
		],
		queryFn: ComponentCategoryApi.findComponentItem,
		enabled: !!selectedValues.length,
	});

	useEffect(() => {
		const component = findComponentQuery.data?.component;

		if (!component) return;

		if (component.id === selectedComponent?.id) return;

		dispatch(
			changeSelectedComponent({
				componentCategory,
				component,
			})
		);
	}, [findComponentQuery.data, product.productComponents]);
};

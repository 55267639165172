import { FC, useEffect, useMemo } from 'react';
import { Text, SegmentedControl, Select, Title } from '@mantine/core';
import { Attribute, AttributeViewType } from '@/api/AttributeApi';
import { filterAndFormatAttributeValues } from '@/utils/utilities';
import { useContent } from '@/providers/ContentProvider';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { changeSelectedAttributes } from '@/store/slices/creatorSlice';
import { UUID } from '@/types/types';
import RENDERER_CONFIG from '@/configs/rendererConfig';

interface Props {
	attribute: Attribute;
	componentCategoryId: UUID;
	isLast: boolean;
}

export const SingleAttribute: FC<Props> = ({
	attribute,
	componentCategoryId,
	isLast,
}) => {
	const dispatch = useTypedDispatch();

	const { options, renderer } = useTypedSelector((state) => state.creator);

	const { getEntityText } = useContent();

	const data = useMemo(() => {
		return filterAndFormatAttributeValues(
			options.selectedAttributes[componentCategoryId],
			attribute.attributeValues
		);
	}, [
		attribute.attributeValues.length,
		options.selectedAttributes,
		renderer.baseDimensions.height,
	]);

	const selectedAttrVal =
		options.selectedAttributes[componentCategoryId][attribute.id];

	useEffect(() => {
		const isAttributeValueMissing = !data.find(
			({ value }) => value === selectedAttrVal
		);

		if (isAttributeValueMissing)
			dispatch(
				changeSelectedAttributes({
					componentCategoryId,
					attributeId: attribute.id,
					attributeValueId: '',
				})
			);
	}, [options.selectedAttributes[componentCategoryId]]);

	if (
		attribute.meta?.attributeType === 'shaftDoorOpenDirection' &&
		renderer.baseDimensions.width >=
			RENDERER_CONFIG.SHAFT_DOORS.DOUBLE_WING_WIDTH_BREAKPOINT
	)
		return null;

	const handleChange = (attributeValueId: string) => {
		dispatch(
			changeSelectedAttributes({
				componentCategoryId,
				attributeId: attribute.id,
				attributeValueId: attributeValueId as UUID,
			})
		);
	};

	switch (attribute.viewType) {
		case AttributeViewType.SELECT:
			return (
				<Select
					mb={isLast ? 'xs' : 'md'}
					label={getEntityText(attribute, 'name')}
					placeholder="Wybierz wartość"
					data={data}
					value={selectedAttrVal}
					onChange={(val) => handleChange(val!)}
				/>
			);
		case AttributeViewType.SWITCH:
			return (
				<>
					<Text size="lg" fw={500} mb={4} lh="md">
						{getEntityText(attribute, 'name')}
					</Text>
					<SegmentedControl
						size="lg"
						mb={isLast ? 'xs' : 'md'}
						data={data}
						value={selectedAttrVal}
						onChange={(val) => handleChange(val)}
					/>
				</>
			);

		default:
			return <Title order={3}>Nieznany typ atrybutu!</Title>;
	}
};

import { Vector2 } from '@/renderer/utils/Vector2';
import { CanvasObject } from '../objects/CanvasObject';
import { BoardObjectType } from '../types/ObjectTypes';
import { DraggableObject } from '../objects/DraggableObject';
import { CanvasEngine } from '../CanvasEngine';
import RENDERER_CONFIG from '@/configs/rendererConfig';

export abstract class RendererUtils {
	static isMouseOverDraggable(mouse: Vector2, object: DraggableObject) {
		const { width, height } = object.isStagging
			? object.getStagingDimensions()
			: object.getScaledDimensions();

		const linePoints = object.isStagging
			? object.getStaggingLinePoints()
			: object.getLinePoints();

		const p1 = linePoints.p1;
		const p2 = linePoints.p2;

		const numerator = Math.abs(
			(p2.y - p1.y) * mouse.x -
				(p2.x - p1.x) * mouse.y +
				p2.x * p1.y -
				p2.y * p1.x
		);
		const denominator = Math.sqrt(
			Math.pow(p2.y - p1.y, 2) + Math.pow(p2.x - p1.x, 2)
		);

		const distance = numerator / denominator;

		const withinInnerWidth = distance <= width / 2;

		const dotProduct =
			(mouse.x - p1.x) * (p2.x - p1.x) + (mouse.y - p1.y) * (p2.y - p1.y);
		const wallLengthSquared = Math.pow(height, 2);
		const betweenEndpoints = dotProduct >= 0 && dotProduct <= wallLengthSquared;

		return withinInnerWidth && betweenEndpoints;
	}

	static isRectOverlapOtherRect(
		rect1: CanvasObject,
		rect2: CanvasObject,
		tolerance = 0
	) {
		return (
			rect1.pos.x < rect2.pos.x + rect2.width + tolerance &&
			rect2.pos.x < rect1.pos.x + rect1.width - tolerance &&
			rect1.pos.y < rect2.pos.y + rect2.height + tolerance &&
			rect2.pos.y < rect1.pos.y + rect1.height - tolerance
		);
	}

	static isRectInsideOtherRect(
		rect1: CanvasObject,
		rect2: CanvasObject,
		tolerance = 0
	) {
		return (
			rect1.pos.x + tolerance >= rect2.pos.x &&
			rect1.pos.x + rect1.width <= rect2.pos.x + rect2.width + tolerance &&
			rect1.pos.y + tolerance >= rect2.pos.y &&
			rect1.pos.y + rect1.height <= rect2.pos.y + rect2.height + tolerance
		);
	}

	static roundObjectPos(
		pos: Vector2,
		round = RENDERER_CONFIG.DISTRIBUTION_BOARD_DRAG_GRID_SIZE as number
	) {
		pos.setX(Math.round(pos.x / round) * round);
		pos.setY(Math.round(pos.y / round) * round);
	}

	static getObjectNameByType(objectType: BoardObjectType) {
		switch (objectType) {
			case BoardObjectType.GRILLE:
				return ['Maskownica'];
			case BoardObjectType.CUSTOM_GRILLE:
				return ['Maskownica', 'niestandardowa'];
			case BoardObjectType.MOUNTING_PLATE:
				return ['Płyta montażowa'];
			case BoardObjectType.SWITCH:
				return ['Maskownica', 'oddzielnie plombowana'];
			case BoardObjectType.ELECTRICITY_METER_1F:
				return ['Licznik 1 fazowy'];
			case BoardObjectType.ELECTRICITY_METER_3F:
				return ['Licznik 3 fazowy'];

			default:
				return ['Nieznany obiekt'];
		}
	}

	static adjustPath(path: string, startPoint: Vector2, size: number) {
		const numbers = path.match(/-?\d+\.?\d*/g)!.map(Number);
		const scaleFactor = size / Math.max(...numbers);
		const diffX = startPoint.x - numbers[0] * scaleFactor;
		const diffY = startPoint.y - numbers[1] * scaleFactor;

		for (let i = 0; i < numbers.length; i += 2) {
			numbers[i] = numbers[i] * scaleFactor + diffX;
			numbers[i + 1] = numbers[i + 1] * scaleFactor + diffY;
		}

		const adjustedPath = path.replace(/-?\d+\.?\d*/g, () =>
			String(numbers.shift())
		);
		return adjustedPath;
	}

	static drawBananaShape(
		context: CanvasRenderingContext2D,
		startPoint: Vector2,
		size: number
	) {
		context.save();
		context.strokeStyle = RENDERER_CONFIG.COLORS.BLACK;
		context.fillStyle = RENDERER_CONFIG.COLORS.WHITE;

		const bananaShape = new Path2D(
			RendererUtils.adjustPath(
				`M 25 60 Q 70 60 70 15 C 70 0 50 0 50 15 C 50 30 40 40 25 40 C 10 40 10 60 25 60`,
				startPoint,
				size
			)
		);

		context.stroke(bananaShape);

		context.restore();
	}

	static scaleFont(
		scale: number,
		fontSize: number = RENDERER_CONFIG.FONT_SIZE
	) {
		return `${fontSize * scale}px ${RENDERER_CONFIG.FONT_FAMILY}`;
	}

	static getHeightMetricOffset(engine: CanvasEngine, lineLength: number) {
		let boardMultiplier = 1,
			totalHeightMultiplier = 1;

		if (engine.getMetricsComponents().length) {
			boardMultiplier += 1.5;
			totalHeightMultiplier += 1.5;
		}
		if (engine.withPlinth) totalHeightMultiplier += 1.5;

		return {
			board: lineLength * boardMultiplier,
			total: lineLength * totalHeightMultiplier,
		};
	}

	static getGrilleModulesCountPerLength(length: number) {
		for (const [size, moduleCount] of Object.entries(
			RENDERER_CONFIG.GRILLE_MODULE_COUNT_PER_LENGTH
		)) {
			if (length <= Number(size)) return moduleCount;
		}

		return 0;
	}
}

import { Vector2 } from '@/renderer/utils/Vector2';
import { CanvasEngine } from './CanvasEngine';
import { CanvasEvent } from './types/CanvasEvent';
import { RendererUtils } from './utils/RendererUtils';
import { MOUSE_BUTTON } from './types/utils';
import { ApplyEventHandlers, On } from './utils/Events';
import { NativeMouseEvent } from './types/Mouse';
import { CreatorStep } from '@/types/creator';

@ApplyEventHandlers
export class CanvasMouseHandler {
	private mousePosition = new Vector2();
	private clientMousePosition = new Vector2();
	private isPaning = false;
	private panStart = new Vector2();

	constructor(private engine: CanvasEngine, private canvas: HTMLCanvasElement) {
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseScroll = this.onMouseScroll.bind(this);
		this.canvas.addEventListener('mousemove', this.onMouseMove);
		this.canvas.addEventListener('mousedown', this.onMouseDown);
		this.canvas.addEventListener('mouseup', this.onMouseUp);
		this.canvas.addEventListener('mouseenter', this.onMouseEnter);
		this.canvas.addEventListener('wheel', this.onMouseScroll);
	}

	@On(CanvasEvent.MOUSE_LEAVE_CANVAS)
	mouseLeaveLeaveRenderer() {
		this.engine.mainNeedsRedraw = true;

		this.isPaning = false;
		this.panStart = new Vector2();
		document.body.style.cursor = 'initial';

		const draggedObject = this.getDraggedObject();
		this.engine.distributionBoard.enabledDraggingRects = [];

		if (draggedObject) draggedObject.fadeOut();
	}

	private getDraggedObject() {
		return this.engine.draggableObjects.find((object) => object.isDragging);
	}

	private onMouseMove(event: NativeMouseEvent) {
		const { clientX, clientY } = event;
		const eventPos = new Vector2(clientX, clientY);

		if (this.clientMousePosition.equals(eventPos)) return;

		this.clientMousePosition = eventPos;

		const rect = this.canvas.getBoundingClientRect();
		const translation = this.engine.translation;

		const x = clientX - rect.left - translation.x;
		const y = clientY - rect.top - translation.y;

		this.mousePosition.set(x, y);

		this.engine.canvasMouse.copy(this.mousePosition);
		this.engine.windowMouse.set(event.clientX, event.clientY);

		const draggedObject = this.getDraggedObject();

		if (draggedObject) {
			this.engine.events.emit(
				CanvasEvent.OBJECT_DRAGGING,
				draggedObject,
				this.mousePosition,
				event
			);
			document.body.style.cursor = 'grabbing';
			return;
		}

		if (this.isPaning) {
			document.body.style.cursor = 'grabbing';

			const panVector = this.panStart.clone().sub(this.mousePosition);
			panVector.multiplyScalar(-1);

			this.engine.events.emit(CanvasEvent.PAN_MOVE, panVector);
			return;
		}

		if (this.engine.currentStep !== CreatorStep.COMPONENTS) return;

		for (const object of this.engine.draggableObjects) {
			if (RendererUtils.isMouseOverDraggable(this.mousePosition, object)) {
				document.body.style.cursor = 'grab';
				if (!object.isMouseOver)
					this.engine.events.emit(CanvasEvent.MOUSE_ENTER, object, event);
			} else {
				if (object.isMouseOver)
					this.engine.events.emit(CanvasEvent.MOUSE_LEAVE_OBJECT, object);
			}
		}
	}

	private onMouseUp(event: NativeMouseEvent) {
		this.isPaning = false;
		this.panStart = new Vector2();
		document.body.style.cursor = 'initial';

		for (const object of this.engine.draggableObjects) {
			if (!object.isMouseOver) continue;
			document.body.style.cursor = 'grab';

			this.engine.onMouseUp(object);
			return;
		}
	}

	private onMouseDown(event: NativeMouseEvent) {
		if (event.button === MOUSE_BUTTON.LEFT) {
			for (const object of this.engine.draggableObjects) {
				if (!object.isMouseOver) continue;

				this.engine.events.emit(
					CanvasEvent.MOUSE_DOWN,
					object,
					event,
					this.mousePosition
				);
				return;
			}
		}

		if (event.button === MOUSE_BUTTON.RIGHT) {
			this.isPaning = true;
			this.panStart = this.mousePosition.clone();
		}
	}

	private onMouseEnter(event: NativeMouseEvent) {
		const draggedStaggedObject = this.engine.stagingArea.objects.find(
			(object) => object.isDragging
		);

		if (!draggedStaggedObject) return;

		this.engine.transferStagingObjectToMain(
			draggedStaggedObject,
			this.mousePosition
		);
	}

	private onMouseScroll(event: WheelEvent) {
		if (this.getDraggedObject()) return;
		const { clientX, clientY } = event;

		const rect = this.canvas.getBoundingClientRect();
		const translation = this.engine.translation;

		const x = clientX - rect.left - translation.x;
		const y = clientY - rect.top - translation.y;

		this.engine.events.emit(CanvasEvent.MOUSE_SCROLL, event, new Vector2(x, y));
	}

	dispose() {
		this.canvas.removeEventListener('mousemove', this.onMouseMove);
		this.canvas.removeEventListener('mousedown', this.onMouseDown);
		this.canvas.removeEventListener('mouseup', this.onMouseUp);
		this.canvas.removeEventListener('mouseenter', this.onMouseEnter);
		this.canvas.removeEventListener('wheel', this.onMouseScroll);
	}
}

import { Component } from '@/api/ComponentApi';
import { Product } from '@/api/ProductApi';
import { UUID } from './types';
import { ComponentCategory } from '@/api/ComponentCategoryApi';
import { Attribute } from '@/api/AttributeApi';
import { ProductType } from '@/api/ProductTypeApi';
import { ShaftDoor } from '@/renderer/objects/ShaftDoor';
import { ShaftObjectType, BoardObjectType } from '@/renderer/types/ObjectTypes';

export enum CreatorStep {
	BASE,
	COMPONENTS,
	ACCESSORIES,
	SUMMARY,
}

export interface CreatorComponentCategory
	extends Omit<ComponentCategory, 'attributeValues'> {
	attributes: Attribute[];
}

export interface CreatorProductType
	extends Omit<ProductType, 'componentCategories'> {
	componentCategories: CreatorComponentCategory[];
}

export interface CreatorProduct
	extends Omit<Product, 'productType' | 'id' | 'options'> {
	id: UUID | '';
	productType: CreatorProductType | null;
}

export type SelectedAttributeValue = UUID | '';
export type CreatorSelectedComponents = Record<UUID, Component | null>;
export type CreatorSelectedAttributes = Record<
	UUID,
	Record<UUID, SelectedAttributeValue>
>;
export type CreatorShaftDoor = Pick<ShaftDoor, 'height' | 'id'> & {
	windowsQuantity: number;
};

export interface CreatorOptions {
	isCreatorOpened: boolean;
	isInitialized: boolean;
	withWindows: boolean;
	productTypeId?: UUID;
	selectedComponents: CreatorSelectedComponents;
	selectedAttributes: CreatorSelectedAttributes;
	selectedAttributesHistory: CreatorSelectedAttributes[];
	currentStep: CreatorStep;
}

export type DimensionAxis = 'width' | 'height' | 'depth';

export interface CreatorRenderer {
	baseDimensions: Record<DimensionAxis, number>;
	shaftDoorsNumber: number;
	shaftDoors: CreatorShaftDoor[];
}

export type ComponentCategoryMeta =
	| {
			objectType?: 'base' | BoardObjectType | 'door' | ShaftObjectType;
	  }
	| undefined;

export type AttributeMeta =
	| {
			attributeType?:
				| 'width'
				| 'height'
				| 'depth'
				| 'grilleInnerCut'
				| 'cutType'
				| 'isPilth'
				| 'pilthHeight'
				| 'withWindows'
				| 'shaftDoorOpenDirection';
	  }
	| undefined;

export type AttributeValueMeta =
	| {
			booleanValue?: boolean;
	  }
	| undefined;

export type ShaftDoorOpenDirection = 'left' | 'right';

import { CanvasEngine } from '../CanvasEngine';
import { CanvasObject } from './CanvasObject';
import { RendererUtils } from '../utils/RendererUtils';
import { ExportRendererPayload } from '../types/ExportPayload';
import RENDERER_CONFIG from '@/configs/rendererConfig';

const { METRICS } = RENDERER_CONFIG;

export class Door extends CanvasObject {
	private _withWindows = false;
	xOffsetToBoard = 100;

	constructor(engine: CanvasEngine) {
		super(engine);
		this.width = 500;
		this.height = 800;
	}

	get withWindows() {
		return this._withWindows;
	}

	set withWindows(value: boolean) {
		this._withWindows = value;
		this.engine.mainNeedsRedraw = true;
	}

	repositionDoor() {
		const { distributionBoard } = this.engine;

		const shell = distributionBoard.shellThickness * 2;

		const metricsOffset = RendererUtils.getHeightMetricOffset(
			this.engine,
			METRICS.LINE_LENGTH
		).total;

		this.pos.set(
			distributionBoard.pos.x +
				distributionBoard.width +
				shell +
				this.xOffsetToBoard +
				metricsOffset,
			distributionBoard.pos.y
		);
	}

	resizeDoor() {
		const { distributionBoard } = this.engine;

		this.width = distributionBoard.width;
		this.height = distributionBoard.height;

		this.repositionDoor();
	}

	export(): ExportRendererPayload['door'] {
		return {
			pos: this.pos.toPoint(),
			xOffsetToBoard: this.xOffsetToBoard,
			width: this.width,
			height: this.height,
			withWindows: this.withWindows,
		};
	}
}

import { BoardObjectType } from '@/renderer/types/ObjectTypes';
import { ShaftObjectType } from '../types/ObjectTypes';
import { CanvasEngine } from '../CanvasEngine';
import ComponentApi from '@/api/ComponentApi';
import EM1F from '../assets/components/EM1F.svg';
import EM3F from '../assets/components/EM3F.svg';

export async function loadComponents(engine: CanvasEngine) {
	const nonconfigureableComponents =
		await ComponentApi.getNonConfigureableComponents();

	engine.stagingArea.addObject({
		componentId: crypto.randomUUID(),
		height: 150,
		width: 500,
		stagingWidth: 650,
		stagingHeight: 200,
		objectType: BoardObjectType.GRILLE,
	});

	engine.stagingArea.addObject({
		componentId: crypto.randomUUID(),
		height: 150,
		width: 500,
		stagingWidth: 650,
		stagingHeight: 200,
		objectType: BoardObjectType.CUSTOM_GRILLE,
	});

	engine.stagingArea.addObject({
		componentId: crypto.randomUUID(),
		height: 100,
		width: 500,
		stagingWidth: 650,
		stagingHeight: 150,
		objectType: BoardObjectType.MOUNTING_PLATE,
	});

	const meter3fComponent = nonconfigureableComponents.find(
		(component) =>
			component.componentCategory.meta?.objectType ===
			BoardObjectType.ELECTRICITY_METER_3F
	);

	if (meter3fComponent)
		engine.stagingArea.addObject({
			componentId: meter3fComponent.id,
			height: 350,
			width: 200,
			objectType: BoardObjectType.ELECTRICITY_METER_3F,
			meta: {
				renderSrc: EM3F,
			},
		});

	const meter1fComponent = nonconfigureableComponents.find(
		(component) =>
			component.componentCategory.meta?.objectType ===
			BoardObjectType.ELECTRICITY_METER_1F
	);

	if (meter1fComponent)
		engine.stagingArea.addObject({
			componentId: meter1fComponent.id,
			height: 250,
			width: 150,
			objectType: BoardObjectType.ELECTRICITY_METER_1F,
			meta: {
				renderSrc: EM1F,
			},
		});

	const switchComponent = nonconfigureableComponents.find(
		(component) =>
			component.componentCategory.meta?.objectType === BoardObjectType.SWITCH
	);

	if (switchComponent)
		engine.stagingArea.addObject({
			componentId: switchComponent.id,
			height: 100,
			width: 50,
			objectType: BoardObjectType.SWITCH,
		});

	const shaftDoorComponent = nonconfigureableComponents.find(
			(component) =>
				component.componentCategory.meta?.objectType === ShaftObjectType.DOOR
		),
		shaftDoorWindowComponent = nonconfigureableComponents.find(
			(component) =>
				component.componentCategory.meta?.objectType === ShaftObjectType.WINDOW
		);

	if (shaftDoorComponent)
		engine.shaft.shaftDoorComponentId = shaftDoorComponent.id;
	if (shaftDoorWindowComponent)
		engine.shaft.shaftDoorWindowComponentId = shaftDoorWindowComponent.id;
}

import { FC } from 'react';
import { Box, Divider, NumberInput, NumberInputProps } from '@mantine/core';
import { CreatorShaftDoor } from '@/types/creator';
import { useTypedDispatch } from '@/store/hooks';
import { handleShaftDoorChange } from '@/store/slices/creatorSlice';
import { canvasEngine } from '@/components/App';
import RENDERER_CONFIG from '@/configs/rendererConfig';

const { HEIGHT_STEP, MIN_HEIGHT } = RENDERER_CONFIG.SHAFT_DOORS;

interface Props {
	idx: number;
	shaftDoor: CreatorShaftDoor;
	remainingSpace: number;
}

export const ShaftDoorDetails: FC<Props> = ({
	idx,
	shaftDoor,
	remainingSpace,
}) => {
	const dispatch = useTypedDispatch();

	const handleWindowQuantityChange: NumberInputProps['onChange'] = (val) => {
		const windowsQuantity = Number(val);
		dispatch(handleShaftDoorChange({ id: shaftDoor.id, windowsQuantity }));
	};

	const handleHeightChange: NumberInputProps['onChange'] = (val) => {
		const height = Number(val);
		dispatch(handleShaftDoorChange({ id: shaftDoor.id, height }));
	};

	const handleBlurHeight = () => {
		const height = Math.round(shaftDoor.height / HEIGHT_STEP) * HEIGHT_STEP;
		dispatch(handleShaftDoorChange({ id: shaftDoor.id, height }));
	};

	const rendererShaftDoor = canvasEngine.shaft.shaftDoors.find(
		(door) => door.id === shaftDoor.id
	)!;

	return (
		<Box>
			<Divider mt="md" mb="sm" label={<Box mx="xs">Drzwi {idx + 1}</Box>} />
			<NumberInput
				mb="md"
				label="Wysokość"
				placeholder="Wybierz wysokość"
				suffix=" mm"
				value={shaftDoor.height}
				step={HEIGHT_STEP}
				min={MIN_HEIGHT}
				max={shaftDoor.height + remainingSpace}
				onChange={handleHeightChange}
				onBlur={handleBlurHeight}
			/>
			<NumberInput
				label="Liczba rewizji"
				placeholder="Wybierz liczbę rewizji"
				suffix=" szt."
				value={shaftDoor.windowsQuantity}
				step={1}
				min={0}
				max={rendererShaftDoor.getTotalWindowSlots()}
				onChange={handleWindowQuantityChange}
				onBlur={handleBlurHeight}
			/>
		</Box>
	);
};

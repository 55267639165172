import { Vector2 } from '@/renderer/utils/Vector2';

export type Dimensions = {
	width: number;
	height: number;
	depth?: number;
};

export enum MOUSE_BUTTON {
	LEFT,
	MIDDLE,
	RIGHT,
}

export enum RenderPrioryty {
	BASE,
	DRAGGED,
}

export type Point = {
	x: number;
	y: number;
};

export type ShaftDoorWindowsAvailableRect = {
	pos: Vector2;
	width: number;
	height: number;
	windowsGridSize: [number, number];
};

import React from 'react';
import { useTypedSelector } from '@/store/hooks';
import { CreatorStep } from '@/types/creator';
import { ProductRenderer } from './steps/ProductRenderer';
import { Box } from '@mantine/core';
import { Dimensions } from './steps/Dimensions';
import styles from './Main.module.scss';

export const Main: React.FC = () => {
	const { options } = useTypedSelector((state) => state.creator);

	return (
		<Box component="main" className={styles.main}>
			{options.currentStep === CreatorStep.BASE && <Dimensions />}
			<ProductRenderer />
		</Box>
	);
};

import { ComponentCategoryType } from '@/api/ComponentCategoryApi';
import { useTypedSelector } from '@/store/hooks';
import { Box, Title } from '@mantine/core';
import { FC, useMemo } from 'react';
import { ShaftDoorsNumberSelect } from '../shaftDoorsNumberSelect/ShaftDoorsNumberSelect';
import { SingleAttribute } from '../singleAttribute/SingleAttribute';
import {
	getFilterRelatedAttributes,
	mapComponentCategoryTypeName,
} from '@/utils/utilities';
import { ProductTypeViewType } from '@/api/ProductTypeApi';
import { DimensionsPicker } from '../dimensionsPicker/DimensionsPicker';

export const AsideBase: FC = () => {
	const { product, options } = useTypedSelector((state) => state.creator);

	const baseComponentCategory = useMemo(
		() =>
			product.productType!.componentCategories.find(
				(componentCategory) =>
					componentCategory.type === ComponentCategoryType.BASE
			)!,
		[product.productType?.componentCategories]
	);

	const isShafts = product.productType!.viewType === ProductTypeViewType.SHAFTS;
	return (
		<Box>
			<Title order={2} mb="md" pt="sm">
				{mapComponentCategoryTypeName(
					ComponentCategoryType.BASE,
					product.productType!.viewType
				)}
			</Title>
			{isShafts && <DimensionsPicker />}
			{getFilterRelatedAttributes(
				baseComponentCategory,
				options.selectedAttributes
			).map((attribute, idx, arr) => (
				<SingleAttribute
					key={attribute.id}
					attribute={attribute}
					componentCategoryId={baseComponentCategory.id}
					isLast={
						idx === arr.length - 1 &&
						product.productType!.viewType ===
							ProductTypeViewType.DISTRIBUTION_BOARD
					}
				/>
			))}
			{isShafts && <ShaftDoorsNumberSelect />}
		</Box>
	);
};

import { CanvasEngine } from '../CanvasEngine';
import { Canvg, presets } from 'canvg';
import { BoardObjectType } from '../types/ObjectTypes';
import { DraggableObject } from '../objects/DraggableObject';
import RENDERER_CONFIG from '@/configs/rendererConfig';

const preset = presets.offscreen();

export class AssetsBase {
	assets: Record<BoardObjectType, Map<number, OffscreenCanvas>>;

	constructor(private engine: CanvasEngine) {
		this.assets = {
			[BoardObjectType.GRILLE]: new Map(),
			[BoardObjectType.MOUNTING_PLATE]: new Map(),
			[BoardObjectType.CUSTOM_GRILLE]: new Map(),
			[BoardObjectType.SWITCH]: new Map(),
			[BoardObjectType.ELECTRICITY_METER_1F]: new Map(),
			[BoardObjectType.ELECTRICITY_METER_3F]: new Map(),
		};
	}

	prepareAssets(object: DraggableObject, src: string) {
		for (const scale of RENDERER_CONFIG.ZOOM_LEVELS) {
			const { width, height } = object.getScaledDimensions(scale);
			this.saveScaledCanvas(width, height, src, scale, object.objectType);
		}
	}

	private async saveScaledCanvas(
		width: number,
		height: number,
		src: string,
		scale: number,
		type: BoardObjectType
	) {
		const availableAsset = this.assets[type].get(scale);
		if (availableAsset) return;

		const canvas = new OffscreenCanvas(width, height);
		const ctx = canvas.getContext('2d')!;

		//@ts-ignore
		const v = await Canvg.from(ctx, src, preset);

		v.resize(width, height, 'xMidYMid meet');

		await v.render();

		this.assets[type].set(scale, canvas);
	}
}

import { Vector2 } from '@/renderer/utils/Vector2';
import { CanvasEngine } from '../CanvasEngine';
import { DraggableObject } from '../objects/DraggableObject';
import { RendererUtils } from './RendererUtils';

export class ObjectDragger {
	constructor(private engine: CanvasEngine) {}

	onObjectDragging(object: DraggableObject, mouse: Vector2) {
		const updatedPos = mouse.clone();

		if (object.isStagging) {
			if (object.centerClickDiff) updatedPos.sub(object.centerClickDiff);
			object.stagingPos.copy(updatedPos);

			return;
		}

		object.lastPos.copy(object.pos);

		updatedPos.multiplyScalar(1 / this.engine.scale);
		if (object.centerClickDiff) updatedPos.sub(object.centerClickDiff);

		object.pos.copy(updatedPos);

		if (object.isAdjustedToBoard) this.handleAdjustableObjectDragging(object);
		else this.handleNormalObjectDragging(object);
	}

	onDragEnd(object: DraggableObject) {
		object.centerClickDiff = undefined;

		if (!object.isDragging) return;

		object.isDragging = false;

		if (object.isStagging) {
			object.returnToBase();

			return;
		}

		if (!object.isInsideBoard) object.fadeOut();
	}

	private handleNormalObjectDragging(object: DraggableObject) {
		const isOverlapingBoard =
			this.engine.distributionBoard.isObjectOverlapingBoard(object);

		if (isOverlapingBoard) RendererUtils.roundObjectPos(object.pos);

		let nextValidPos = this.engine.collisionDetector.collisionCheck(object);
		if (nextValidPos) {
			const isInside =
				this.engine.distributionBoard.isObjectInsideBoard(object);

			nextValidPos = this.engine.collisionDetector.collisionCheck(object);

			if (!isInside || nextValidPos) object.backToLastPos();
		}

		object.checkIsInsideBoard();
	}

	private handleAdjustableObjectDragging(object: DraggableObject) {
		let isOverlapingBoard =
			this.engine.distributionBoard.isObjectOverlapingBoard(object);

		if (!isOverlapingBoard) {
			object.width = object.stagingWidth;
			object.isInsideBoard = false;
			return;
		}

		RendererUtils.roundObjectPos(object.pos);

		this.engine.distributionBoard.setAdjustableObjectPosAndWidth(object);

		let nextValidPos = this.engine.collisionDetector.collisionCheck(object);

		if (nextValidPos) {
			object.pos.copy(nextValidPos);
			const isInside =
				this.engine.distributionBoard.isObjectInsideBoard(object);
			nextValidPos = this.engine.collisionDetector.collisionCheck(object);

			if (!isInside || nextValidPos) {
				object.backToLastPos();
				object.checkIsInsideBoard();

				if (!object.isInsideBoard) object.width = object.stagingWidth;
			}
		}

		object.checkIsInsideBoard();
	}
}

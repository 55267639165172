import {
	ApiError,
	AppError,
	Language,
	StringSignature,
	UUID,
} from '@/types/types';
import { Locale, formatDistance } from 'date-fns';
import { flatten, groupBy } from 'lodash';
import { isAxiosError } from 'axios';
import { Attribute } from '@/api/AttributeApi';
import { ErrorCode } from '@/configs/errorCode';
import { AttributeValue } from '@/api/AttributeValueApi';
import { Component } from '@/api/ComponentApi';
import { BoardObjectType } from '@/renderer/types/ObjectTypes';
import { DraggableObject } from '@/renderer/objects/DraggableObject';
import { canvasEngine } from '@/components/App';
import { RendererUtils } from '@/renderer/utils/RendererUtils';
import { ComboboxItem } from '@mantine/core';
import {
	ComponentCategory,
	ComponentCategoryType,
} from '@/api/ComponentCategoryApi';
import {
	CreatorComponentCategory,
	CreatorSelectedAttributes,
	CreatorSelectedComponents,
	CreatorShaftDoor,
	SelectedAttributeValue,
	ShaftDoorOpenDirection,
} from '@/types/creator';
import { ProductType, ProductTypeViewType } from '@/api/ProductTypeApi';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';

export function formatDateForInput(date: moment.MomentInput, format?: string) {
	return moment(date, format).format('YYYY-MM-DD');
}

export function formatDateForPresentation(date: moment.MomentInput) {
	if (!date) return '-';
	return moment(date).format('DD.MM.YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput | string = new Date()
) {
	return moment(date).format('HH:mm');
}

const PASSWORD_REGEX = /^(?=\D*\d)[^ ]{6,}$/;

/**
 *
 * @param password one number and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return PASSWORD_REGEX.test(password);
}

const PHONE_REGEX =
	/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;

/**
 *
 * @param phoneNumber validate international
 * @returns
 */
export function validatePhoneNumber(phoneNumber: string) {
	return PHONE_REGEX.test(phoneNumber) && phoneNumber.length >= 9;
}

/**
 *
 * @param accountNumber validate polish
 * @returns
 */
export function validateAccountNumber(accountNumber: string) {
	const trimmed = accountNumber.trim();
	return /\d{26}/.test(trimmed);
}

/**
 *
 * @param postalCode validate polish
 * @returns
 */
export function validatePostalCode(postalCode: string) {
	const trimmed = postalCode.trim();
	return /^[0-9]{2}-[0-9]{3}/.test(trimmed);
}

export function isValidDateObj(date: moment.MomentInput) {
	return moment(date).isValid();
}

export function incrementDate(date: moment.MomentInput) {
	return moment(date).add(1, 'day').toDate();
}

export function randomDate(start: Date, end: Date) {
	return new Date(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	);
}

export function sortByDate(
	dateA: string | Date,
	dateB: string | Date,
	order: 'asc' | 'desc' = 'asc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function stripHTML(string: string) {
	return string.replace(/(<([^>]+)>)/gi, '');
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
	return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
	switch (fileType) {
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'pdf':
			return 'application/pdf';
		default:
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}
}

export function getContent(conent: StringSignature, slug: string) {
	return conent[slug];
}

export function formatDateFromNow(date: Date, locale: Locale) {
	return formatDistance(date, new Date(), {
		locale,
		includeSeconds: true,
		addSuffix: true,
	});
}

export const formatPrice = (value: number, opts?: Intl.NumberFormatOptions) => {
	const formatter = new Intl.NumberFormat('pl', {
		style: 'currency',
		currency: 'PLN',
		currencyDisplay: 'code',
		maximumFractionDigits: !value ? 0 : undefined,
		...opts,
	});

	return formatter.format(value);
};

export const isApiError = (error: unknown): error is ApiError => {
	return (
		!!(error as AppError).status &&
		!!(error as AppError).stack &&
		!!(error as ApiError).message
	);
};

export const isAppError = (error: unknown): error is AppError => {
	return !!(error as AppError).status && !!(error as AppError).stack;
};

export const getErrorCodes = (error: unknown) => {
	if (isAxiosError<ApiError>(error) && error.response)
		return Array.isArray(error.response.data.message)
			? error.response.data.message
			: [error.response.data.message];

	return [ErrorCode.GENERIC];
};

export const errorCodesToMessage = (errorCodes: ErrorCode[]) =>
	errorCodes.map((code) => `errors.${code}`).join(', ');

export function getFileSrc(path?: string) {
	return `${import.meta.env.VITE_API_URL_UPLOADS}${path}`;
}

export function isDefaultLanguage(language: Language): language is 'PL' {
	return language === APP_CONFIG.DEFAULT_LANGUAGE;
}

export function natSort(nameA = '', nameB = '', order: 'asc' | 'desc' = 'asc') {
	let a: string | number = Number(nameA),
		b: string | number = Number(nameB);

	if (isNaN(a) || isNaN(b)) {
		return order === 'asc'
			? nameA.localeCompare(nameB)
			: nameB.localeCompare(nameA);
	}

	return order === 'asc' ? a - b : b - a;
}

export function inputWidthValue(value: string) {
	return `${value.length + 3}ch`;
}

export function getAttributeValueName(attributeValue: AttributeValue) {
	if (attributeValue.displayName) return attributeValue.displayName;

	return attributeValue.attribute.suffix
		? `${attributeValue.value} ${attributeValue.attribute.suffix}`
		: attributeValue.value;
}

export function getInitialSelectedComponents(
	componentCategories: CreatorComponentCategory[]
) {
	const selectedComponents: CreatorSelectedComponents = {};

	for (const componentCategory of componentCategories) {
		selectedComponents[componentCategory.id] = null;
	}

	return selectedComponents;
}

export function getInitialSelectedAttributes(
	componentCategories: CreatorComponentCategory[]
) {
	const selectedAttributes: CreatorSelectedAttributes = {};

	for (const componentCategory of componentCategories) {
		selectedAttributes[componentCategory.id] = {};

		for (const attribute of componentCategory.attributes) {
			if (!attribute.attributeValues.length) continue;

			selectedAttributes[componentCategory.id][attribute.id] =
				(componentCategory.chosenAttributeValues &&
					componentCategory.chosenAttributeValues[attribute.id]) ||
				'';
		}
	}

	return selectedAttributes;
}

export function groupAttributeValues(
	componentCategory: ComponentCategory
): Attribute[] {
	const grouped = groupBy(
		componentCategory.attributeValues,
		(attrVal) => attrVal.attribute.id
	);

	for (const attributeId in grouped) {
		grouped[attributeId].sort((a, b) => a.listOrder - b.listOrder);
	}

	const attributes = Object.values(grouped)
		.map((attributeValues) => ({
			...attributeValues[0].attribute,
			attributeValues,
		}))
		.sort((a, b) => a.listOrder - b.listOrder);

	return attributes;
}

export function cancellablePromise<TData extends {}>(promise: Promise<TData>) {
	let isCancelled = false;

	const wrappedPromise = new Promise<TData>((resolve, reject) => {
		promise.then(
			(value) =>
				isCancelled ? reject({ isCancelled, value }) : resolve(value),
			(error) => (isCancelled ? reject({ isCancelled, error }) : reject(error))
		);
	});

	return {
		promise: wrappedPromise,
		cancel() {
			isCancelled = true;
		},
	};
}

export function mapComponentCategoryTypeName(
	type: ComponentCategoryType,
	viewType: ProductTypeViewType
) {
	switch (viewType) {
		case ProductTypeViewType.DISTRIBUTION_BOARD:
			switch (type) {
				case ComponentCategoryType.BASE:
					return 'Parametry bazowe';
				case ComponentCategoryType.COMPONENTS:
					return 'Komponenty';
				case ComponentCategoryType.ACCESSORIES:
					return 'Drzwi i pozostałe opcje';
			}
		case ProductTypeViewType.SHAFTS:
			switch (type) {
				case ComponentCategoryType.BASE:
					return 'Parametry bazowe';
				case ComponentCategoryType.COMPONENTS:
					return 'Wymiary drzwi';
				case ComponentCategoryType.ACCESSORIES:
					return 'Konfiguracja drzwi';
			}
	}
}

export function updateStaggingObject(
	componentCategory: CreatorComponentCategory,
	selectedComponent: Component
) {
	const payload: Partial<DraggableObject> = {
		componentId: selectedComponent.id,
	};

	if (!componentCategory.meta) return;

	const { objectType } = componentCategory.meta;

	for (const attribute of componentCategory.attributes) {
		if (!attribute.meta) continue;

		const selectedValue = selectedComponent.attributeValues.find(
			(attr) => attr.attribute.id === attribute.id
		)!;

		const { attributeType } = attribute.meta;

		switch (objectType) {
			case BoardObjectType.GRILLE:
				switch (attributeType) {
					case 'height':
						payload.height = Number(selectedValue.value);
						break;
					case 'grilleInnerCut':
						payload.meta = {
							withInnerCut: !!selectedValue.meta?.booleanValue,
						};
						break;
				}
				break;
			case BoardObjectType.CUSTOM_GRILLE:
				switch (attributeType) {
					case 'height':
						payload.height = Number(selectedValue.value);
						break;
					case 'cutType':
						payload.meta = {
							cutType: selectedValue.value,
						};
						break;
				}
				break;
			case BoardObjectType.MOUNTING_PLATE:
				switch (attributeType) {
					case 'height':
						payload.height = Number(selectedValue.value);
						break;
				}
				break;

			default: {
				console.error('Unknown object type');
			}
		}
	}

	canvasEngine.stagingArea.updateStaggingObject(
		objectType as BoardObjectType,
		payload
	);
}

export function getDimensionsFromComponent(
	componentCategory: CreatorComponentCategory,
	selectedComponent: Component
) {
	const dimensions = {
		height: 0,
		width: 0,
		depth: 0,
	};

	const heightAttribute = componentCategory.attributes.find(
			(attr) => attr.meta?.attributeType === 'height'
		),
		widthAttribute = componentCategory.attributes.find(
			(attr) => attr.meta?.attributeType === 'width'
		),
		depthAttribute = componentCategory.attributes.find(
			(attr) => attr.meta?.attributeType === 'depth'
		);

	const selectedHeightValue = selectedComponent.attributeValues.find(
			(attr) => attr.attribute.id === heightAttribute?.id
		),
		selectedWidthValue = selectedComponent.attributeValues.find(
			(attr) => attr.attribute.id === widthAttribute?.id
		),
		selectedDepthValue = selectedComponent.attributeValues.find(
			(attr) => attr.attribute.id === depthAttribute?.id
		);

	if (!selectedHeightValue || !selectedWidthValue || !selectedDepthValue)
		return;
	dimensions.height = Number(selectedHeightValue.value);
	dimensions.width = Number(selectedWidthValue.value);
	dimensions.depth = Number(selectedDepthValue.value);

	return dimensions;
}

export function updateDoors(
	componentCategory: CreatorComponentCategory,
	selectedComponent: Component
) {
	const withPlinthAttribute = componentCategory.attributes.find(
			(attr) => attr.meta?.attributeType === 'isPilth'
		),
		selectedWithPlinthValue = selectedComponent.attributeValues.find(
			(attr) => attr.attribute.id === withPlinthAttribute?.id
		);

	const plinthHeightAttribute = componentCategory.attributes.find(
			(attr) => attr.meta?.attributeType === 'pilthHeight'
		),
		selectedPlinthHeightValue = selectedComponent.attributeValues.find(
			(attr) => attr.attribute.id === plinthHeightAttribute?.id
		);

	const withWindowsAttribute = componentCategory.attributes.find(
			(attr) => attr.meta?.attributeType === 'withWindows'
		),
		selectedWithWindowsValue = selectedComponent.attributeValues.find(
			(attr) => attr.attribute.id === withWindowsAttribute?.id
		);

	if (selectedWithPlinthValue)
		canvasEngine.withPlinth = !!selectedWithPlinthValue.meta?.booleanValue;

	if (selectedPlinthHeightValue)
		canvasEngine.plinthHeight = Number(selectedPlinthHeightValue.value);

	if (selectedWithWindowsValue)
		canvasEngine.door.withWindows =
			!!selectedWithWindowsValue.meta?.booleanValue;

	return {
		withWindows: !!selectedWithWindowsValue?.meta?.booleanValue,
	};
}

export function updateShaftDoors(
	componentCategory: CreatorComponentCategory,
	selectedComponent: Component
) {
	const shaftDoorsDirectionAttribute = componentCategory.attributes.find(
			(attr) => attr.meta?.attributeType === 'shaftDoorOpenDirection'
		),
		selectedShaftDoorOpenDirectionValue =
			selectedComponent.attributeValues.find(
				(attr) => attr.attribute.id === shaftDoorsDirectionAttribute?.id
			);

	if (selectedShaftDoorOpenDirectionValue)
		canvasEngine.shaft.openDirection =
			selectedShaftDoorOpenDirectionValue.value as ShaftDoorOpenDirection;
}

export function getFilterRelatedAttributes(
	componentCategory: CreatorComponentCategory,
	selected: CreatorSelectedAttributes
) {
	const selectedAttributeValues = Object.values(selected[componentCategory.id]);

	return componentCategory.attributes.filter((attribute) => {
		if (!attribute.relations?.length) return true;

		for (const relation of attribute.relations) {
			const includes = selectedAttributeValues.some(
				(attrVal) => attrVal === relation.objectId
			);
			if (includes) return relation.present;
		}
	});
}

export function filterAndFormatAttributeValues(
	selectedAttributeValues: Record<UUID, SelectedAttributeValue>,
	attributeValues: AttributeValue[],
	disabledCallback?: (attrVal: AttributeValue) => boolean
) {
	return attributeValues
		.filter((attributeValue) => {
			if (!attributeValue.relations?.length) return true;

			for (const relation of attributeValue.relations) {
				const includes = Object.values(selectedAttributeValues).some(
					(attrVal) => attrVal === relation.objectId
				);
				if (includes) return relation.present;
			}

			return true;
		})
		.map((attributValue) => ({
			value: attributValue.id,
			label: getAttributeValueName(attributValue),
			disabled: disabledCallback ? disabledCallback(attributValue) : false,
		}));
}

export function getModulesCountName(
	modulesCount: ReturnType<typeof RendererUtils.getGrilleModulesCountPerLength>
) {
	if (!modulesCount) return '';
	if ([32, 43, 54].includes(modulesCount)) return 'moduły';
	return 'modułów';
}

export function calculateMaxShaftDoorsAmount(shaftHeight: number) {
	let calculated = 0,
		remainingHeight = shaftHeight;
	const { MIN_HEIGHT, GAP } = RENDERER_CONFIG.SHAFT_DOORS;

	while (remainingHeight >= MIN_HEIGHT) {
		remainingHeight -= MIN_HEIGHT + GAP;
		calculated++;
	}

	return calculated;
}

export function calculateRemainingSpaceInShaft(
	shaftHeight: number,
	shaftDoors: CreatorShaftDoor[]
) {
	let calculated = shaftHeight;

	for (const shaftDoor of shaftDoors) {
		calculated -= shaftDoor.height;
	}

	calculated -= RENDERER_CONFIG.SHAFT_DOORS.GAP * (shaftDoors.length - 1);

	return calculated;
}

export function prepareDimensionsData(
	settings: ProductType['dimensionsSettings']
) {
	const data: {
		height: ComboboxItem[];
		width: ComboboxItem[];
		depth: ComboboxItem[];
	} = {
		height: [],
		width: [],
		depth: [],
	};

	let min = settings.height.min,
		max = settings.height.max;

	do {
		data.height.push({ value: min.toString(), label: `${min} mm` });
		min += settings.height.step;
	} while (min <= max);

	min = settings.width.min;
	max = settings.width.max;

	do {
		data.width.push({ value: min.toString(), label: `${min} mm` });
		min += settings.width.step;
	} while (min <= max);

	min = settings.depth.min;
	max = settings.depth.max;

	do {
		data.depth.push({ value: min.toString(), label: `${min} mm` });
		min += settings.depth.step;
	} while (min <= max);

	return data;
}

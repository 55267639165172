import { CanvasEvent } from '../types/CanvasEvent';
import 'reflect-metadata';

type Callback = (...params: any) => void;
type EventsRecord = Partial<Record<CanvasEvent, Callback[] | undefined>>;

export type EventOn = (key: CanvasEvent, callback: Callback) => void;
export type EventEmit = (key: CanvasEvent) => void;
export type EventRemove = (key: CanvasEvent, callback: Callback) => void;

export function Emit(event: CanvasEvent) {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: any) {
			const result = originalMethod.apply(this, args);

			Events.getInstance().emit(event, result);
			return result;
		};
	};
}

export function ApplyEventHandlers<T extends { new (...args: any[]): {} }>(
	constructor: T
) {
	return class extends constructor {
		constructor(...args: any[]) {
			super(...args);

			const eventHandlers = Reflect.getMetadata(
				'eventHandlers',
				constructor.prototype
			);

			if (eventHandlers) {
				eventHandlers.forEach(
					(handler: { event: CanvasEvent; method: Function }) => {
						Events.getInstance().on(handler.event, handler.method.bind(this));
					}
				);
			}
		}
	};
}

export function On(event: CanvasEvent) {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const existingEventHandlers =
			Reflect.getMetadata('eventHandlers', target) || [];

		existingEventHandlers.push({
			event,
			method: descriptor.value,
		});

		Reflect.defineMetadata('eventHandlers', existingEventHandlers, target);
	};
}

export class Events {
	private static instance: Events;

	private _events: EventsRecord = {};

	get events() {
		return this._events;
	}

	set events(value: EventsRecord) {
		this._events = value;
	}

	on: EventOn = (key: CanvasEvent, callback: Callback) => {
		if (!this.events[key]) this.events[key] = [];
		this.events[key]!.push(callback);
	};

	remove = (key: CanvasEvent, callback: () => void) => {
		if (this.events[key] && this.events[key]?.indexOf(callback) !== -1) {
			this.events[key]?.splice(this.events[key]?.indexOf(callback) || -1, 1);
		}
	};

	removeAll = () => {
		this.events = {};
	};

	emit = (key: CanvasEvent, ...params: any) => {
		const events = this.events[key] || [];

		for (const callback of events) {
			callback.apply(this, [...params]);
		}
	};

	public static getInstance(): Events {
		if (!Events.instance) {
			Events.instance = new Events();
		}

		return Events.instance;
	}
}

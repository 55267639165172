import { CanvasEngine } from '../CanvasEngine';
import { BoardObjectType } from '../types/ObjectTypes';
import { CanvasObject } from './CanvasObject';
import { Vector2 } from '@/renderer/utils/Vector2';
import { pick } from 'lodash';
import { RenderPrioryty } from '../types/utils';
import { Line } from '../utils/Line';
import { UUID } from '@/types/types';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import gsap from 'gsap';

export type DraggableObjectConfig = {
	componentId: UUID;
	width: number;
	height: number;
	objectType: BoardObjectType;
	stagingWidth?: number;
	stagingHeight?: number;
	centerClickDiff?: Vector2;
	meta?: Record<string, any>;
};

export class DraggableObject extends CanvasObject {
	id = crypto.randomUUID();
	componentId: UUID;
	isStagging = true;
	basePos = new Vector2(0, 0);
	stagingPos = new Vector2(0, 0);
	lastPos = new Vector2(0, 0);
	stagingWidth = 0;
	stagingHeight = 0;
	private _isDragging = false;
	isInsideBoard = false;
	isAdjustedToBoard = false;
	objectType: BoardObjectType;
	renderPriority = RenderPrioryty.BASE;
	centerClickDiff?: Vector2;
	meta: Record<string, any> = {};

	constructor(engine: CanvasEngine, config: DraggableObjectConfig) {
		super(engine);
		this.resize(config);
		this.componentId = config.componentId;
		this.stagingWidth = config.stagingWidth || config.width;
		this.stagingHeight = config.stagingHeight || config.height;
		this.centerClickDiff = config.centerClickDiff;
		this.objectType = config.objectType;

		this.isAdjustedToBoard =
			RENDERER_CONFIG.BOARD_ADJUSTED_OBJECT_TYPES.includes(this.objectType);

		if (config.meta) this.meta = config.meta;
	}

	getStagingDimensions() {
		return {
			width: this.stagingWidth * RENDERER_CONFIG.STAGING_CANVAS_SCALE,
			height: this.stagingHeight * RENDERER_CONFIG.STAGING_CANVAS_SCALE,
		};
	}

	get isDragging() {
		return this._isDragging;
	}

	set isDragging(value: boolean) {
		this._isDragging = value;
		this.renderPriority = value ? RenderPrioryty.DRAGGED : RenderPrioryty.BASE;
	}

	private dispose() {
		this.engine.draggableObjects = this.engine.draggableObjects.filter(
			(obj) => obj !== this
		);
		this.engine.collisionDetector.removeCollidable(this);
	}

	backToLastPos() {
		this.pos.copy(this.lastPos);
	}

	checkIsInsideBoard() {
		this.isInsideBoard =
			this.engine.distributionBoard.isObjectInsideBoard(this);
	}

	getStaggingLinePoints() {
		const height = this.getStagingDimensions().height,
			halfWidth = this.getStagingDimensions().width / 2;

		const topVector = this.stagingPos.clone().add(new Vector2(halfWidth, 0));
		const bottomVector = this.stagingPos
			.clone()
			.add(new Vector2(halfWidth, height));

		return new Line(topVector, bottomVector);
	}

	returnToBase() {
		const { x, y } = this.basePos;

		gsap.to(this.stagingPos, {
			ease: 'power2.out',
			duration: 0.5,
			x,
			y,
			onUpdate: () => {
				this.engine.stagingNeedsRedraw = true;
			},
		});
	}

	clone() {
		const clone = new DraggableObject(this.engine, {
			...pick(this, [
				'componentId',
				'width',
				'height',
				'objectType',
				'stagingWidth',
			]),
			centerClickDiff: this.centerClickDiff?.clone(),
			meta: structuredClone(this.meta),
		});

		return clone;
	}

	fadeOut() {
		document.body.style.cursor = 'initial';

		this.isDragging = false;
		this.isMouseOver = false;

		gsap.to(this.pos, {
			ease: 'power2.out',
			duration: 1,
			y: this.pos.y + 3000,
			onUpdate: () => {
				this.engine.mainNeedsRedraw = true;
			},
			onComplete: () => {
				this.dispose();
			},
		});
	}

	export() {
		return {
			pos: this.pos.toPoint(),
			width: this.width,
			height: this.height,
			objectType: this.objectType,
			meta: structuredClone(this.meta),
		};
	}
}

import React, { FormEventHandler, useMemo } from 'react';
import { Box, LoadingOverlay } from '@mantine/core';
import { Main } from './main/Main';
import { useTypedSelector } from '@/store/hooks';
import { Aside } from './aside/Aside';
import { useMediaQuery } from '@mantine/hooks';
import APP_CONFIG from '@/configs/appConfig';

export const Creator: React.FC = () => {
	const isMobile = useMediaQuery(`(max-width: ${APP_CONFIG.BREAKPOINTS.MD})`);

	const isLoading = useTypedSelector((state) => state.app.loading);

	const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
	};

	const height = useMemo(
		() => `calc(${isMobile ? '100' : '90'}dvh - 64px)`,
		[isMobile]
	);

	return (
		<Box
			className="creator-wrapper"
			component="form"
			onSubmit={handleSubmit}
			h={height}
			pb="md"
		>
			<LoadingOverlay
				visible={isLoading}
				zIndex={APP_CONFIG.Z_INDEX.CREATOR_LOADING}
			/>
			<Main />
			<Aside />
		</Box>
	);
};
